import { useMemo } from 'react';

import { Button, Divider, Heading, Text } from '@chakra-ui/react';
import { mdiCheck } from '@mdi/js';
import { GroupBase, GroupHeadingProps, MultiValue, OptionProps, Select } from 'chakra-react-select';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import { Icon } from './Icon';

export interface IMultiSelectWithTagsDefaultOption {
	title?: string;
	subtitle?: string;
	value: string;
	group?: string;
}

export interface IMultiSelectWithTagsProps {
	placeholder?: string;
	/** Pass an array to make it a multi select */
	value: string | string[];
	onChange: (data: string | string[]) => void;
	options: IMultiSelectWithTagsDefaultOption[];
	/** Changes how the selected options are displayed */
	optionDisplayCb?: (option: IMultiSelectWithTagsDefaultOption) => string | React.ReactNode;
	/** The name of the title prop to use */
	optionsTitleProp?: string;
	/** The name of the sub title prop to use */
	optionsSubtitleProp?: string;
	disabled?: boolean;
	hasError?: boolean;
}

interface IMultiSelectGroup extends GroupBase<Omit<IMultiSelectWithTagsDefaultOption, 'group'>> {
	label?: string;
	isFirst: boolean;
	isLast: boolean;
}

interface IMultiSelectWithTagsOptionProps extends OptionProps<IMultiSelectWithTagsDefaultOption, boolean, IMultiSelectGroup> {
	optionsTitleProp: string;
	optionsSubtitleProp: string;
}

interface IMultiSelectWithTagsGroupHeadingProps extends GroupHeadingProps<IMultiSelectWithTagsDefaultOption, boolean, IMultiSelectGroup> {
	isMulti: boolean;
}

const MultiSelectWithTagsGroupHeading: React.FC<IMultiSelectWithTagsGroupHeadingProps> = ({ data, isMulti }) => {
	if (!data.label) return null;

	return (
		<>
			{!data.isFirst && <Divider my={1} />}

			<Heading variant="section" px={isMulti ? 8 : 4} py={2} borderColor="borderColor" w="100%" data-testid="multi-select-group">
				{data.label}
			</Heading>
		</>
	);
};

const MultiSelectWithTagsOption: React.FC<IMultiSelectWithTagsOptionProps> = ({
	data,
	optionsTitleProp,
	optionsSubtitleProp,
	isSelected,
	isMulti,
	selectOption,
}) => {
	const title = data[optionsTitleProp];
	const subtitle = data[optionsSubtitleProp];

	return (
		<Button
			variant="ghost"
			fontWeight="normal"
			color="chakra-body-text"
			role="option"
			py={2}
			px={isMulti ? 10 : 4}
			borderRadius={0}
			flexWrap="wrap"
			textAlign="left"
			justifyContent="start"
			pos="relative"
			w="100%"
			height="auto"
			isActive={isSelected && !isMulti}
			onClick={() => selectOption(data)}
			data-testid="multi-select-option"
		>
			{isSelected && isMulti && <Icon path={mdiCheck} pos="absolute" top="50%" left={4} transform="translateY(-50%)" />}

			<div>
				<Text data-testid="multi-select-option-title" w="100%">
					{title}
				</Text>

				{subtitle && (
					<Text variant="tiny" mt={0.5} data-testid="multi-select-option-subtitle">
						{subtitle}
					</Text>
				)}
			</div>
		</Button>
	);
};

export const MultiSelectWithTags: React.FC<IMultiSelectWithTagsProps> = ({
	placeholder = 'Field',
	value,
	onChange,
	options,
	optionDisplayCb,
	optionsTitleProp = 'title',
	optionsSubtitleProp = 'subtitle',
	disabled,
	hasError,
}) => {
	const { groupedOptions, hasGroupedData } = useMemo(() => {
		const hasGroupedData = Boolean(options.find((opt) => opt.group));

		if (!hasGroupedData) return { groupedOptions: [], hasGroupedData };

		const optionsByGroup = groupBy(options, 'group');
		const groupedOptions: IMultiSelectGroup[] = sortBy(
			Object.entries(optionsByGroup).reduce(
				(prev, [group, options]) => [
					...prev,
					{
						options,
						label: group,
					},
				],
				[],
			),
			'label',
		).map((group, idx, groups) => ({ ...group, isFirst: idx === 0, isLast: groups.length - 1 === idx }));

		return {
			groupedOptions,
			hasGroupedData,
		};
	}, [options]);

	const selectedOptions = useMemo(() => {
		if (Array.isArray(value)) return options.filter((opt) => value.includes(opt.value));
		return options.find((opt) => opt.value === value);
	}, [value, options]);

	const isMultiSelect = Array.isArray(value);

	const onSelectChange = (option: IMultiSelectWithTagsDefaultOption | MultiValue<IMultiSelectWithTagsDefaultOption>) => {
		if (typeof option === 'string') onChange(option);
		else if (Array.isArray(option)) onChange(option.map((o) => o.value));
		else if (typeof option === 'object') onChange((option as IMultiSelectWithTagsDefaultOption).value);
	};

	return (
		<div data-testid={isMultiSelect ? 'multi-select-with-tags' : 'select-with-tags'}>
			<Select
				placeholder={placeholder}
				useBasicStyles
				selectedOptionStyle="check"
				hideSelectedOptions={false}
				closeMenuOnSelect={!isMultiSelect}
				value={selectedOptions}
				isDisabled={disabled}
				isInvalid={hasError}
				menuPosition="fixed"
				isMulti={isMultiSelect}
				options={hasGroupedData ? groupedOptions : options}
				getOptionLabel={(option) => option[optionsTitleProp]}
				formatOptionLabel={optionDisplayCb}
				onChange={onSelectChange}
				components={{
					GroupHeading: (props) => <MultiSelectWithTagsGroupHeading {...props} isMulti={isMultiSelect} />,
					Option: (props) => {
						return <MultiSelectWithTagsOption {...props} optionsTitleProp={optionsTitleProp} optionsSubtitleProp={optionsSubtitleProp} />;
					},
				}}
			/>
		</div>
	);
};
