import React from 'react';

import { Heading } from '@chakra-ui/react';

import useContentStore from '@/stores/ContentStore';
import useContentViewStore from '@/stores/ContentViewStore';
import useDesignerStore from '@/stores/DesignerStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';

import { contentViewMenuItemConstants as ViewTypes } from '@/util/resources';

import GeneralSettings from '@/components/gui/content/settings/General-settings.react';
import { FilterableSettings } from '@/components/gui/settingsContext/SettingsContext';

class GeneralSettingsMenu extends React.PureComponent {
	render() {
		const isMobileView = this.props.currentView === ViewTypes.MOBILE_VIEW;

		const generalSettingsProps = isMobileView ? { ...this.props.content, ...this.props.content.mobileProps } : this.props.content;

		return (
			<React.Fragment>
				<Heading size="sm" mb={5}>
					General settings
				</Heading>
				<FilterableSettings>
					<GeneralSettings
						{...generalSettingsProps}
						// {...contentSettings}
						backgroundColor={isMobileView ? this.props.content.mobileProps.bg_color : this.props.content.bg_color}
						isMobileView={isMobileView}
						currentView={this.props.currentView}
						general_settings={this.props.general_settings}
						leftSidebarOptions={this.props.options}
					/>
				</FilterableSettings>
			</React.Fragment>
		);
	}
}

const GeneralSettingsMenuWrapper = (props) => {
	const designerData = useDesignerStore();
	const leftSidebarProps = useLeftSidebarStore();
	const { currentView } = useContentViewStore();
	const { general_settings, content } = useContentStore();

	return (
		<GeneralSettingsMenu
			{...leftSidebarProps}
			{...designerData}
			general_settings={general_settings}
			content={content}
			currentView={currentView}
			{...props}
		/>
	);
};

export default GeneralSettingsMenuWrapper;
