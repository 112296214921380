import React, { useEffect } from 'react';

import { Box, Card, CardBody, Heading, Image, Text } from '@chakra-ui/react';

import useContentStore from '@/stores/ContentStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';

import strings from '@/resources/strings/en-us';
import DomHelper from '@/util/domHelper';
import { transformBlockTitle } from '@/util/helper';
import { ApiAuthorisationModes, PublicCustomRowHelperImg, RowTypes } from '@/util/resources';
import { generateGuid } from '@/util/shared';

import oneFourthIcon from 'assets/images/left_structures/menuarea_1-1-1-1.svg';
import oneThirdIcon from 'assets/images/left_structures/menuarea_1-1-1.svg';
import halfIcon from 'assets/images/left_structures/menuarea_1-1.svg';
import twoThirdsRightIcon from 'assets/images/left_structures/menuarea_1-2.svg';
import fullIcon from 'assets/images/left_structures/menuarea_1.svg';
import twoThirdsLeftIcon from 'assets/images/left_structures/menuarea_2-1.svg';

export const _structureItems = [
	{
		label_first_line: strings.full_width_container_first,
		label_second_line: strings.full_width_container_second,
		iconSrc: fullIcon,
		type: RowTypes.FULL,
	},
	{
		label_first_line: strings.half_width_container_first,
		label_second_line: strings.half_width_container_second,
		iconSrc: halfIcon,
		type: RowTypes.HALF,
	},
	{
		label_first_line: strings.two_thirds_right_container_first,
		label_second_line: strings.two_thirds_right_container_second,
		iconSrc: twoThirdsRightIcon,
		type: RowTypes.TWO_THIRDS_RIGHT,
	},
	{
		label_first_line: strings.two_thirds_left_container_first,
		label_second_line: strings.two_thirds_left_container_second,
		iconSrc: twoThirdsLeftIcon,
		type: RowTypes.TWO_THIRDS_LEFT,
	},
	{
		label_first_line: strings.three_one_thirds_container_first,
		label_second_line: strings.three_one_thirds_container_second,
		iconSrc: oneThirdIcon,
		type: RowTypes.THREE_ONE_THIRDS,
	},
	{
		label_first_line: strings.four_one_fourths_container_first,
		label_second_line: strings.four_one_fourths_container_second,
		iconSrc: oneFourthIcon,
		type: RowTypes.FOUR_ONE_FOURTHS,
	},
];

export const StructureItem = (props) => {
	return (
		<Card variant="outline" layerStyle="interactive.fill" w="28" mb={props.mb ? props.mb : 4} mr={props.lastMarginRight}>
			<CardBody
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				position="relative"
				gap="2"
				data-testid="add-structure-box"
				onClick={props.onClick}
			>
				<Image src={props.iconSrc} boxSize="6" />
				{props.label_first_line && (
					<Text
						display="block"
						w="100%"
						overflow="hidden"
						whiteSpace="nowrap"
						textOverflow="ellipsis"
						title={props.label_first_line}
						textAlign="center"
					>
						{props.label_first_line}
					</Text>
				)}
			</CardBody>
		</Card>
	);
};

const StructureItemHelper = (props) => {
	return (
		<Box
			borderStyle="solid"
			borderWidth={1}
			borderColor="chakra-border-color"
			bgColor="white"
			display="flex"
			alignItems="center"
			position="relative"
			borderRadius="base"
			mb={4}
			justifyContent="space-evenly"
			cursor="pointer"
			onClick={props.onClick}
			data-testid="add-structure-box"
			mr={4}
			h={16}
			// w={127}
			minW={24}
			flex={1}
		>
			<Image src={props.iconSrc} boxSize="6" />

			<Box w="50%">
				{props.label_first_line && (
					<Text
						fontWeight="semibold"
						display="block"
						w="100%"
						overflow="hidden"
						whiteSpace="nowrap"
						textOverflow="ellipsis"
						title={props.label_first_line}
						textAlign="center"
						fontSize="sm"
						maxW="100%"
					>
						{props.label_first_line}
					</Text>
				)}
				{props.label_second_line && (
					<Text
						fontWeight="semibold"
						display="block"
						w="100%"
						overflow="hidden"
						whiteSpace="nowrap"
						textOverflow="ellipsis"
						title={props.label_second_line}
						textAlign="center"
						fontSize="sm"
						maxW="100%"
					>
						{props.label_second_line}
					</Text>
				)}
			</Box>
		</Box>
	);
};

const AddStructuresBox = (props) => {
	useEffect(() => {
		window.addEventListener(
			'keyup',
			DomHelper.onKeyUpModalListener.bind(null, null, useContentStore.getState().deactivateAddStructureBoxHelper),
		);

		return () => {
			window.removeEventListener('keyup', DomHelper.onKeyUpModalListener);
		};
	}, []);

	const onClick = (type) => {
		const contentStore = useContentStore.getState();
		contentStore.deactivateAddStructureBoxHelper();
		if (props.first) {
			contentStore.prependRow({ rowType: type });
		} else {
			contentStore.addRowAfterPosition({ rowType: type, rowId: props.id });
		}
	};

	const onClickOldCustom = (row) => {
		const contentStore = useContentStore.getState();
		contentStore.deactivateAddStructureBoxHelper();
		if (props.first) {
			contentStore.prependCustomRow(row);
		} else {
			contentStore.addCustomRowAfterPosition({ row, rowId: props.id });
		}
	};

	const onClickCustom = (row) => {
		const contentStore = useContentStore.getState();
		contentStore.deactivateAddStructureBoxHelper();
		const newId = generateGuid();

		const onError = () => {
			contentStore.removeRow(newId);
		};

		const onSuccess = (row) => {
			contentStore.replaceRow({ rowId: newId, row: JSON.parse(row) });
		};

		if (props.first) {
			contentStore.prependRow({ rowType: 'FULL', id: newId });
		} else {
			contentStore.addRowAfterPosition({ rowType: 'FULL', rowId: props.id, newRowId: newId });
		}

		contentStore.saveRowSettings({ loading: true, id: newId });
		useLeftSidebarStore.getState().getCustomRowData(row, onSuccess, onError);
	};

	let structures = _structureItems.map((item, key) => {
		let lastMarginRight = key === _structureItems.length - 1 ? 0 : 15;
		const StructureComponent = props.helperGrid ? StructureItemHelper : StructureItem;
		return <StructureComponent {...item} key={key} lastMarginRight={lastMarginRight} onClick={() => onClick(item.type)} />;
	});

	const hasCustomRows = props.customRows && props.customRows.rows && props.customRows.rows.length;

	const customStructures =
		hasCustomRows &&
		props.customRows.rows
			.filter((item) => item.authorisationMode === ApiAuthorisationModes.Public)
			.map((item, key) => {
				let lastMarginRight = key === props.customRows.rows.length - 1 ? 0 : 15;
				const transformedTitle = transformBlockTitle(item.title);
				const iconSrc = PublicCustomRowHelperImg[transformedTitle];
				const StructureComponent = props.helperGrid ? StructureItemHelper : StructureItem;
				return (
					<StructureComponent
						key={item.id}
						label_first_line={item.title}
						label_second_line={''}
						iconSrc={iconSrc}
						lastMarginRight={lastMarginRight}
						structure={item.row}
						rowId={item.id}
						onClick={item.row ? () => onClickOldCustom(item.row) : () => onClickCustom(item)}
					/>
				);
			});

	if (props.helperGrid) {
		return (
			<Box display="flex" flexDirection="column" className="helper-grid-layout" borderStyle="solid" borderColor="cyan.400" borderWidth={3}>
				<Box p={2.5} bgColor="cyan.400" color="chakra-inverse-text" outline="3px solid" outlineColor="cyan.400">
					Add layout
				</Box>
				<Box display="flex" p={5} flexWrap="wrap" pr={1.5} pb={1.5} className="helper-grid-layout">
					{customStructures ? [...structures, ...customStructures] : structures}
				</Box>
			</Box>
		);
	}

	return (
		<Box className="add-first-container">
			<Heading size="md" display="flex" justifyContent="center" mb={2.5}>
				Start building your layout
			</Heading>
			<Box my={5}>
				<Box display="flex" flexWrap="wrap">
					{structures}
				</Box>
			</Box>
			{hasCustomRows ? (
				<Box my={5}>
					<Text mb={4}>Dynamic content blocks</Text>
					<Box display="flex" flexWrap="wrap">
						{customStructures}
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default AddStructuresBox;
