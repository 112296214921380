import React, { useCallback } from 'react';

import { Box } from '@chakra-ui/react';

import useContentStore from '@/stores/ContentStore';

import SharedInputSettings from './SharedInput-settings.react';

export const DateInputSettingComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

const DateInputSettings = (props) => {
	const onChange = useCallback(
		(data) => {
			let settings = {
				[data.type]: data.value,
			};

			if (props.address) {
				useContentStore.getState().saveElementSettings({
					address: props.address,
					settings,
				});
			}
		},
		[props.address],
	);

	return (
		<Box className="date-input-settings">
			<DateInputSettingComponents
				{...props}
				onChange={onChange}
				settings={{
					placeholder: {
						active: false,
					},
					placeholderGroup: {
						active: false,
					},
				}}
			/>
		</Box>
	);
};

export default DateInputSettings;
