import config from 'config/config';

import { generateUrlWithPathParams, makeApiGatewayCall } from '@/util/helper';

// const getRepeatedDate =  () => Moment().format('YYYYMMDDYYYYMMDDYYYYMMDDYYYYMMDD'); //date like this so that the blob service does not throw a validation error for the userid format

export const uploadImage = (files) => {
	let data = new FormData();
	let type = '';

	for (let i = 0, file; (file = files[i]); i++) {
		//$FlowIgnore
		data.append(i, file);

		type = file.type;
	}

	let uploadUrl = config.full_url_upload_url;

	return makeApiGatewayCall(uploadUrl, 'POST', data)
		.then((response) => {
			if (!response.data || !response.data.Url) {
				throw new Error('no url returned');
			}
			return { newSrc: response.data.Url, type };
		})
		.catch((error) => {
			console.error(error);
			if (error.response && error.response.data && error.response.data.Error) {
				return { error: error.response.data };
			}
			return { error };
		});
};

export const uploadImageByUrl = (url, category = 'EDITOR_IMAGES', name) => {
	let data = {
		Filename: name,
		Url: url,
		ExpectedContentType: 'image/',
		Category: category,
	};

	let uploadUrl = config.full_url_upload_url;

	return makeApiGatewayCall(uploadUrl, 'POST', data)
		.then((response) => {
			if (!response.data || !response.data.Url) {
				throw new Error('no url returned');
			}
			return { newSrc: response.data.Url, type: response.data.ContentType };
		})
		.catch((error) => {
			console.error(error);
			if (error.response && error.response.data && error.response.data.Error) {
				return { error: error.response.data };
			}

			return { error };
		});
};

export const deleteUploadedFiles = (id, successCb, errorCb) => {
	const url = generateUrlWithPathParams(config.blob_delete, '', {
		id,
	});

	return makeApiGatewayCall(url, 'DELETE')
		.then(() => {
			if (successCb) successCb();
		})
		.catch((error) => {
			console.error(error);
			if (errorCb) errorCb();
		});
};

export const getUploadedFiles = (page, search, successCb, errorCb, category = 'EDITOR_IMAGES') => {
	const data = {
		WhereCategory: category,
		OrderBy: 3,
		WhereStatus: 1,
		Page: page,
		PageSize: 16,
		WhereSearchText: search ? search.replace(/ /g, '-') : null,
	};

	return makeApiGatewayCall(config.blob, 'POST', data)
		.then((response) => {
			if (successCb) successCb(response.data);
		})
		.catch((error) => {
			console.error(error);
			if (errorCb) errorCb();
		});
};
