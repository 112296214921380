import React, { createRef } from 'react';

import {
	Box,
	Button,
	ButtonGroup,
	FormControl,
	FormLabel,
	Input,
	Menu,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import config from 'config/config';
import { ErrorMessages, SuccessMessages, WarningMessages } from 'config/messages';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import { showNotification } from '@/stores/NotificationStore';

import { leadingDebounce, pathPrefix } from '@/util/helper';
import { ApiAuthorisationModes, NotificationTypes, PublicOptions } from '@/util/resources';

export class SaveCustomElementMenu extends React.PureComponent {
	constructor(props) {
		super(props);

		this.nameInputRef = createRef();

		this.state = {
			authorisationMode: PublicOptions[0].value,
			title: '',
			hoverItem: -1,
			elementToEdit: undefined,
			order: '',
			loading: false,
		};
	}

	render() {
		const isDev = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test' || this.props.allowProdSave;
		const dropdown = this.state.title && !this.state.elementToEdit && this.renderDropDown();

		return (
			<Modal
				onClose={this.onClose}
				isOpen={this.props.location.pathname === pathPrefix() + '/save-custom-element' || import.meta.env.MODE === 'test'}
				size="lg"
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>Save item to reuse</ModalHeader>
					<ModalCloseButton data-testid="save-element-modal-close" />

					<ModalBody>
						<FormControl mb={4}>
							<FormLabel>Item name</FormLabel>
							<Input
								ref={this.nameInputRef}
								maxLength={40}
								value={this.state.title}
								readOnly={this.state.elementToEdit}
								onChange={(e) => this.setState({ title: e.target.value })}
							/>
							<Box pos="relative" sx={{ '& > *': { w: '100%' } }}>
								{dropdown}
							</Box>
						</FormControl>

						{isDev && (
							<React.Fragment>
								<FormControl mb={4}>
									<FormLabel>Authorization mode</FormLabel>
									<Select
										useBasicStyles
										data-testid="auth-mode-select"
										selectedValue={this.state.authorisationMode}
										defaultValue={PublicOptions[0]}
										onChange={(selectedOption) => this.setState({ authorisationMode: selectedOption.value })}
										placeholder="Select an option"
										options={this.authModeOptions}
									/>
								</FormControl>

								<FormControl mb={4}>
									<FormLabel>Item order</FormLabel>
									<Input maxLength={40} value={this.state.order} onChange={(e) => this.setState({ order: e.target.value })} />
									{/* {dropDown} */}
								</FormControl>
							</React.Fragment>
						)}
					</ModalBody>
					<ModalFooter gap="2">
						{this.state.elementToEdit ? (
							<ButtonGroup display="flex" justifyContent="flex-end">
								{!this.state.loading && (
									<Button variant="ghost" onClick={this.unsetElement} isDisabled={this.state.loading}>
										Cancel
									</Button>
								)}
								<Button onClick={this.replaceElement} isDisabled={this.state.loading}>
									Overwrite existing
								</Button>
							</ButtonGroup>
						) : (
							<Box display="flex" justifyContent="flex-end">
								<Button onClick={this.saveElement} isDisabled={this.state.loading} data-testid="save-element-modal-save">
									Save
								</Button>
							</Box>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	}

	authModeOptions = PublicOptions.map((item) => {
		return { value: item.value, label: item.label };
	});

	saveElement = leadingDebounce(() => {
		if (!this.state.title) {
			showNotification({ type: NotificationTypes.WARNING, text: WarningMessages.CUSTOM_ELEMENT_NO_TITLE });
			return;
		}

		/* istanbul ignore next */
		let successCb = () => {
			this.setState({ loading: false });
			this.props.getCustomElements();
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.CUSTOM_ELEMENT_SAVED });
			this.setState({ title: '' });
			this.props.navigate(pathPrefix() + this.props.location.search);
		};

		/* istanbul ignore next */
		let errorCb = () => {
			this.setState({ loading: false });
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.CUSTOM_ELEMENT_NOT_SAVED });
		};

		this.setState({ loading: true });

		if (this.props.element_to_save) {
			this.props.saveCustomElement(
				this.props.element_to_save,
				this.state.title,
				'',
				this.state.order,
				this.state.authorisationMode,
				successCb,
				errorCb,
			);
		}
	}, config.debounce_times.general);

	renderDropDown = () => {
		const list = this.renderList();

		return (
			<Menu isOpen={Boolean(list?.length)} isLazy>
				<MenuList maxH="calc(50vh - 45px)" overflow="auto" width="100%" onFocus={() => this.nameInputRef.current?.focus()}>
					{list}
				</MenuList>
			</Menu>
		);
	};

	renderList = () => {
		let elements = this.props.elements;

		const list = [];

		const filteredElements = this.props.allowProdSave
			? elements
			: elements.filter((element) => {
					return element.authorisationMode === ApiAuthorisationModes.Private;
				});

		if (filteredElements) {
			filteredElements.forEach((element, i) => {
				if (element.title.toLowerCase().includes(this.state.title.toLowerCase())) {
					list.push(
						<MenuItem
							key={i}
							onClick={() => {
								this.setElementToEdit(element);
								this.nameInputRef.current?.blur();
							}}
							data-testid="dropdown-item"
						>
							{element.title}
						</MenuItem>,
					);
				}
			});
		}

		return list;
	};

	setElementToEdit = (element) => {
		this.setState({
			elementToEdit: element,
			title: element.title,
			order: element.order,
			authorisationMode: element.authorisationMode,
		});
	};

	unsetElement = () => {
		this.setState({ elementToEdit: undefined, title: '' });
	};

	replaceElement = () => {
		const element = this.state.elementToEdit;

		/* istanbul ignore next */
		const saveError = () => {
			showNotification({ type: NotificationTypes.ERROR, text: 'Unknown error occurred' });
		};

		/* istanbul ignore next */
		const saveSuccess = () => {
			this.props.getCustomElements();
			showNotification({ type: NotificationTypes.SUCCESS, text: 'Successfully overwritten.' });
			this.unsetElement();
			this.props.navigate(pathPrefix() + this.props.location.search);
		};

		/* istanbul ignore next */
		this.props.saveCustomElement(
			this.props.element_to_save,
			this.state.title,
			element.id,
			this.state.order,
			this.state.authorisationMode,
			saveSuccess,
			saveError,
		);
	};

	onClose = () => {
		this.props.navigate(pathPrefix() + this.props.location.search);
	};
}

const SaveCustomElementMenuWrapper = (props) => {
	const { custom_elements, getCustomElements, saveCustomElement } = useLeftSidebarStore((state) => {
		return {
			custom_elements: state.custom_elements,
			getCustomElements: state.getCustomElements,
			saveCustomElement: state.saveCustomElement,
		};
	}, shallow);
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<SaveCustomElementMenu
			{...custom_elements}
			getCustomElements={getCustomElements}
			saveCustomElement={saveCustomElement}
			{...props}
			navigate={navigate}
			location={location}
		/>
	);
};

export default SaveCustomElementMenuWrapper;
