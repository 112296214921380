import { IconButton, Tooltip } from '@chakra-ui/react';

import { changeView } from '@/stores/ContentViewStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';

import { Icon } from '@/components/gui/shared/Icon';

export const ContentViewMenuItem = ({ itemData, toggled, alt, testId, icon }) => {
	const onClick = () => {
		changeView(itemData.type);
		useLeftSidebarStore.getState().toggleGeneralSettings();
	};

	return (
		<Tooltip label={alt || itemData.label}>
			<IconButton
				variant="ghost"
				size="lg"
				borderRadius={0}
				aria-label={alt || itemData.label}
				colorScheme={toggled ? 'primary' : 'neutral'}
				borderBottom="2px solid"
				borderBottomColor={toggled ? 'currentcolor' : 'transparent'}
				icon={<Icon path={icon} />}
				onClick={onClick}
				data-testid={testId}
			/>
		</Tooltip>
	);
};

export default ContentViewMenuItem;
