import { Box, Skeleton, Td, Tr } from '@chakra-ui/react';

export const SitesTableLoader = () => (
	<>
		{[...Array(7)].map((_, i) => (
			<Tr h={12} key={i}>
				<Td w="1%" minW={10} px={3} py={1}>
					<Skeleton w="100%">
						<Box h={4} />
					</Skeleton>
				</Td>
				<Td w="33%" px={3} py={1}>
					<Skeleton w="40%">
						<Box h={4} />
					</Skeleton>
					<Skeleton w="70%" mt={1}>
						<Box h={3} />
					</Skeleton>
				</Td>
				<Td w="33%" px={3} py={1}>
					<Skeleton w="30%">
						<Box h={4} />
					</Skeleton>
				</Td>
				<Td w="33%" px={3} py={1}>
					<Skeleton w="70%">
						<Box h={4} />
					</Skeleton>
				</Td>
			</Tr>
		))}
	</>
);
