import React from 'react';

import { AllComponentTypes, ComponentDefaults, getCorrectedHtml } from 'xmc-forms/src/util/shared.js';

const Text = (props = ComponentDefaults[AllComponentTypes.text]) => {
	const id = props.id ? props.id.toString().replace(/-/g, '') : '';

	const html = getCorrectedHtml(props.text);

	const checkMobileChange = () => {
		if (props.mobileProps && props.mobileProps.mobileChanged) {
			return true;
		}
		return false;
	};

	const returnComponents = [
		<div
			className={`component global-text text-component text-desktop text${id} `}
			data-component-type="text"
			key={0}
			id={id}
			dangerouslySetInnerHTML={{ __html: html }}
			// width={props.slotWidth}
		/>,
	];

	if (checkMobileChange()) {
		const mobileProps = { ...props, ...props.mobileProps };

		const mobileHtml = getCorrectedHtml(mobileProps.text);

		returnComponents.push(
			<div
				className={`global-text text-component text-mobile text${id} `}
				data-component-type="text"
				key={1}
				id={`mobile${id}`}
				dangerouslySetInnerHTML={{ __html: mobileHtml }}
			/>,
		);
	}

	return returnComponents;
};

export default Text;
