import React from 'react';

import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	FormControl,
	FormLabel,
	IconButton,
	Image,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { mdiContentCopy, mdiPencilOutline } from '@mdi/js';
import { Select } from 'chakra-react-select';
import { Link } from 'react-router-dom';

import { showNotification } from '@/stores/NotificationStore';

import { copyToClipboard } from '@/util/helper';

import { Icon } from '@/components/gui/shared/Icon';
import SiteManagement from '@/components/gui/shared/SiteManagement.react';
import { getRoute } from '@/components/Table/strings';

export const TableSettings = (props) => {
	const { onSubmit } = props;

	const onKeyDown = (e) => {
		if (e.keyCode === 13) {
			onSubmit();
		}
	};

	return (
		<>
			<TableDrawer {...props} onKeyDown={onKeyDown} />
			<TableModal {...props} onKeyDown={onKeyDown} />
		</>
	);
};

const TableDrawer = (props) => {
	const { onKeyDown, entityForm, activeEntity, onClose, onChange, onSubmit, takeEntityAction, webhooks } = props;

	return (
		<Drawer size="sm" isOpen={activeEntity && entityForm.show} onClose={onClose}>
			<DrawerOverlay />

			<DrawerContent>
				<DrawerCloseButton />

				<DrawerHeader>Form settings</DrawerHeader>

				<DrawerBody>
					<FormControl mb={6}>
						<FormLabel>Name</FormLabel>
						<Input
							autoFocus
							value={entityForm.name}
							onChange={(e) => onChange({ type: 'name', value: e.target.value })}
							onKeyDown={onKeyDown}
							onInput={(e) => {
								e.target.value = e.target.value.replace(
									/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
									'',
								);
							}}
							data-testid="name-input"
						/>
					</FormControl>

					{activeEntity && activeEntity.Blueprints && (
						<Box mb={6} display="flex" flexDirection="column">
							<Text color="neutral-fg" mb={2} fontWeight="medium">
								Preview
							</Text>
							<Box
								borderRadius="sm"
								maxH="xs"
								h="fit-content"
								justifyContent="center"
								borderWidth={1}
								borderStyle="solid"
								borderColor="chakra-border-color"
								overflowY="auto"
								p={4}
							>
								{activeEntity.Blueprints.PreviewImageUrl ? (
									<Image w="100%" src={activeEntity.Blueprints.PreviewImageUrl} />
								) : (
									<Text fontWeight="semibold" alignSelf="center">
										No preview available
									</Text>
								)}
							</Box>
						</Box>
					)}

					<Button
						w="100%"
						variant="outline"
						colorScheme="primary"
						mb={6}
						leftIcon={<Icon path={mdiPencilOutline} />}
						onClick={() => takeEntityAction('design', activeEntity.Entity.Id)}
					>
						Edit design
					</Button>

					{activeEntity && (
						<FormControl mb={6}>
							<FormLabel>Form ID</FormLabel>
							<InputGroup>
								<Input isReadOnly value={activeEntity.Entity.Id} />
								<InputRightElement>
									<Tooltip label="Copy to clipboard">
										<IconButton
											variant="ghost"
											icon={<Icon path={mdiContentCopy} />}
											size="sm"
											aria-label={'Copy to clipboard'}
											onClick={() =>
												copyToClipboard(activeEntity.Entity.Id, () => {
													showNotification({ type: 'Success', text: 'Copied' });
												})
											}
											data-testid="form-id-copy-btn"
										/>
									</Tooltip>
								</InputRightElement>
							</InputGroup>
						</FormControl>
					)}

					<FormControl mb={6} data-testid="settings-webhooks-dropdown">
						<FormLabel>Webhook</FormLabel>
						{webhooks.items.length ? (
							<Select
								useBasicStyles
								selectedOptionStyle="check"
								value={entityForm.webhookId ? webhooks.items.find((item) => item.Id === entityForm.webhookId) : ''}
								onChange={(data) => onChange({ type: 'webhookId', value: data.Id })}
								getOptionLabel={(item) => item.Settings.Name}
								getOptionValue={(item) => item.Id}
								options={webhooks.items}
								menuPlacement="auto"
							/>
						) : (
							<Text>No webhooks available</Text>
						)}
						<Box display="flex" mt={8}>
							<Link to={getRoute.root('forms', 'webhook', '1')}>
								<Button variant="outline" colorScheme="primary">
									Manage webhooks
								</Button>
							</Link>
						</Box>
					</FormControl>

					<SiteManagement />
				</DrawerBody>

				<DrawerFooter gap="2">
					<Button variant="ghost" onClick={onClose} colorScheme="neutral">
						Cancel
					</Button>
					<Button onClick={onSubmit}>Save</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
};

const TableModal = (props) => {
	const { onKeyDown, activeEntity, entityForm, onClose, onChange, onSubmit } = props;

	return (
		<Modal size="md" isOpen={!activeEntity && entityForm.show} onClose={onClose}>
			<ModalOverlay />

			<ModalContent>
				<ModalCloseButton data-testid="create-form-modal-close" />

				<ModalHeader data-testid="create-form-modal-header">Create form</ModalHeader>

				<ModalBody>
					<FormControl>
						<FormLabel>Name</FormLabel>
						<Input
							autoFocus
							value={entityForm.name}
							onChange={(e) => onChange({ type: 'name', value: e.target.value })}
							onKeyDown={onKeyDown}
							onInput={(e) => {
								e.target.value = e.target.value.replace(
									/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
									'',
								);
							}}
							data-testid="name-input"
						/>
					</FormControl>
				</ModalBody>

				<ModalFooter gap="2">
					<Button onClick={onClose} variant="ghost" colorScheme="neutral">
						Cancel
					</Button>
					<Button onClick={onSubmit}>Save</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
