import { FC } from 'react';

import { Box, FormControl, FormLabel } from '@chakra-ui/react';

import { Tooltip } from '@/components/gui/content/helper/Tooltip.react';
import { IMultiSelectWithTagsProps, MultiSelectWithTags } from '@/components/gui/shared/MultiSelectWithTags';

import { settingsLabelStyle } from './Settings.styles';

interface IMultiSelectWithTagsSettingProps extends Omit<IMultiSelectWithTagsProps, 'onChange'> {
	type: string;
	label?: string;
	tooltip?: JSX.Element;
	onChange: (data: { type: string; value: string | string[] }) => void;
}

const MultiSelectWithTagsSetting: FC<IMultiSelectWithTagsSettingProps> = ({ label, type, onChange, tooltip, ...rest }) => {
	return (
		<FormControl mb={5}>
			{label && (
				<FormLabel sx={settingsLabelStyle}>
					<Box display="inline-flex" pos="relative">
						{label}
						{tooltip && <Tooltip>{tooltip}</Tooltip>}
					</Box>
				</FormLabel>
			)}

			<MultiSelectWithTags {...(rest as unknown as IMultiSelectWithTagsProps)} onChange={(data) => onChange({ type, value: data })} />
		</FormControl>
	);
};

export default MultiSelectWithTagsSetting;
