export default {
	text_line_height: '1.3',
	font_size: 16,
	font_family: 'Arial, Helvetica, sans-serif',
	color: '#000000',
	background_image: '',
	background_repeat: 'no-repeat',
	bg_color: 'transparent',
	title: '',
	retina_images: false,
	borderWidth: 0,
	structureWidth: 1000,
	borderColor: '#000000',
	border: {},
	editor3: true,
	successAction: {
		type: 'none',
		message:
			'<p style="text-align: center; font-family: Arial, Helvetica, sans-serif;">&nbsp;</p><p style="text-align: center; font-family: Arial, Helvetica, sans-serif;"><strong><span style="color: rgb(45, 194, 107);">Form has been submitted successfully!</span></strong></p><p style="text-align: center; font-family: Arial, Helvetica, sans-serif;">&nbsp;</p>',
		redirectUrl: 'https://',
	},
	failAction: {
		type: 'message',
		message:
			'<p style="text-align: center; font-family: Arial, Helvetica, sans-serif;">&nbsp;</p><p style="text-align: center; font-family: Arial, Helvetica, sans-serif;"><strong><span style="color: rgb(224, 62, 45);">Form has not been submitted!</span></strong></p><p style="text-align: center; font-family: Arial, Helvetica, sans-serif;">&nbsp;</p>',
		redirectUrl: 'https://',
	},
	usedColors: [
		'rgba(0,0,0,1)',
		'#d0021b',
		'#f5a623',
		'#f8e71c',
		'#8b572a',
		'#7ed321',
		'#417505',
		'#bd10e0',
		'#9013fe',
		'#4a90e2',
		'#50e3c2',
		'#b8e986',
		'#000000',
		'#4a4a4a',
		'#9b9b9b',
		'#ffffff',
	],
	disableAutoComponents: [],
	rows: [
		{
			background_image: '',
			background_image_type: '',
			background_repeat: 'no-repeat',
			background_position: 'initial',
			borderWidth: 0,
			borderColor: '#000000',
			borderRadius: 0,
			padding: 0,
			multiPadding: { paddingTop: 0, paddingLeft: 40, paddingBottom: 0, paddingRight: 40, allow: true },
			pageIndex: 0,
			bg_color: 'transparent',
			hidden: false,
			responsive: true,
			behavior: 'NORMAL',
			slot_spacing_side: 0,
			slot_spacing_center: 0,
			mobileProps: {
				index: 0,
				responsive: true,
				multiPadding: { paddingTop: 0, paddingLeft: 20, paddingBottom: 0, paddingRight: 20, allow: true },
				mobileChanged: true,
			},
			background_image_wide: '',
			background_repeat_wide: 'no-repeat',
			background_position_wide: 'initial',
			visibility: { type: 'show', operator: 'all', conditions: [] },
			background_color_wide: 'transparent',
			repeat_count: 5,
			sort_products: 'Orders',
			uniqueId: '16dc5951-1186-45f4-979e-dedd8af3bc4f',
			id: '66dbd3ac-f3ed-4f91-95cb-be6525290753',
			type: 'FULL',
			slots: [
				{
					background_color: 'transparent',
					contentBgColor: 'transparent',
					borderWidth: 0,
					borderColor: '#000000',
					borderRadius: 0,
					padding: 0,
					hidden: false,
					id: '01d0c29f-6947-42b2-a33c-e0865ecce00a',
					multiPadding: { paddingTop: 40, paddingLeft: 0, paddingBottom: 20, paddingRight: 0, allow: true },
					vertical_align: 'top',
					components: [
						{
							id: 'fcd7906e-51ae-499d-9fb0-a9c252b36f99',
							type: 'image',
							resized: true,
							resizeWidth: 158,
							resizeHeight: 82.00000000000003,
							originalWidth: 600,
							currentSrc:
								'https://forms-assets-preproduction-euw-preprod.sitecorecloud.io/fda77e52-7c15-4654-49e1-08dc3e87a6cc/5a24781c52f108dcadcea32bbd4b57b4/frame-492.png',
							originalSrc:
								'https://forms-assets-preproduction-euw-preprod.sitecorecloud.io/fda77e52-7c15-4654-49e1-08dc3e87a6cc/5a24781c52f108dcadcea32bbd4b57b4/frame-492.png',
							link_url: '',
							src: 'https://forms-assets-preproduction-euw-preprod.sitecorecloud.io/fda77e52-7c15-4654-49e1-08dc3e87a6cc/5a24781c52f108dcadcea32bbd4b57b4/frame-492.png',
							alt: 'Email Image',
							background_color: 'transparent',
							imageBorderRadius: 0,
							imageBorderWidth: 0,
							imageBorderColor: '#000000',
							padding: 0,
							multiPadding: { allow: false, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 },
							alignment: 'center',
							mobileProps: { index: 0 },
							loading: false,
							uniqueId: '47244a9f-aba7-45e8-9d32-9dc9c8d31382',
							imageType: 'image/png',
							newComponent: false,
						},
						{
							id: '07dad5d7-e159-4ccb-890d-49ec36b0bf2b',
							type: 'text',
							text: '<p style="text-align: center;"><span style="font-size: 36px;"><strong>Book Your Stay</strong></span></p>',
							background_color: 'transparent',
							textBorderRadius: 0,
							textBorderWidth: 0,
							textBorderColor: '#000000',
							padding: 10,
							multiPadding: { allow: false, paddingTop: 10, paddingRight: 10, paddingBottom: 10, paddingLeft: 10 },
							mobileProps: {
								index: 1,
								text: '<p style="text-align: center;"><span style="font-size: 24px;"><strong>Book Your Stay</strong></span></p>',
								mobileChanged: true,
							},
							loading: false,
							uniqueId: '540b721d-625a-46c8-bf27-edf55fa9e1ca',
							newComponent: false,
						},
						{
							id: '1d958941-b297-4859-8c41-fbf4b957ca50',
							type: 'text',
							text: '<p style="text-align: center;">Experience something new every moment.</p>',
							background_color: 'transparent',
							textBorderRadius: 0,
							textBorderWidth: 0,
							textBorderColor: '#000000',
							padding: 10,
							multiPadding: { allow: false, paddingTop: 10, paddingRight: 10, paddingBottom: 10, paddingLeft: 10 },
							mobileProps: {
								index: 2,
								text: '<p style="text-align: center;"><span style="font-size: 12px;">Experience something new every moment.</span></p>',
								mobileChanged: true,
								padding: 0,
								multiPadding: { allow: false, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 },
							},
							loading: false,
							uniqueId: '0ec987c5-2b3c-4486-bb6f-4f5bf9b7aed4',
							newComponent: false,
						},
					],
					mobileProps: {
						index: 0,
						multiPadding: { paddingTop: 20, paddingLeft: 0, paddingBottom: 20, paddingRight: 0, allow: true },
						mobileChanged: true,
					},
					type: 'FULL',
					uniqueId: '267c2d5f-bfb3-4c5a-99eb-eaebc7771f51',
				},
			],
			isMobileView: false,
			structureWidth: 1000,
			hasRepeatables: 0,
			repeatableRowId: '',
			leftSidebarOptions: {},
		},
		{
			background_image: '',
			background_image_type: '',
			background_repeat: 'no-repeat',
			background_position: 'initial',
			borderWidth: 0,
			borderColor: '#000000',
			borderRadius: 0,
			padding: 0,
			multiPadding: { paddingTop: 0, paddingLeft: 40, paddingBottom: 0, paddingRight: 40, allow: true },
			pageIndex: 0,
			bg_color: 'transparent',
			hidden: false,
			responsive: true,
			behavior: 'NORMAL',
			slot_spacing_side: 0,
			slot_spacing_center: 0,
			mobileProps: {
				index: 1,
				responsive: true,
				multiPadding: { paddingTop: 0, paddingLeft: 20, paddingBottom: 0, paddingRight: 20, allow: true },
				mobileChanged: true,
			},
			background_image_wide: '',
			background_repeat_wide: 'no-repeat',
			background_position_wide: 'initial',
			visibility: { type: 'show', operator: 'all', conditions: [] },
			background_color_wide: 'transparent',
			repeat_count: 5,
			sort_products: 'Orders',
			uniqueId: 'cb533be1-53bd-46d2-9aff-fb9743909d8b',
			id: '4ced48a4-500a-4d24-8bbf-7c345cf9b579',
			type: 'THREE_ONE_THIRDS',
			slots: [
				{
					background_color: 'transparent',
					contentBgColor: 'transparent',
					borderWidth: 0,
					borderColor: '#000000',
					borderRadius: 0,
					padding: 0,
					hidden: false,
					id: '24835465-9b2d-41c8-a642-fcc94c7ef0cf',
					multiPadding: { allow: false, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 },
					vertical_align: 'top',
					components: [
						{
							id: 'cc5c97be-2a75-4f90-b5ae-f0f27081b8b6',
							type: 'text_input',
							name: 'Full name',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 24,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 16,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: true,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Full name',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: '',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 0 },
							maxLength: 200,
							loading: false,
							uniqueId: 'f4bdb9ab-0de6-4e24-8ad8-7a322c954659',
							newComponent: false,
						},
					],
					mobileProps: { index: 0 },
					type: 'ONE_THIRD',
					uniqueId: '49633220-c765-4c99-8cde-71d6512ec8b0',
				},
				{
					background_color: 'transparent',
					contentBgColor: 'transparent',
					borderWidth: 0,
					borderColor: '#000000',
					borderRadius: 0,
					padding: 0,
					hidden: false,
					id: '9117e976-31f6-44a4-ae51-905d57cd548a',
					multiPadding: { allow: false, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 },
					vertical_align: 'top',
					components: [
						{
							id: '4184719e-ff86-4d61-8eb3-21c5d12614b4',
							type: 'email_input',
							name: 'email',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 24,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 16,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: true,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Email',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: '',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 0 },
							loading: false,
							uniqueId: '77b080d4-ba8c-4bbe-92e1-7471b32465f6',
							newComponent: false,
						},
					],
					mobileProps: { index: 1 },
					type: 'ONE_THIRD',
					uniqueId: '9e5f8b1d-f556-42db-8ca8-8cad5ebb66ef',
				},
				{
					background_color: 'transparent',
					contentBgColor: 'transparent',
					borderWidth: 0,
					borderColor: '#000000',
					borderRadius: 0,
					padding: 0,
					hidden: false,
					id: 'd54ab663-66ae-4683-b459-57a476ec0ac3',
					multiPadding: { allow: false, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 },
					vertical_align: 'top',
					components: [
						{
							id: 'b6454304-45d0-4547-9d26-0bc6a87c7afb',
							type: 'phone_input',
							name: 'phone',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 24,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 16,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: true,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Phone',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: '',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 0 },
							loading: false,
							uniqueId: '82611e24-75ed-4de0-af59-f8330d346721',
							newComponent: false,
						},
					],
					mobileProps: { index: 2 },
					type: 'ONE_THIRD',
					uniqueId: '42da41c2-18fa-4d53-96b2-acf874bf2e9d',
				},
			],
		},
		{
			background_image: '',
			background_image_type: '',
			background_repeat: 'no-repeat',
			background_position: 'initial',
			borderWidth: 0,
			borderColor: '#000000',
			borderRadius: 0,
			padding: 0,
			multiPadding: { paddingTop: 0, paddingLeft: 40, paddingBottom: 0, paddingRight: 40, allow: true },
			pageIndex: 0,
			bg_color: 'transparent',
			hidden: false,
			responsive: true,
			behavior: 'NORMAL',
			slot_spacing_side: 0,
			slot_spacing_center: 0,
			mobileProps: {
				index: 2,
				responsive: true,
				multiPadding: { paddingTop: 0, paddingLeft: 20, paddingBottom: 0, paddingRight: 20, allow: true },
				mobileChanged: true,
			},
			background_image_wide: '',
			background_repeat_wide: 'no-repeat',
			background_position_wide: 'initial',
			visibility: { type: 'show', operator: 'all', conditions: [] },
			background_color_wide: 'transparent',
			repeat_count: 5,
			sort_products: 'Orders',
			uniqueId: '729c0372-c6bb-4960-989d-ac4d14179f2a',
			id: '1af6c72b-c410-4aa4-85e9-7f6c3a0eb062',
			type: 'HALF',
			slots: [
				{
					background_color: 'transparent',
					contentBgColor: 'transparent',
					borderWidth: 0,
					borderColor: '#000000',
					borderRadius: 0,
					padding: 0,
					hidden: false,
					id: '8f9c94c1-8d51-4e0e-ac4d-4dcd2e059f51',
					multiPadding: { allow: false, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 },
					vertical_align: 'top',
					components: [
						{
							id: '24701de5-626a-4bf6-b859-8c0d699e01b8',
							type: 'singleselect_input',
							name: 'roomtype',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 24,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 16,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: true,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Room type',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: 'Select option',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 0 },
							options: [
								{ value: 'Option 1', label: 'Option 1' },
								{ value: 'Option 2', label: 'Option 2' },
								{ value: 'Option 3', label: 'Option 3' },
							],
							loading: false,
							uniqueId: 'a32b14ab-d19c-4eea-8eb9-de0559a07740',
							newComponent: false,
						},
					],
					mobileProps: { index: 0 },
					type: 'HALF',
					uniqueId: '4df144ca-1d2c-4ec1-bf19-c750458b6ee7',
				},
				{
					background_color: 'transparent',
					contentBgColor: 'transparent',
					borderWidth: 0,
					borderColor: '#000000',
					borderRadius: 0,
					padding: 0,
					hidden: false,
					id: '61edf00d-9f67-4e92-9f43-3b89452607e1',
					multiPadding: { allow: false, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 },
					vertical_align: 'top',
					components: [
						{
							id: '05f8c7d1-3a41-4a8c-9bff-fea322dcfd98',
							type: 'number_input',
							name: 'numberofguest',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 24,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 16,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: true,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Number of guest',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: 'Enter number',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 0 },
							loading: false,
							uniqueId: 'c7e79bdd-126f-4b00-ac6e-6de08fe2e4a1',
							newComponent: false,
						},
					],
					mobileProps: { index: 1 },
					type: 'HALF',
					uniqueId: 'e5a139ce-354c-4e48-88ee-2a5e8d02a26a',
				},
			],
		},
		{
			background_image: '',
			background_image_type: '',
			background_repeat: 'no-repeat',
			background_position: 'initial',
			borderWidth: 0,
			borderColor: '#000000',
			borderRadius: 0,
			padding: 0,
			multiPadding: { paddingTop: 0, paddingLeft: 40, paddingBottom: 0, paddingRight: 40, allow: true },
			pageIndex: 0,
			bg_color: 'transparent',
			hidden: false,
			responsive: true,
			behavior: 'NORMAL',
			slot_spacing_side: 0,
			slot_spacing_center: 0,
			mobileProps: {
				index: 3,
				responsive: true,
				multiPadding: { paddingTop: 0, paddingLeft: 20, paddingBottom: 0, paddingRight: 20, allow: true },
				mobileChanged: true,
			},
			background_image_wide: '',
			background_repeat_wide: 'no-repeat',
			background_position_wide: 'initial',
			visibility: { type: 'show', operator: 'all', conditions: [] },
			background_color_wide: 'transparent',
			repeat_count: 5,
			sort_products: 'Orders',
			uniqueId: '64e7d1a2-d044-4092-8aa2-8cacf8e3550b',
			id: 'aada1197-42bf-4503-be31-265b07568b90',
			type: 'HALF',
			slots: [
				{
					background_color: 'transparent',
					contentBgColor: 'transparent',
					borderWidth: 0,
					borderColor: '#000000',
					borderRadius: 0,
					padding: 0,
					hidden: false,
					id: '524ac436-22d9-4f14-99af-e9a1a648758f',
					multiPadding: { allow: false, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 },
					vertical_align: 'top',
					components: [
						{
							id: '6e65b7eb-e1fd-46f3-8afc-fe153c2d7476',
							type: 'date_input',
							name: 'checkindate',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 24,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 16,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: true,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Check in date',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: '',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 0 },
							loading: false,
							uniqueId: '54533316-87a4-4807-89e8-41509a7bf597',
							newComponent: false,
						},
					],
					mobileProps: { index: 0 },
					type: 'HALF',
					uniqueId: 'b046b1bf-3b24-4b0b-ac52-98d44e3e669a',
				},
				{
					background_color: 'transparent',
					contentBgColor: 'transparent',
					borderWidth: 0,
					borderColor: '#000000',
					borderRadius: 0,
					padding: 0,
					hidden: false,
					id: 'c82be802-079b-4ef1-ba59-0ea91e885bf6',
					multiPadding: { allow: false, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 },
					vertical_align: 'top',
					components: [
						{
							id: 'cc1b5f91-9b0a-4416-8b2d-d8fbec238e17',
							type: 'date_input',
							name: 'checkoutdate',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 24,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 16,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: true,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Check out date',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: '',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 0 },
							loading: false,
							uniqueId: '7e90ef53-010b-439f-82ec-45d363131102',
							newComponent: false,
						},
					],
					mobileProps: { index: 1 },
					type: 'HALF',
					uniqueId: '94ed6486-c69f-4b55-9f75-34466135f869',
				},
			],
		},
		{
			background_image: '',
			background_image_type: '',
			background_repeat: 'no-repeat',
			background_position: 'initial',
			borderWidth: 0,
			borderColor: '#000000',
			borderRadius: 0,
			padding: 0,
			multiPadding: { paddingTop: 0, paddingLeft: 40, paddingBottom: 40, paddingRight: 40, allow: true },
			pageIndex: 0,
			bg_color: 'transparent',
			hidden: false,
			responsive: true,
			behavior: 'NORMAL',
			slot_spacing_side: 0,
			slot_spacing_center: 0,
			mobileProps: {
				index: 4,
				responsive: true,
				multiPadding: { paddingTop: 0, paddingLeft: 20, paddingBottom: 40, paddingRight: 20, allow: true },
				mobileChanged: true,
			},
			background_image_wide: '',
			background_repeat_wide: 'no-repeat',
			background_position_wide: 'initial',
			visibility: { type: 'show', operator: 'all', conditions: [] },
			background_color_wide: 'transparent',
			repeat_count: 5,
			sort_products: 'Orders',
			uniqueId: 'f9f63d9e-275f-4a7f-9590-bc8c8638a0a9',
			id: '093d097c-43e4-40aa-9dea-752ed397fca3',
			type: 'FULL',
			slots: [
				{
					background_color: 'transparent',
					contentBgColor: 'transparent',
					borderWidth: 0,
					borderColor: '#000000',
					borderRadius: 0,
					padding: 0,
					hidden: false,
					id: '7d87d53a-8ab6-4b62-b847-34b9f7f9ce50',
					multiPadding: { allow: false, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 },
					vertical_align: 'top',
					components: [
						{
							id: 'bcd30e38-c1c5-44be-8651-5ef418e2c8fa',
							type: 'checkbox_group',
							name: 'checkbox_group_c5d74',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 32,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 20,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: false,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Meals included',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: 'Checkbox',
							disabled: false,
							value: '',
							readOnly: false,
							description: '',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 0 },
							optionsLimit: 0,
							options: [
								{ value: 'Breakfast', label: 'Option 1' },
								{ value: 'Lunch', label: 'Option 2' },
								{ value: 'Dinner', label: 'Option 4' },
								{ value: 'All included', label: 'Option 3' },
							],
							loading: false,
							uniqueId: 'c5d74dd5-39f9-43fb-963c-df0614113bc2',
							newComponent: false,
						},
						{
							id: '5867ac1f-8dc3-4b04-abba-038b6c6ba316',
							type: 'radio',
							name: 'airpotpickup',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 30,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 14,
							fieldFontColour: 'rgba(0,0,0,1)',
							fieldBorderWidth: 2,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 17,
							fieldPadding: 9,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: true,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Airport pick up?',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: 'Radio',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 0 },
							options: [
								{ value: 'Yes, pick me up at the airport.', label: 'Option 1' },
								{ value: 'No, I will make my own way there.', label: 'Option 2' },
							],
							loading: false,
							uniqueId: '97d1cbee-11bc-4506-93e4-deabd6af4784',
							newComponent: false,
						},
						{
							id: 'b7bcf42d-2eda-4bab-ba7b-6d70ad821bec',
							type: 'text_input',
							name: 'flightnumber',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 24,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 16,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: true,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Flight number',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: '',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 1 },
							maxLength: 200,
							loading: false,
							uniqueId: 'da616f6d-8036-425d-849c-6c6ddfb96203',
							newComponent: false,
						},
						{
							id: 'ef67c3d1-62a2-457a-bfe0-9548c139e504',
							type: 'longtext_input',
							name: 'specialrequest',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 24,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 16,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: true,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'Special request',
							required: false,
							hidden: false,
							hiddenField: false,
							placeholder: '',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 2 },
							maxLength: 1000,
							loading: false,
							uniqueId: '32dabe1b-ad0d-4bdd-b604-c649660800c8',
							newComponent: false,
						},
						{
							id: '9955258e-61a9-4300-a2c8-a7b81bc726ee',
							type: 'gdpr',
							name: 'gdpr_checkbox_5350d',
							background_color: 'transparent',
							labelFont: 'Arial, Helvetica, sans-serif',
							labelFontSize: 16,
							labelFontColour: '#404040',
							labelPosition: 'column',
							labelTextDecoration: 'none',
							labelFontStyle: 'normal',
							labelFontWeight: 'bold',
							labelWidth: 24,
							asteriskColor: 'rgba(240, 52, 52, 1)',
							placeholderFont: 'Arial, Helvetica, sans-serif',
							placeholderFontSize: 16,
							placeholderFontColour: 'rgba(0,0,0,0.36)',
							placeholderFontWeight: 'normal',
							placeholderTextDecoration: 'none',
							placeholderFontStyle: 'normal',
							fieldFontWeight: 'normal',
							fieldTextDecoration: 'none',
							fieldFontStyle: 'normal',
							fieldFont: 'Arial, Helvetica, sans-serif',
							fieldFontSize: 16,
							fieldFontColour: 'rgba(0, 0, 0, 1)',
							fieldBorderWidth: 1,
							fieldBorderColour: 'rgba(0,0,0,0.42)',
							fieldBorderRadius: 6,
							fieldPadding: 10,
							fieldBgColor: 'rgba(255,255,255,1)',
							descriptionFontColour: 'rgba(0,0,0,0.55)',
							descriptionFontSize: 14,
							descriptionFontFamily: 'Arial, Helvetica, sans-serif',
							descriptionSpacing: 8,
							descriptionHide: false,
							descriptionTextDecoration: 'none',
							descriptionFontStyle: 'normal',
							descriptionFontWeight: 'normal',
							width: 100,
							padding: 16,
							label: 'I accept the terms & conditions',
							required: true,
							hidden: false,
							hiddenField: false,
							placeholder: 'Checkbox',
							disabled: false,
							value: '',
							readOnly: false,
							description: 'Help text…',
							optionsSpacing: 10,
							multiPadding: { allow: false, paddingTop: 16, paddingRight: 16, paddingBottom: 16, paddingLeft: 16 },
							pattern: '',
							mobileProps: { index: 4 },
							legalText: '',
							legalLabel: 'I accept terms',
							loading: false,
							uniqueId: '5350d078-5279-47ad-b844-f7d3c5503f78',
							newComponent: false,
						},
						{
							background_color: 'transparent',
							alignment: 'center',
							theme: 'dark',
							padding: 10,
							multiPadding: { allow: false, paddingTop: 10, paddingRight: 10, paddingBottom: 10, paddingLeft: 10 },
							id: 'eba55868-d284-40e8-93bd-b46dae97ecd5',
							type: 'recaptcha',
							siteKey: '',
							mobileProps: { index: 5 },
							loading: false,
							name: 'recaptcha_71640',
							uniqueId: '7164034b-8e7d-41e1-9078-ef30498996f7',
							newComponent: false,
						},
						{
							id: '0863d6a3-59f2-4b0c-9b58-e062f3ae613f',
							type: 'spacer',
							borderWidth: 0,
							borderRadius: 0,
							borderColor: '#000000',
							height: 32,
							background_color: 'transparent',
							mobileProps: { index: 3 },
							loading: false,
							uniqueId: '0a87d60b-f83f-4c7e-a539-30031085a886',
							newComponent: false,
						},
						{
							id: '9573cf85-4198-42c8-84d5-aa7fba2e751b',
							type: 'submit_button',
							noLink: true,
							background_color: '',
							color: 'rgba(0,0,0,1)',
							text_color: 'rgba(255,255,255,1)',
							alignment: 'center',
							customSize: { height: 60, width: 300 },
							padding: 16,
							multiPadding: { allow: false, paddingTop: 10, paddingRight: 10, paddingBottom: 10, paddingLeft: 10 },
							margin: 0,
							fontFamily: 'Arial, Helvetica, sans-serif',
							fontWeight: 'bold',
							textDecoration: 'none',
							fontStyle: 'normal',
							fontSize: 16,
							width: 'custom',
							borderRadius: 10,
							borderWidth: 0,
							borderColor: '#000000',
							text: 'Book now',
							url: '',
							newWindow: true,
							back_button: {
								text: 'Back',
								background_color: 'transparent',
								color: 'transparent',
								text_color: 'rgba(59,59,59,1)',
								padding: 0,
								alignment: 'center',
								customSize: { height: 100, width: 50 },
								multiPadding: { allow: false, paddingTop: 10, paddingRight: 10, paddingBottom: 10, paddingLeft: 10 },
								margin: 16,
								fontFamily: 'Arial, Helvetica, sans-serif',
								fontWeight: 'bold',
								textDecoration: 'none',
								fontStyle: 'normal',
								fontSize: 16,
								width: 'auto',
								borderRadius: 10,
								borderWidth: 0,
								borderColor: '#000000',
								newWindow: true,
							},
							mobileProps: { index: 4 },
							customCss: '',
							loading: false,
							uniqueId: '0cb57c8f-9adf-42c1-a4e9-8b08280e9793',
							newComponent: false,
						},
						{
							id: 'e2fcbb55-f654-4ff9-abae-a637e6dd12b9',
							type: 'text',
							text: '<p style="text-align: center;">Your information is safe with us!</p>',
							background_color: 'transparent',
							textBorderRadius: 0,
							textBorderWidth: 0,
							textBorderColor: '#000000',
							padding: 10,
							multiPadding: { allow: false, paddingTop: 10, paddingRight: 10, paddingBottom: 10, paddingLeft: 10 },
							mobileProps: { index: 5 },
							loading: false,
							uniqueId: 'c3af0002-c715-4227-9f4e-6c9032a456fd',
							newComponent: false,
						},
						{
							id: '06b4c48d-0a9b-4221-bd06-0b0798df0e66',
							type: 'social_follow',
							background_color: 'transparent',
							spacing: 2,
							alignment: 'center',
							style: 'black-round',
							facebook: '',
							twitter: '',
							instagram: '',
							pinterest: null,
							googleplus: null,
							youtube: null,
							linkedin: null,
							tumblr: null,
							deprecatedUsed: [],
							mobileProps: { index: 9 },
							loading: false,
							uniqueId: '2a0999ee-669d-4b0d-9b57-f98c559965f2',
							newComponent: false,
						},
					],
					mobileProps: { index: 0 },
					type: 'FULL',
					uniqueId: 'a8bbc02b-ac58-434e-9856-ab6465df6137',
				},
			],
		},
	],
	validation_messages: {
		required: 'This field is required',
		email: 'Email address must follow the format user@example.com',
		phone: 'Phone number must follow the format +(country_code)(phone_number)',
		recaptcha: 'Validate using the reCAPTCHA',
		uploadFileType: 'File type is not allowed',
		uploadFileSize: 'File size is too large',
		uploadFileCount: 'Uploaded files must be within the limit',
		uploadFileGenericError: 'File did not upload',
	},
	lastPage: 0,
	customCss: '',
	mobileProps: {},
	logics: [],
	newsletterWidth: null,
	structureAlignment: 'flex-start',
	limitedComponents: ['submit_button'],
	script: {
		head: '// This code will be added to a <Script> tag, \n// at the <head> of the document \n',
		body: '// This code will be added to a <Script> tag, \n// at the end of the <body> element \n',
	},
	primaryProduct: {
		sync: false,
		status: false,
		description: '',
		startDate: '',
		endDate: '',
		couponCode: '',
		src: '',
		resized: false,
		resizeWidth: null,
		resizeHeight: null,
		currentSrc: '',
		originalSrc: '',
	},
	watermark: { style: 'white-left', alignment: 'center', padding: 20 },
	errors: [],
	hasRepeatables: 0,
	repeatableRowId: '',
	customFonts: [
		{
			value: 'Roboto, sans-serif',
			label: 'Roboto',
			embed: 'Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i',
		},
		{
			value: 'Open Sans, sans-serif',
			label: 'Open Sans',
			embed: 'Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i',
		},
		{ value: 'Lato, sans-serif', label: 'Late', embed: 'Lato:100,100i,300,300i,400,400i,700,700i,900,900i' },
		{
			value: 'Montserrat, sans-serif',
			label: 'Montserrat',
			embed: 'Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i',
		},
		{
			value: 'Roboto Condensed, sans-serif',
			label: 'Roboto Condensed',
			embed: 'Roboto+Condensed:300,300i,400,400i,700,700i',
		},
		{
			value: 'Source Sans Pro, sans-serif',
			label: 'Source Sans Pro',
			embed: 'Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i',
		},
		{ value: 'Oswald, sans-serif', label: 'Oswald', embed: 'Oswald:200,300,400,500,600,700' },
		{
			value: 'Raleway, sans-serif',
			label: 'Raleway',
			embed: 'Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i',
		},
		{ value: 'Merriweather, serif', label: 'Merriweather', embed: 'Merriweather:300,300i,400,400i,700,700i,900,900i' },
		{ value: 'Roboto Slab, serif', label: 'Roboto Slab', embed: 'Roboto+Slab:100,300,400,700' },
		{ value: 'PT Sans, sans-serif', label: 'PT Sans', embed: 'PT+Sans:400,400i,700,700i' },
		{ value: 'Slabo 27px, serif', label: 'Slabo', embed: 'Slabo+27px' },
		{
			value: 'Poppins, sans-serif',
			label: 'Poppins',
			embed: 'Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i',
		},
		{ value: 'Ubuntu, sans-serif', label: 'Ubuntu', embed: 'Ubuntu:300,300i,400,400i,500,500i,700,700i' },
		{ value: 'Noto Sans, sans-serif', label: 'Noto Sans', embed: 'Noto+Sans:400,400i,700,700i' },
		{ value: 'Heebo, sans-serif', label: 'Heebo', embed: 'Heebo:100,300,400,500,700,800,900' },
		{
			value: 'Open Sans Condensed, sans-serif',
			label: 'Open Sans Condensed',
			embed: 'Open+Sans+Condensed:300,300i,700',
		},
		{
			value: 'Playfair Display, serif',
			label: 'Playfair Display',
			embed: 'Playfair+Display:400,400i,700,700i,900,900i',
		},
		{ value: 'Lora, serif', label: 'Lora', embed: 'Lora:400,400i,700,700i' },
		{ value: 'PT Serif, serif', label: 'PT Serif', embed: 'PT+Serif:400,400i,700,700i' },
		{
			value: 'Muli, sans-serif',
			label: 'Muli',
			embed: 'Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i',
		},
		{
			value: 'Titillium Web, sans-serif',
			label: 'Titillium Web',
			embed: 'Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900',
		},
		{
			value: 'Roboto Mono, monospace',
			label: 'Roboto Mono',
			embed: 'Roboto+Mono:100,100i,300,300i,400,400i,500,500i,700,700i',
		},
		{
			value: 'Nunito, sans-serif',
			label: 'Nunito',
			embed: 'Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i',
		},
		{ value: 'Rubik, sans-serif', label: 'Rubik', embed: 'Rubik:300,300i,400,400i,500,500i,700,700i,900,900i' },
		{
			value: 'Fira Sans, sans-serif',
			label: 'Fira Sans',
			embed: 'Fira+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i',
		},
		{ value: 'PT Sans Narrow, sans-serif', label: 'PT Sans Narrow', embed: 'PT+Sans+Narrow:400,700' },
		{ value: 'Mukta, sans-serif', label: 'Mukta', embed: 'Mukta:200,300,400,500,600,700,800' },
		{ value: 'Noto Serif, serif', label: 'Noto Serif', embed: 'Noto+Serif:400,400i,700,700i' },
		{ value: 'Arimo, sans-serif', label: 'Arimo', embed: 'Arimo:400,400i,700,700i' },
		{ value: 'Work Sans, sans-serif', label: 'Work Sans', embed: 'Work+Sans:100,200,300,400,500,600,700,800,900' },
		{ value: 'Nanum Gothic, sans-serif', label: 'Nanum Gothic', embed: 'Nanum+Gothic:400,700,800' },
		{ value: 'Inconsolata, monospace', label: 'Inconsolata', embed: 'Inconsolata:400,700' },
		{ value: 'Quicksand, sans-serif', label: 'Quicksand', embed: 'Quicksand:300,400,500,700' },
		{ value: 'Noto Sans KR, sans-serif', label: 'Noto Sans KR', embed: 'Noto+Sans+KR:100,300,400,500,700,900' },
		{ value: 'Dosis, sans-serif', label: 'Dosis', embed: 'Dosis:200,300,400,500,600,700,800' },
		{ value: 'Noto Sans JP, sans-serif', label: 'Noto Sans JP', embed: 'Noto+Sans+JP:100,300,400,500,700,900' },
		{ value: 'Oxygen, sans-serif', label: 'Oxygen', embed: 'Oxygen:300,400,700' },
		{ value: 'Hind, sans-serif', label: 'Hind', embed: 'Hind:300,400,500,600,700' },
		{ value: 'Crimson Text, serif', label: 'Crimson Text', embed: 'Crimson+Text:400,400i,600,600i,700,700i' },
		{ value: 'Bitter, serif', label: 'Bitter', embed: 'Bitter' },
		{ value: 'Indie Flower, cursive', label: 'Indie Flower', embed: 'Indie+Flower' },
		{ value: 'Libre Baskerville, serif', label: 'Libre Baskerville', embed: 'Libre+Baskerville:400,400i,700' },
		{ value: 'Cabin, sans-serif', label: 'Cabin', embed: 'Cabin:400,400i,500,500i,600,600i,700,700i' },
		{ value: 'Anton, sans-serif', label: 'Anton', embed: 'Anton' },
		{
			value: 'Josefin Sans, sans-serif',
			label: 'Josefin Sans',
			embed: 'Josefin+Sans:100,100i,300,300i,400,400i,600,600i,700,700i',
		},
		{
			value: 'Nunito Sans, sans-serif',
			label: 'Nunito Sans',
			embed: 'Nunito+Sans:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i',
		},
		{
			value: 'Libre Franklin, sans-serif',
			label: 'Libre Franklin',
			embed: 'Libre+Franklin:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i',
		},
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Fjalla One, sans-serif', label: 'Fjalla One', embed: 'Fjalla+One' },
		{ value: 'Arvo, serif', label: 'Arvo', embed: 'Arvo:400,400i,700,700i' },
	],
	templateId: 'f8634279-e65b-4ca2-b21f-a32f8218766a',
	lastPageAll: 0,
};
