import { ErrorMessages } from 'config/messages';
import { NativeTypes } from 'react-dnd-html5-backend';

import useContentStore from '@/stores/ContentStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';

import { findElementAddressById, simpleObjectEquals, stringifyAddress } from '@/util/helper';
import itemTypes from '@/util/itemTypes';
import { LimitedComponentTypes, NotificationTypes, SlotsNotAllowedForElementDesigner } from '@/util/resources';
import { AllComponentTypes } from '@/util/shared';

import Button from './elements/Button.react';
import Checkbox from './elements/Checkbox.react';
import CheckboxGroup from './elements/CheckboxGroup.react';
import DateInput from './elements/DateInput.react';
import EmailInput from './elements/EmailInput.react';
import FieldPlaceholder from './elements/FieldPlaceholder.react';
import FileUpload from './elements/FileUpload';
import GDPRbox from './elements/GDPRbox.react';
import Image from './elements/Image.react';
import LongTextInput from './elements/LongTextInput.react';
import MultiSelect from './elements/MultiSelect.react';
import NumberInput from './elements/NumberInput.react';
import PhoneInput from './elements/PhoneInput.react';
import Radio from './elements/Radio.react';
import Recaptcha from './elements/Recaptcha.react';
import SingleSelect from './elements/SingleSelect.react';
import SocialFollow from './elements/SocialFollow.react';
import Spacer from './elements/Spacer.react';
import Text from './elements/Text.react';
import TextInput from './elements/TextInput.react';

export let _dynamicElements = {
	submit_button: Button,
	image: Image,
	social_follow: SocialFollow,
	spacer: Spacer,
	text: Text,
	radio: Radio,
	checkbox: Checkbox,
	checkbox_group: CheckboxGroup,
	email_input: EmailInput,
	phone_input: PhoneInput,
	text_input: TextInput,
	longtext_input: LongTextInput,
	number_input: NumberInput,
	date_input: DateInput,
	singleselect_input: SingleSelect,
	multiselect_input: MultiSelect,
	file_upload: FileUpload,
	gdpr: GDPRbox,
	recaptcha: Recaptcha,
	fieldPlaceholder: FieldPlaceholder,
};

export const containerSpec = {
	drop(props, monitor, component) {
		if (!props.isMobileView) {
			const { address: originalAddress, type, newComponent, id } = monitor.getItem();
			const { address } = props;

			const contentStore = useContentStore.getState();

			const slotsNotAllowed = SlotsNotAllowedForElementDesigner;

			if (monitor.getItemType() !== NativeTypes.FILE && slotsNotAllowed[type].includes(props.type)) {
				showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.WRONG_SLOT });
			}

			if (monitor.getItemType() === itemTypes.ELEMENT && !slotsNotAllowed[type].includes(props.type)) {
				// if (!monitor.didDrop()) {
				// 	address.component = props.components.length;

				// 	contentStore.moveComponent({ fromAddress: originalAddress, toAddress: address });
				// }

				if (type === AllComponentTypes.recaptcha && props.lastPage !== props.pageIndex) {
					showNotification({
						type: NotificationTypes.WARNING,
						text: 'reCAPTCHA should be always added at the last page of your form.',
					});
					return;
				}

				const limitedComponents = contentStore.getLimitedComponents()[props.pageIndex];

				if (limitedComponents && limitedComponents.find((item) => item.type === type && item.id !== id)) {
					// contentStore.removeComponent(originalAddress);
					showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
					return;
				} else {
					contentStore.addSubmitButton(
						{ ...originalAddress, component: originalAddress.component + 1 },
						{ componentType: type, pageIndex: props.pageIndex },
					);
				}

				if (!props.components.length) {
					const duplicateAddress = findElementAddressById(contentStore.content.rows, monitor.getItem().id);

					if (duplicateAddress) {
						// contentStore.removeComponent(duplicateAddress);
						contentStore.moveComponent({ fromAddress: duplicateAddress, toAddress: address });
					} else {
						contentStore.insertComponent({
							insertAddress: address,
							componentData: {
								type: monitor.getItem().type,
								loading: monitor.getItem().isCustomElement ? true : false,
								id: monitor.getItem().id,
							},
							additionalData: { componentType: monitor.getItem().type, pageIndex: props.pageIndex },
						});
					}
				}

				//remove compoent from original address if not allowed in that slot type
			} else if (monitor.getItemType() === itemTypes.ELEMENT && newComponent && slotsNotAllowed[type].includes(props.type)) {
				contentStore.removeComponent(originalAddress);
			} else if (monitor.getItemType() === NativeTypes.FILE && slotEmpty(props)) {
				onDropImage(monitor.getItem().files, component);
			}
		} else {
			const { address } = monitor.getItem();
			const { address: overAddress } = props;

			useContentStore.getState().moveSlot({ fromAddress: address, toAddress: overAddress });
		}
	},

	hover(props, monitor) {
		const { address } = props;
		const { type, pageIndex } = monitor.getItem();

		if (
			LimitedComponentTypes.includes(type) &&
			useContentStore.getState().validity.limitedComponents[props.pageIndex].find((item) => item.type === type) &&
			pageIndex !== props.pageIndex
		) {
			return;
		}

		if (type === AllComponentTypes.recaptcha && props.lastPage !== props.pageIndex) {
			return;
		}

		if (!props.components.length && !SlotsNotAllowedForElementDesigner[type].includes(props.type)) {
			const elementAddress = monitor.getItem().address;
			if (!elementAddress) {
				useContentStore.getState().insertComponent({
					insertAddress: address,
					componentData: {
						type: monitor.getItem().type,
						loading: monitor.getItem().isCustomElement ? true : false,
						id: monitor.getItem().id,
					},
					additionalData: { componentType: monitor.getItem().type, pageIndex: props.pageIndex },
				});
				monitor.getItem().address = { ...address, component: props.components.length };
			} else {
				const toAddress = { ...address, component: props.components.length };
				useContentStore.getState().moveComponent({ fromAddress: elementAddress, toAddress });
				monitor.getItem().address = toAddress;
			}
		}
	},

	canDrop(props, monitor) {
		const { address } = monitor.getItem();
		const { address: overAddress } = props;

		if (props.isMobileView && address.rowId !== overAddress.rowId) {
			return false;
		}

		return true;
	},
};

export function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
	};
}

function onDropImage(files, component) {
	if (files && files.length !== 0) {
		// const name = files[0].name;
		// const extension = `.${name.split('.').pop()}`;

		// if(!config.accepted_image_formats.includes(extension.toLowerCase())){
		//     showNotification({ms: 5000, type: NotificationTypes.WARNING, text: WarningMessages.WRONG_IMAGE_FORMAT});
		//     return;
		// }

		let address = { ...component.props.address, component: 0 };
		let componentData = { ...useContentStore.getState().general_settings.components[AllComponentTypes.image] };

		//insert component first
		useContentStore.getState().insertComponent({ insertAddress: address, componentData });

		let progress = (e) => {
			let percentCompleted = (e.loaded / e.total) * 100;
			component.setState({ progress: percentCompleted });
			let listener = () => {
				component.setState({ progress: 0 });
				let el = document.querySelector(`.element${stringifyAddress(address)} img.content-image`);
				if (el) el.removeEventListener('load', listener);
			};
			let el = document.querySelector(`.element${stringifyAddress(address)} img.content-image`);

			if (el) el.addEventListener('load', listener);
		};

		useModalStore.getState().showImagePickerModal({
			id: Date.now(),
			image: '',
			file: files[0],
			details: address,
			type: 'element',
			progressFunc: progress,
		});
	}
}

export const sourceSpec = {
	beginDrag(props) {
		useContentStore.getState().toggleDragging(props.id);
		return {
			address: props.address,
			type: props.type,
		};
	},

	endDrag() {
		useContentStore.getState().toggleDragging();
	},

	// canDrag(props: Props){
	//     if (props.isMobileView){
	//         return false;
	//     }
	//     return true;
	// }

	canDrag() {
		// TEMP
		// if (props.isMobileView) {
		// 	return true;
		// }
		return false;
	},
};

export function sourceCollect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
		connectDragPreview: connect.dragPreview(),
	};
}

export function slotEmpty(props) {
	return (props.components.length === 1 && props.components[0].newComponent) || props.components.length === 0;
}

export const compareAddress = (address1, slotAddress) => {
	let first = { ...address1 };
	delete first.component;
	return simpleObjectEquals(first, slotAddress);
};
