import { Box, SimpleGrid, theme } from '@chakra-ui/react';

import useContentStore from '@/stores/ContentStore';
import useEntitiesStore from '@/stores/EntitiesStore';
import useTemplateStore from '@/stores/TemplateStore';

import { hasBlueprints, isTemplateMgm } from '@/util/helper';
import { ApiAuthorisationModes } from '@/util/resources';

import { EntityStatuses } from '@/components/Table/strings';

import Filters from './market/Filters.react';
import MarketLeftSidebarMenu from './market/MarketLeftSidebarMenu.react';
import SearchBox from './market/SearchBox.react';
import TemplateThumb from './market/TemplateThumb.react';

const applyFilters = (templatesMetadata, ...filterFunctions) => {
	return filterFunctions.reduce((acc, filterFunc) => acc.filter(filterFunc), templatesMetadata);
};

const categoryFilter = (currentCategory) => {
	return (templateMetadata) => {
		if (currentCategory !== 'All categories') {
			return templateMetadata.categories.includes(currentCategory);
		} else {
			return true;
		}
	};
};

const personalFilter = (filterId, data) => {
	return (templateMetadata) => {
		switch (filterId) {
			case 1:
				return data.favouriteTemplates.includes(templateMetadata.id);
			case 2:
				return templateMetadata.AuthorisationMode === ApiAuthorisationModes.Private;
			case 3:
				return templateMetadata.AuthorisationMode === ApiAuthorisationModes.Public;
			case 4:
				return templateMetadata.AuthorisationMode === ApiAuthorisationModes.Organization;
			default:
				return true;
		}
	};
};

const searchFilter = (searchTerm) => {
	return (templateMetadata) => {
		if (!searchTerm) {
			return true;
		}
		return templateMetadata.title ? templateMetadata.title.toLowerCase().includes(searchTerm.toLowerCase()) : false;
	};
};

const TemplateMarket = () => {
	const {
		templatesMetadata,
		favouriteTemplates,
		currentCategory,
		currentPersonalFilter,
		availablePersonalFilters,
		search_term,
		templateCategories,
	} = useTemplateStore();
	const isPublishedEntity = useEntitiesStore((state) => state.activeEntity?.Entity.Status === EntityStatuses.active);

	if (isPublishedEntity) {
		return null;
	}

	const filteredTemplatesMetadata = applyFilters(
		templatesMetadata,
		personalFilter(currentPersonalFilter, { favouriteTemplates }),
		categoryFilter(currentCategory),
		searchFilter(search_term),
	);

	const templatesMetadataList = filteredTemplatesMetadata
		.map((temp, index) => (
			<TemplateThumb currentCategory={currentCategory} favouriteTemplates={favouriteTemplates} template={temp} key={index} />
		))
		.filter((item) => item !== null);

	if ((!hasBlueprints() && !useContentStore.getState().content.rows.length) || isTemplateMgm()) {
		const createNew = <TemplateThumb currentCategory={currentCategory} fromScratch key="scratch-template-thumb" />;
		templatesMetadataList.unshift(createNew);
	}

	return (
		<SimpleGrid
			columns={2}
			templateColumns={{ base: '4fr 8fr', md: '3fr 9fr', lg: '2fr 10fr' }}
			spacing="7"
			p="7"
			pt={10}
			maxW={2000}
			margin="0 auto"
		>
			<Box h="full">
				<MarketLeftSidebarMenu currentCategory={currentCategory} templateCategories={templateCategories} />
			</Box>

			<Box h="full" overflowY="auto">
				<Filters availablePersonalFilters={availablePersonalFilters} currentPersonalFilter={currentPersonalFilter} />

				<SearchBox searchTerm={search_term} />

				{templatesMetadataList.length ? (
					<SimpleGrid my={5} spacing={5} gridTemplateColumns={`repeat(auto-fill, minmax(${theme.sizes['2xs']}, 1fr))`}>
						{templatesMetadataList}
					</SimpleGrid>
				) : (
					<Box my={24} textAlign="center" fontWeight="semibold" fontSize="xl">
						No templates to display for this category or filter
					</Box>
				)}
			</Box>
		</SimpleGrid>
	);
};

export default TemplateMarket;
