import React from 'react';

import { getPadding, getShadowValFromProps, getTransformerFormFieldFontStyles } from 'xmc-forms/src/util/shared.js';

export const Checkbox = (props) => {
	const { formId } = props;

	const id = props.id ? props.id.toString().replace(/-/g, '') : '';

	return (
		<div
			id={id}
			className={`component checkbox-field ${props.required ? 'required-component' : ''} checkbox-component checkbox${props.id.replace(
				/-/g,
				'',
			)} form-input-wrapper`}
		>
			{props.legalText && <div className="form-field-legal-text" dangerouslySetInnerHTML={{ __html: props.legalText }} />}
			<label
				className={`form-input-wrapper-element lp-flex-container lp-flex-direction-horizontal lp-flex-align-center form-input-${formId} ${
					props.legalText ? 'legalLabel' : ''
				}`}
			>
				<div className="form-input-wrapper-div">
					<input
						data-id={id}
						className={`global-checkbox-element form-input-checkbox-element form-input-element params-${props.type}`}
						data-type={props.type}
						data-label={props.label}
						data-required={props.required}
						// data-validate={props.validate || false}
						type="checkbox"
						placeholder={props.placeholder}
						disabled={props.disabled}
						name={props.name}
						defaultChecked={props.isCheckboxGroup ? false : props.value}
						value={props.value}
						required={props.required}
						data-is-checkbox-group={props.isCheckboxGroup}
						// readOnly={props.readOnly}
					/>
					<span className="global-checkbox-check-marker checkbox-check-marker mdi mdi-check  " />
				</div>
				<span className="global-input-label form-field-label ">
					{props.label}
					<span className="global-field-required form-field-required "> *</span>
				</span>
			</label>
			{!props.descriptionHide && props.description && (
				<div className="global-input-description form-input-description  ">{props.description}</div>
			)}
		</div>
	);
};

export const generateCheckboxClass = (component) => {
	return `
        .checkbox${component.id.replace(/-/g, '')} {
            width: 100%; 
            background-color: ${component.background_color};
            display: ${component.hidden ? 'none' : 'block'};
			${getPadding(component)}
        }

        .checkbox${component.id.replace(/-/g, '')} label .form-field-label{
            color: ${component.labelFontColour};
            font-size: ${component.labelFontSize}px;
            font-weight: ${component.labelFontWeight};
            font-style: ${component.labelFontStyle};
            text-decoration: ${component.labelTextDecoration};
            display: ${component.labelHide ? 'none' : 'block'};
			margin-left: 10px;
			display: ${component.labelHide ? 'none' : 'flex'};
            ${getTransformerFormFieldFontStyles(component.labelFont)}
        }

        .checkbox${component.id.replace(/-/g, '')} .form-field-required {
            color: ${component.asteriskColor};
        }

        .checkbox${component.id.replace(/-/g, '')} .form-input-element {
            background: ${component.fieldBgColor};
			padding: ${component.fieldPadding}px;
			color: ${component.fieldFontColour};
			font-size: ${component.fieldFontSize}px;
			border-width: ${component.fieldBorderWidth}px;
			border-color: ${component.fieldBorderColour};
			border-radius: ${component.fieldBorderRadius}px;
			font-weight: ${component.fieldFontWeight};
			font-style: ${component.fieldFontStyle};
			text-decoration: ${component.fieldTextDecoration};
            box-shadow: ${getShadowValFromProps(component.box_shadow)};
        }

        .checkbox${component.id.replace(/-/g, '')} .form-input-description, .checkbox${component.id.replace(
					/-/g,
					'',
				)} .form-input-error-message {
            font-style: ${component.descriptionFontStyle};
			font-weight: ${component.descriptionFontWeight};
			text-decoration: ${component.descriptionTextDecoration};
            color: ${component.descriptionFontColour};
            font-family: ${component.descriptionFontFamily};
            font-size: ${component.descriptionFontSize}px;
            margin-top: ${component.descriptionSpacing}px;
            display: ${component.descriptionHide ? 'none' : 'block'};
        }

		.checkbox${component.id.replace(/-/g, '')} .checkbox-check-marker {
            display: ${component.value ? 'block' : 'none'};
			margin-left: ${0 - component.fieldFontSize / 2}px;
			margin-top: ${0 - component.fieldFontSize / 2}px;
			color: ${component.fieldFontColour};
			font-size: ${component.fieldFontSize}px;
        }

		label.legalLabel {
			min-height: ${component.labelWidth}px
		}
    `;
};

export default Checkbox;
