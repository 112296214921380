import { memo, useMemo } from 'react';

import { FormControl, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

import { trunc } from '@/util/helper';

import { settingsLabelStyle } from './Settings.styles';

const DropDownSetting = ({ options, onSelectOption, onChange, type, selected, label, customStyle, selectProps }) => {
	const onOptionClick = (selectedOption) => {
		if (onSelectOption) onSelectOption(selectedOption.value);

		if (onChange) onChange({ type: type, value: selectedOption.value });
	};

	const formattedOptions = useMemo(() => {
		return options.map((item) => {
			const value = item.label || item.value;
			const truncatedValue = trunc(value, 30);

			return { value: item.value, label: truncatedValue };
		});
	}, [options]);

	const selectedOption = useMemo(() => options.find((option) => option.value === selected), [options, selected]);

	return (
		<FormControl mb={5} style={customStyle} id={`${type}-dropdown-setting`}>
			<FormLabel {...settingsLabelStyle}>{label}</FormLabel>
			<Select
				useBasicStyles
				menuPosition="fixed"
				selectedOptionStyle="check"
				onChange={(selectedOption) => onOptionClick(selectedOption)}
				value={selectedOption}
				options={formattedOptions}
				className="notranslate"
				placeholder={'Select an option'}
				{...selectProps}
			/>
		</FormControl>
	);
};

export default memo(DropDownSetting);
