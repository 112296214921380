import React from 'react';

import useContentStore from '@/stores/ContentStore';

import { SettingsBox } from '@/components/gui/settingsContext/SettingsContext';
import AlignmentSetting from '@/components/gui/shared/settings/AlignmentSetting.react';
import DropDownSetting from '@/components/gui/shared/settings/DropDownSetting.react';
import PaddingSetting from '@/components/gui/shared/settings/PaddingSetting.react';

const AlignOptions = [
	{
		value: 'left',
		label: 'Left',
	},
	{
		value: 'center',
		label: 'Center',
	},
	{
		value: 'right',
		label: 'Right',
	},
];

export const RecaptchaSettingComponents = React.memo((props) => {
	const { onChange } = props;
	return (
		<SettingsBox blockId={props.blockId}>
			{!props.isMobileView && (
				<DropDownSetting
					type="theme"
					onChange={onChange}
					selected={props.theme}
					label="Theme"
					options={[
						{
							value: 'light',
							label: 'Light',
						},
						{
							value: 'dark',
							label: 'Dark',
						},
					]}
				/>
			)}
			<PaddingSetting
				multiPadding={props.multiPadding}
				padding={props.padding}
				type="multiPadding"
				label="Padding"
				secondaryType="padding"
				onChange={onChange}
			/>
			<AlignmentSetting type="alignment" onChange={props.onChange} selected={props.alignment} options={AlignOptions} label="Alignment" />
		</SettingsBox>
	);
});

const RecaptchaSettings = (props) => {
	const saveElementSettings = useContentStore((state) => state.saveElementSettings);

	const onChange = (data) => {
		let settings = {};

		if (Array.isArray(data)) {
			data.forEach((setting) => {
				settings = {
					...settings,
					[setting.type]: setting.value,
				};
			});
		} else {
			settings = {
				[data.type]: data.value,
			};
		}

		if (props.address) {
			saveElementSettings({
				address: props.address,
				settings,
			});
		}
	};

	return (
		<div className="email-input-settings">
			<RecaptchaSettingComponents {...props} onChange={onChange} />
		</div>
	);
};

export default RecaptchaSettings;
