import React from 'react';

import { Box, IconButton, Spinner, Tooltip } from '@chakra-ui/react';
import { mdiArrowTopLeftBottomRight, mdiTrayArrowUp } from '@mdi/js';
import { Resizable } from 're-resizable';
import { DropTarget } from 'react-dnd';
//$FlowIgnore
import { NativeTypes } from 'react-dnd-html5-backend';

import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import useModalStore from '@/stores/ModalStore';

import domHelper from '@/util/domHelper';
import { getResizedHeightFromWidth, stringifyAddress } from '@/util/helper';
import { ImageWidthLimitsContent, ImageWidthLimitsUI } from '@/util/resources';
import { getMaxConstraints, getPadding } from '@/util/shared';

import { Icon } from '@/components/gui/shared/Icon';

import ElementComponent from './ElementComponent.react';

export const progressBar = {
	zIndex: 12,
	position: 'absolute',
	top: 2,
	height: '5px',
	backgroundColor: '#2d2d2d',
	transition: 'width .5s',
};

const fileTarget = {
	drop(props, monitor, component) {
		onDrop(monitor.getItem().files, component);
	},
};

class SharedImage extends ElementComponent {
	constructor(props) {
		super(props);

		this.state = {
			imgHeight: parseInt(this.props.height, 10) || 0,
			imgWidth: this.props.width,
			progress: 0,
			sizeSetDone: false,
			heightLimit: 2000, //changed dynamically using ref
		};
	}

	componentWillReceiveProps(nextProps) {
		if (!isNaN(parseInt(nextProps.height)) && this.props.src === nextProps.src) {
			this.setState({ imgHeight: parseInt(nextProps.height) });
		}
	}

	render() {
		let { connectDropTarget, isOver } = this.props;

		const wrapperStyle = { border: (isOver && '2px dashed #666') || 'none' };

		const doubleClick = !this.props.isMobileView && !this.props.disabled ? this.onDoubleClickPlaceholder : undefined;

		return connectDropTarget(
			<div onWheel={this.onScroll} onDoubleClick={doubleClick} style={wrapperStyle} data-testid="shared-image-wrapper">
				<div style={{ position: 'relative' }}>
					<WrappedImage
						{...this.props}
						{...this.state}
						onResizeStop={this.onResizeStop}
						onLoadImage={this.imageLoadListener}
						imgAttributes={this.props.imgAttributes}
					/>
					<ImagePlaceholder
						hide={this.props.isMobileView ? !this.props.isMobileView : !this.props.src}
						placeholderText={this.props.placeholderText}
					/>
					<div
						className="progress-bar"
						style={{
							...progressBar,
							display: this.state.progress === 0 ? 'none' : 'block',
							width: `${this.state.progress}%`,
						}}
					></div>
				</div>
			</div>,
		);
	}

	onDoubleClickPlaceholder = () => {
		useModalStore.getState().showImagePickerModal({
			id: Date.now(),
			image: this.props.src,
			details: this.props.address,
			type: 'element',
			progressFunc: this.progressFunc,
		});
	};

	progressFunc = (e) => {
		let percentCompleted = (e.loaded / e.total) * 100;
		this.setState({ progress: percentCompleted });
	};

	imageLoadListener = (fromRef = false) => {
		return (e) => {
			//remove upload progress bar, if this listener is not being executed from the 'ref' callback
			if (!fromRef) {
				this.setState({ progress: 0 });
			}

			//set proper height for the resizer
			let h = e.target.height;
			let w = e.target.width;

			this.setState({
				imgHeight: h,
				imgWidth: w,
				heightLimit: getResizedHeightFromWidth(w, h, this.props.slotWidth),
			});
		};
	};

	onResizeStop = (ev, data) => {
		let { size } = data;

		if (size.width === ImageWidthLimitsUI[this.props.slotType][this.props.type]) {
			size.height = getResizedHeightFromWidth(size.width, size.height, ImageWidthLimitsContent[this.props.slotType][this.props.type]);
		}

		if (size.width === ImageWidthLimitsUI[this.props.slotType][this.props.type]) {
			size.width = ImageWidthLimitsContent[this.props.slotType][this.props.type];
		}

		setTimeout(() => {
			this.props.onResizeStop(ev, data);
		}, 0);
	};

	onScroll = (event) => {
		let scroll = 0;

		if (this.props.activeElement) {
			event.preventDefault();
			event.stopPropagation();

			let image = document.querySelector(`img#image-${stringifyAddress(this.props.address)}`);

			if (event.deltaY < 0 && parseInt(image.clientWidth) > 40) {
				scroll = -1;
			} else if (event.deltaY > 0 && parseInt(image.clientWidth) < this.props.slotWidth) {
				scroll = 1;
			}

			const width = image.clientWidth + scroll;
			const height = (width * image.clientHeight) / image.clientWidth;

			this.onResizeStop(event, { size: { width, height } });
		}
	};
}

export const ImagePlaceholder = (props) => {
	if (props.hide) {
		return (
			<Box
				className="content-image-placeholder"
				border="3px dashed"
				borderColor="chakra-border-color"
				px={1.5}
				py={8}
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				color="chakra-subtle-text"
				fontWeight="semibold"
				position="relative"
				m={4}
				h={!props.placeholderText ? '133px' : 'auto'}
				onDoubleClick={props.onDoubleClick}
			>
				<Box position="absolute" top={0} left={0} right={0} bottom={0} />
				<Box>
					<Icon path={mdiTrayArrowUp} size="12" color="chakra-placeholder-color" />
				</Box>
				<Box mt={5} display={props.placeholderText ? 'block' : 'none'}>
					<Box as="span" fontWeight="semibold">
						{props.text || 'Double-click here, or drag and drop your image'}
					</Box>
				</Box>
			</Box>
		);
	}

	return null;
};

const WrappedImage = (props) => {
	const [slotWidth, setSlotWidth] = React.useState(props.slotWidth);
	const [loader, setLoader] = React.useState(false);

	React.useEffect(() => {
		setSizeState({ width: props.width, height: props.height });
	}, [props.width, props.height]);

	let width = parseInt(props.width);
	const finalMaxWidth = getMaxConstraints(props, props.slotWidth);

	let initalWidth = width > finalMaxWidth ? finalMaxWidth : width;
	const [sizeState, setSizeState] = React.useState({ width: initalWidth, height: props.imgHeight });

	// let key = props.imgHeight + parseInt(props.width) + 'image';

	let imageSrc = props.src;

	const padding = getPadding(props, true);

	const extraStyles = props.extraStyles ? props.extraStyles : {};

	React.useEffect(() => {
		if (slotWidth && props.slotWidth !== slotWidth) {
			setSlotWidth(props.slotWidth);
			setLoader(true);
			setTimeout(() => {
				setLoader(false);
			}, 200);
		}
	}, [props.slotWidth, slotWidth]);

	if (loader) {
		return (
			<Box h="48" w="100%" display="flex" alignItems="center" justifyContent="center">
				<Spinner boxSize="8" />
			</Box>
		);
	}

	return (
		<div style={{ display: (!props.src && 'none') || 'block', ...padding }}>
			<Box
				maxW={sizeState.width}
				w="100%"
				h={props.resized ? (sizeState.height > props.heightLimit ? props.heightLimit : sizeState.height) : 'auto'}
				transition="unset"
			>
				<Resizable
					minWidth={40}
					minHeight={40}
					maxWidth={finalMaxWidth}
					maxHeight={extraStyles.borderWidth ? props.heightLimit + 2 * extraStyles.borderWidth : props.heightLimit}
					size={{
						height: '100%',
						width: '100%',
					}}
					onResizeStop={(e, direction, ref) => {
						props.onResizeStop(e, { size: { width: ref.clientWidth, height: ref.clientHeight } });
					}}
					onResize={(e, direction, ref) => {
						setSizeState({ width: ref.clientWidth, height: ref.clientHeight });
					}}
					lockAspectRatio
					enable={{
						bottomRight: props.activeElement,
					}}
					handleStyles={{
						bottomRight: {
							width: 27,
							height: 27,
							bottom: 0,
							right: 0,
						},
					}}
					handleComponent={{
						bottomRight: (
							<Tooltip label="Resize">
								<IconButton
									size="sm"
									colorScheme="neutral"
									aria-label="Resize"
									display="flex"
									onMouseDown={(e) => e.preventDefault()}
									boxSize={'6'}
									minW="auto"
									minH="auto"
									alignItems="center"
									justifyContent="center"
									cursor="nwse-resize"
									icon={<Icon path={mdiArrowTopLeftBottomRight} />}
									borderRadius="sm"
									style={{ userSelect: 'none' }}
								/>
							</Tooltip>
						),
					}}
				>
					<img
						onLoad={props.onLoadImage(false)}
						id={`image-${stringifyAddress(props.address)}`}
						src={imageSrc}
						alt={props.alt}
						className="content-image"
						width="100%"
						height="auto"
						style={{ border: '0', maxHeight: '100%', ...extraStyles }}
						ref={(img) => {
							// because onload event is not fired if image is from cache, here we fire it manually,this could impact performance because it is executed a lot
							// if (img && img.complete) {
							// 	console.log(img.clientHeight, img.clientWidth, 'asd')
							// 	props.onLoadImage(true)({ target: { height: img.clientHeight, width: img.clientWidth } });
							// }

							domHelper.setAttributeList(img, props.imgAttributes || []);
							domHelper.applyStyles(img, { verticalAlign: 'top' });
						}}
					/>
				</Resizable>
			</Box>
		</div>
	);
};

// <ResizableBox
// 				// key={key}
// 				width={initalWidth}
// 				height={props.imgHeight}
// 				lockAspectRatio={true}
// 				minConstraints={[20, 20]}
// 				maxConstraints={[finalMaxWidth, props.heightLimit]}
// 				onResizeStop={(e, obj) => props.onResizeStop(e, obj)}
// 				draggableOpts={{ onMouseDown: (e) => e.preventDefault() } /*to prevent dragging the whole component*/}
// 				className={`image-wrapper ${props.activeElement && !isGif ? '' : 'handle-hidden'}`}
// 			>
// 				<img
// 					onLoad={props.onLoadImage(false)}
// 					id={`image-${stringifyAddress(props.address)}`}
// 					src={imageSrc}
// 					alt={props.alt || 'Email image'}
// 					className="content-image"
// 					width="100%"
// 					height="auto"
// 					style={{ border: '0', borderRadius: props.imageBorderRadius }}
// 					ref={(img) => {
// 						// because onload event is not fired if image is from cache, here we fire it manually,this could impact performance because it is executed a lot
// 						// if (img && img.complete) {
// 						// 	console.log(img.clientHeight, img.clientWidth, 'asd')
// 						// 	props.onLoadImage(true)({ target: { height: img.clientHeight, width: img.clientWidth } });
// 						// }

// 						domHelper.setAttributeList(img, props.imgAttributes || []);
// 						domHelper.applyStyles(img, { verticalAlign: 'top' });
// 					}}
// 				/>
// 			</ResizableBox>

/**
 * @param files - native browser file objects taken from file input events
 * @param context - the react component instance onto which the drop is taking place (used to manipulate the progress bar and display the new image in the correct component)
 */
function onDrop(files, context) {
	useLeftSidebarStore.getState().toggleElementSettings({ address: context.props.address, component: context.props });

	if (files && files.length !== 0) {
		let progress = (e) => {
			let percentCompleted = (e.loaded / e.total) * 100;
			context.setState({ progress: percentCompleted });
		};

		useModalStore.getState().showImagePickerModal({
			id: Date.now(),
			image: context.props.src,
			file: files[0],
			details: context.props.address,
			type: 'element',
			progressFunc: progress,
		});
	}
}

export default DropTarget(NativeTypes.FILE, fileTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
}))(SharedImage);
