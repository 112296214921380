import React, { useState } from 'react';

import {
	Badge,
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	FormControl,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputLeftElement,
	Link,
	Text,
} from '@chakra-ui/react';
import { mdiMagnify, mdiPlus } from '@mdi/js';
import { Select } from 'chakra-react-select';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareMemo } from 'use-deep-compare';
import { shallow } from 'zustand/shallow';

import useEntitiesStore from '@/stores/EntitiesStore';

import { Icon } from '@/components/gui/shared/Icon';
import MoreMenu from '@/components/gui/shared/MoreMenu';
import BaseTable from '@/components/Table/components/BaseTable';
import { EmptyPage } from '@/components/Table/components/EmptyPage';
import { WebhooksTableLoader } from '@/components/Table/components/skeletons/WebhooksTableLoader';
import { TablePagination } from '@/components/Table/components/TablePagination';
import { baseFlippedProps, getQuerySearch, getQueryStrings, getWebhookFiltersFromQuery } from '@/components/Table/helpers';
import {
	getRoute,
	InUseOptions,
	SearchTermMinLength,
	WebhookOptions,
	WebhookOrderByTypes,
	WebhooksDefaultSorting,
	WebhookTypeProperties,
	WebhookTypes,
	WebhookTypesLabels,
} from '@/components/Table/strings';

const orderCategories = {
	Name: { filter: 'Name', label: 'Name' },
	AuthType: { filter: 'Auth type', label: 'Auth type' },
	IsActive: { filter: 'IsActive', label: 'Status' },
	Action: { filter: '', label: '' },
};

const WebhooksConnector = (props) => {
	const webhookData = useEntitiesStore((state) => {
		return {
			webhooks: state.webhooks,
			getWebhooks: state.getWebhooks,
			searchWebhooks: state.searchWebhooks,
			deleteWebhook: state.deleteWebhook,
			createWebhook: state.createWebhook,
			updateWebhook: state.updateWebhook,
			loaders: state.loaders,
		};
	}, shallow);
	const location = useLocation();
	const params = useParams();

	return <WebhooksContainer {...webhookData} {...props} location={location} params={params} />;
};

const SelectStyles = {
	container: (provided) => ({
		...provided,
		mx: 1.5,
		width: 220,
	}),
};

const WebhooksContainer = (props) => {
	const qs = getQueryStrings(props.location ? props.location.search : '');

	const { isDrawer, webhooks, getWebhooks, searchWebhooks, deleteWebhook, createWebhook, updateWebhook, loaders } = props;
	const [showDrawer, setShowDrawer] = React.useState(false);
	const [filters, setFilters] = useState(getWebhookFiltersFromQuery(qs));
	const [orderBy, setOrderBy] = React.useState(qs.orderBy ? qs.orderBy : WebhookOrderByTypes[WebhookOrderByTypes.length - 1]);
	const [editWebhook, setEditWebhook] = React.useState(undefined);
	const [pagination, setPagination] = React.useState(props.params?.page ? parseInt(props.params?.page, 10) : 1);
	const navigate = useNavigate();

	const debouncedSearch = useDebounce(filters.searchTerm, 500);
	const hasFilters = Boolean(filters.searchTerm?.length > 2 || filters.authType || filters.inUse);

	const onClose = () => {
		setShowDrawer(false);
		setEditWebhook(undefined);
	};

	const getWebhooksByFilters = (isInitialLoad = false) => {
		if ((debouncedSearch && debouncedSearch.length) || hasFilters) {
			if (debouncedSearch.length >= SearchTermMinLength || hasFilters) {
				searchWebhooks(filters, orderBy, pagination);

				if (!isDrawer) {
					const newSearch = {};

					if (filters.searchTerm && debouncedSearch.length >= SearchTermMinLength) newSearch.name = filters.searchTerm;
					if (filters.authType) newSearch.authType = filters.authType;
					if (filters.inUse) newSearch.inUse = filters.inUse;

					navigate(getRoute.root('forms', 'webhook', pagination) + `?${getQuerySearch(newSearch)}`);
				}
			}
		} else {
			const successCb = () => !isDrawer && navigate(getRoute.root('forms', 'webhook', pagination));
			getWebhooks(pagination, orderBy, 10, successCb, isInitialLoad);
		}
	};

	const onClickDelete = (e, id) => {
		e.stopPropagation();
		deleteWebhook(id, getWebhooksByFilters);
	};

	const onChangePagination = (page) => {
		setPagination(page);
	};

	const onChangeSearch = (value) => {
		onChangePagination(1);
		setFilters({ ...filters, searchTerm: value });
	};

	const onFilterChange = (type, value) => {
		onChangePagination(1);
		setFilters({ ...filters, [type]: value });
	};

	React.useEffect(
		() => getWebhooksByFilters(webhooks.totalItems <= 0),
		[debouncedSearch, pagination, orderBy, filters.authType, filters.inUse],
	);

	return (
		<Flipper spring="noWobble" flipKey={webhooks.items.map((item) => item.Id).join('-')}>
			<div>
				<Box px={isDrawer ? 0 : [2.5, 2.5, 8, 8]} maxW={2000} mx="auto">
					<Box display={['none', 'none', 'block']}>
						<Box display="flex" flexWrap="wrap" alignItems="center" pt={6} m="0 auto" maxW={2000}>
							<Heading as="h1">Webhooks</Heading>
							<Button ml="auto" leftIcon={<Icon path={mdiPlus} />} onClick={() => setShowDrawer(true)}>
								Create webhook
							</Button>
						</Box>
						<Text variant="subtle" py={2.5} m="0 auto" maxW={2000}>
							Webhooks can be used to capture submitted inputs to your database. It can also be used to trigger business workflows you may
							have set upon form submission.
						</Text>
					</Box>
					<Box>
						<Box display="flex" h="20" alignItems="center" justifyContent={'start'}>
							{!webhooks.items.length && !hasFilters ? null : (
								<>
									<InputGroup maxW="sm" mr={1.5} flex={1}>
										<Input
											autoFocus
											placeholder="Search by name"
											data-testid="table-filter-input"
											value={filters.searchTerm}
											onChange={(e) => onChangeSearch(e.target.value)}
										/>
										<InputLeftElement pointerEvents="none">
											<Icon path={mdiMagnify} />
										</InputLeftElement>
									</InputGroup>

									<Select
										placeholder="Filter by auth type"
										isClearable
										chakraStyles={SelectStyles}
										options={WebhookOptions}
										onChange={(data) => onFilterChange('authType', data?.value ?? null)}
										value={filters.authType ? WebhookOptions.find((opt) => opt.value === filters.authType) : null}
										useBasicStyles
										selectedOptionStyle="check"
									/>

									<Select
										placeholder="Filter by in use"
										isClearable
										chakraStyles={SelectStyles}
										options={InUseOptions}
										onChange={(data) => onFilterChange('inUse', data?.value ?? null)}
										value={filters.inUse ? InUseOptions.find((opt) => opt.value === filters.inUse) : null}
										useBasicStyles
										selectedOptionStyle="check"
									/>
								</>
							)}
						</Box>
						<WebhookList
							order={orderBy}
							webhooks={webhooks.items}
							deleteWebhook={onClickDelete}
							editWebhook={(webhook) => setEditWebhook(webhook)}
							changeOrder={setOrderBy}
							isDrawer={isDrawer}
							loaders={loaders}
						/>
						{!loaders.webhooks && !webhooks.items.length && debouncedSearch.length < SearchTermMinLength && !hasFilters ? (
							<Flipped flipId="webhooks-empty" {...baseFlippedProps}>
								<div>
									<EmptyPage title="No webhooks yet" />
								</div>
							</Flipped>
						) : null}
						{!loaders.webhooks && !webhooks.items.length && (debouncedSearch.length >= SearchTermMinLength || hasFilters) ? (
							<Flipped flipId="no-filter-match" {...baseFlippedProps}>
								<div>
									<Text display="flex" justifyContent="center" my={8} fontSize="xl">{`No webhooks match your criteria`}</Text>
								</div>
							</Flipped>
						) : null}
						{webhooks.totalPages > 1 ? (
							<Flipped flipId="webhook-table-pagination" {...baseFlippedProps}>
								<TablePagination
									currentPage={pagination}
									totalPages={webhooks.totalPages}
									totalItems={webhooks.totalItems}
									showPageLabel
									onClick={(e, page) => onChangePagination(page)}
								/>
							</Flipped>
						) : null}
						<WebhookDrawer
							isOpen={showDrawer || editWebhook}
							editWebhook={editWebhook}
							onClose={onClose}
							getWebhooks={getWebhooksByFilters}
							createWebhook={createWebhook}
							updateWebhook={updateWebhook}
						/>
					</Box>
				</Box>
			</div>
		</Flipper>
	);
};

const WebhookList = ({ order, webhooks, editWebhook, deleteWebhook, changeOrder, loaders }) => {
	const webhooksChangeOrder = (type) => {
		if (type.includes('IsActiveAscending')) {
			changeOrder('IsActive');
		} else if (type.includes('IsActiveDescending')) {
			changeOrder('IsNotActive');
		} else {
			changeOrder(type);
		}
	};

	const getFormattedOrder = () => {
		switch (order) {
			case 'IsActive':
				return 'IsActiveAscending';
			case 'IsNotActive':
				return 'IsActiveDescdending';
			default:
				return order;
		}
	};

	const formattedHeaders = {
		Name: {
			label: 'Name',
			isSortable: true,
			width: '45%',
			restHeaderProps: { cursor: 'pointer', 'data-testid': 'webhook-name-header' },
		},
		AuthType: {
			label: 'Auth type',
			width: '30%',
		},
		IsActive: {
			label: 'Status',
			isSortable: true,
			width: '15%',
			restHeaderProps: { cursor: 'pointer' },
		},
		Action: {
			label: '',
			width: '4%',
		},
	};

	const formattedColumns = useDeepCompareMemo(() => {
		return webhooks.map((item) => {
			const { Settings } = item;

			return {
				Name: {
					item: (
						<Link onClick={() => editWebhook(item)} data-testid={`webhook-${item.Id}-name`}>
							{Settings.Name}
						</Link>
					),
					restProps: {},
				},
				AuthType: {
					item: <Text data-testid={`webhook-${item.Id}-auth-type`}>{WebhookTypesLabels[Settings.AuthType]}</Text>,
					restProps: {},
				},
				IsActive: {
					item: (
						<Badge data-testid={`webhook-${item.Id}-in-use`} colorScheme={item.HasAssignedEntities ? 'success' : 'neutral'}>
							{item.HasAssignedEntities ? 'In use' : 'Not in use'}
						</Badge>
					),
					restProps: {},
				},
				Action: {
					item: (
						<WebhookActions inUse={item.HasAssignedEntities} onDelete={(e) => deleteWebhook(e, item.Id)} onEdit={() => editWebhook(item)} />
					),
					restProps: {},
				},
			};
		});
	}, [webhooks]);

	if (!webhooks.length) {
		return null;
	}

	return (
		<BaseTable
			headers={formattedHeaders}
			data={formattedColumns}
			order={getFormattedOrder()}
			orderByTypes={WebhookOrderByTypes}
			defaultOrderBy={WebhooksDefaultSorting}
			orderCategories={orderCategories}
			changeOrder={webhooksChangeOrder}
			loaders={{ table: loaders.webhooks, tableDataUpdate: loaders.webhooksDataUpdate }}
			skeletonComponent={<WebhooksTableLoader />}
			data-testid="webhooks-table"
		/>
	);
};

const WebhookDrawer = (props) => {
	const initialData = {
		Name: '',
		AuthType: 0,
		EndPoint: '',
		ClientId: '',
		ClientSecret: '',
		Username: '',
		Password: '',
		Header: '',
		ApiKey: '',
	};

	const [webhook, setWebhook] = React.useState(initialData);

	const { isOpen, onClose, getWebhooks, editWebhook, createWebhook, updateWebhook } = props;

	React.useEffect(() => {
		if (editWebhook) {
			setWebhook({ ...webhook, ...editWebhook.Settings });
		}
	}, [editWebhook]);

	const onSubmit = () => {
		const properties = WebhookTypeProperties[WebhookTypes[webhook.AuthType]];

		let data = {};

		properties.forEach((item) => {
			data = { ...data, [item]: webhook[item] };
		});
		if (editWebhook) {
			updateWebhook(editWebhook.Id, data, getWebhooks);
		} else {
			createWebhook(data, getWebhooks);
		}
		onCloseDrawer();
	};

	const onCloseDrawer = () => {
		onClose();
		setWebhook(initialData);
	};

	const onKeyDown = (e) => {
		if (e.keyCode === 13) {
			onSubmit();
		}
	};

	const onChange = (data) => {
		setWebhook({ ...webhook, ...data });
	};

	const settingsByType = {
		0: () => {
			return (
				<>
					<FormControl mb={6}>
						<FormLabel>Client ID</FormLabel>
						<Input
							value={webhook.ClientId}
							onChange={(e) => onChange({ ClientId: e.target.value })}
							onKeyDown={onKeyDown}
							data-testid="webhook-input-clientid"
						/>
					</FormControl>
					<FormControl mb={6}>
						<FormLabel>Client secret</FormLabel>
						<Input
							value={webhook.ClientSecret}
							onChange={(e) => onChange({ ClientSecret: e.target.value })}
							onKeyDown={onKeyDown}
							data-testid="webhook-input-clientsecret"
						/>
					</FormControl>
					<FormControl mb={6}>
						<FormLabel>Authorization URL</FormLabel>
						<Input
							value={webhook.AuthorizeEndPoint}
							onChange={(e) => onChange({ AuthorizeEndPoint: e.target.value })}
							onKeyDown={onKeyDown}
							data-testid="webhook-input-authurl"
						/>
					</FormControl>
				</>
			);
		},
		1: () => {
			return (
				<>
					<FormControl mb={6}>
						<FormLabel>Username</FormLabel>
						<Input
							value={webhook.Username}
							onChange={(e) => onChange({ Username: e.target.value })}
							onKeyDown={onKeyDown}
							data-testid="webhook-input-username"
						/>
					</FormControl>
					<FormControl mb={6}>
						<FormLabel>Password</FormLabel>
						<Input
							value={webhook.Password}
							onChange={(e) => onChange({ Password: e.target.value })}
							onKeyDown={onKeyDown}
							data-testid="webhook-input-password"
						/>
					</FormControl>
				</>
			);
		},
		2: () => {
			return (
				<>
					<FormControl mb={6}>
						<FormLabel>Header</FormLabel>
						<Input
							value={webhook.Header}
							onChange={(e) => onChange({ Header: e.target.value })}
							onKeyDown={onKeyDown}
							data-testid="webhook-input-header"
						/>
					</FormControl>
					<FormControl mb={6}>
						<FormLabel>API key</FormLabel>
						<Input
							value={webhook.ApiKey}
							onChange={(e) => onChange({ ApiKey: e.target.value })}
							onKeyDown={onKeyDown}
							data-testid="webhook-input-apikey"
						/>
					</FormControl>
				</>
			);
		},
		3: () => null,
	};

	return (
		<Drawer isOpen={isOpen} onClose={onCloseDrawer}>
			<DrawerOverlay />

			<DrawerContent>
				<DrawerCloseButton />

				<DrawerHeader>{editWebhook?.Settings?.Name ?? 'Create webhook'}</DrawerHeader>

				<DrawerBody>
					<FormControl mb={6}>
						<FormLabel>Authentication type</FormLabel>

						<Select
							placeholder="Select an option"
							options={WebhookOptions}
							onChange={(data) => onChange({ AuthType: parseInt(data.idx, 10) })}
							value={typeof webhook.AuthType === 'number' ? WebhookOptions.find((opt) => opt.idx === webhook.AuthType) : null}
							useBasicStyles
							selectedOptionStyle="check"
						/>
					</FormControl>

					<FormControl mb={6}>
						<FormLabel>Name</FormLabel>
						<Input
							autoFocus
							value={webhook.Name}
							onChange={(e) => onChange({ Name: e.target.value })}
							onKeyDown={onKeyDown}
							data-testid="webhook-input-name"
						/>
					</FormControl>

					<FormControl mb={6}>
						<FormLabel>URL</FormLabel>
						<Input
							value={webhook.EndPoint}
							onChange={(e) => onChange({ EndPoint: e.target.value })}
							onKeyDown={onKeyDown}
							data-testid="webhook-input-url"
						/>
					</FormControl>
					{settingsByType[webhook.AuthType]()}
				</DrawerBody>

				<DrawerFooter gap="2">
					<Button variant="ghost" onClick={onClose}>
						Cancel
					</Button>
					<Button onClick={onSubmit}>Save</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
};

export const WebhooksDrawer = (props) => {
	const { onClose, isOpen, webhooksProps } = props;
	return (
		<Drawer isOpen={isOpen} onClose={onClose} size="full">
			<DrawerOverlay />

			<DrawerContent>
				<DrawerCloseButton />

				<DrawerBody>{isOpen && <WebhooksConnector {...webhooksProps} isDrawer />}</DrawerBody>

				<DrawerFooter>
					<Button onClick={onClose} data-testid="close-webhooks-drawer">
						Close
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
};

export const WebhookActions = (props) => (
	<MoreMenu
		dataTestId="webhook-more-menu"
		actions={[
			{ label: 'Edit', action: props.onEdit, testId: 'webhook-action-edit' },
			{
				label: 'Delete',
				action: props.onDelete,
				noRender: props.inUse,
				testId: 'webhook-action-delete',
			},
		]}
	/>
);

export const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = React.useState(value);

	React.useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value]);

	return debouncedValue;
};

export default WebhooksConnector;
