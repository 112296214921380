import { memo, MouseEvent, useCallback } from 'react';

import { Box, IconButton, Menu, MenuButton, MenuItem, MenuList, MenuListProps, MenuProps, Tooltip } from '@chakra-ui/react';
import { mdiDotsHorizontal } from '@mdi/js';

import { TestId } from 'test/types';

import { Icon } from './Icon';

export type MenuActionItem = {
	action: (e: MouseEvent<HTMLElement>) => void;
	label: string;
	noRender?: boolean;
	testId?: string;
};

interface Props extends TestId {
	handleMenu?: (isOpen: boolean) => void;
	actions: MenuActionItem[];
	disabled?: boolean;
	menuProps?: Partial<MenuProps>;
	menuListProps?: MenuListProps;
}

const MoreMenu = ({ actions, dataTestId = 'actions-menu', disabled, menuProps, menuListProps }: Props) => {
	const handleAction = useCallback((e: MouseEvent<HTMLElement>, menuAction: (e: MouseEvent<HTMLElement>) => void) => {
		e?.stopPropagation();
		menuAction(e);
	}, []);

	const onBtnClick = useCallback((e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		e.stopPropagation();
	}, []);

	return (
		<Box data-testid={dataTestId}>
			<Menu isLazy data-testid={dataTestId} preventOverflow {...menuProps}>
				<Tooltip label="Actions">
					<MenuButton
						as={IconButton}
						variant="ghost"
						size="sm"
						aria-label="Actions"
						icon={<Icon path={mdiDotsHorizontal} />}
						isDisabled={disabled}
						onClick={onBtnClick}
						data-testid={dataTestId ? `${dataTestId}-btn` : undefined}
					/>
				</Tooltip>

				<MenuList {...menuListProps}>
					{actions.map((actionItem) => {
						if (actionItem.noRender) {
							return null;
						}

						return (
							<MenuItem
								key={`${actionItem.label.toLowerCase()}-menu_item`}
								data-testid={actionItem.testId || `${actionItem.label.toLowerCase()}-menu_item`}
								onClick={(e) => handleAction(e, actionItem.action)}
							>
								{actionItem.label}
							</MenuItem>
						);
					})}
				</MenuList>
			</Menu>
		</Box>
	);
};

export default memo(MoreMenu);
