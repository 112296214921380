import React from 'react';

import { Box, FormControl, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react';

import { formatTestId } from '@/util/helper';

import { Tooltip } from '@/components/gui/content/helper/Tooltip.react';

import { settingsLabelStyle } from './Settings.styles';

const TextInput = ({
	disabled = false,
	maxChars = 2083,
	customStyle = { customRoot: null, customLabel: null, customInput: null },
	label,
	tooltip,
	type,
	inputType = 'text',
	text,
	validateURL,
	ariaLabel,
	placeholder,
	renderColor,
	disableEmoji,
	onChange,
}) => {
	const { customRoot, customLabel, customInput } = customStyle;

	const onInput = (event) => {
		if (disableEmoji) {
			event.target.value = event.target.value.replace(
				/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
				'',
			);
		}
	};

	const fixURL = (value) => {
		let result = value;

		if (validateURL) {
			let val = (typeof value === 'string' && value) || '';
			let protocol = val.substring(0, 8);

			result = val;

			if (!(protocol === 'https://' || protocol.substring(0, 7) === 'http://')) {
				result = 'http://' + val.substring(8);
			}

			if (val.length <= 8 && val !== 'https://' && val.substring(0, 7) !== 'http://') {
				result = 'http://';
			}
		}

		return result;
	};

	const handleChange = (event) => {
		let value = fixURL(event.target.value);

		if (onChange && value.length < maxChars) {
			onChange({ event, type, value });
		}
	};

	return (
		<FormControl
			id={`${type}-text-input-setting`}
			data-type={type}
			className="text-input-setting-container"
			isDisabled={disabled}
			mb={5}
			{...customRoot}
		>
			{label && (
				<FormLabel as="label" {...settingsLabelStyle} {...customLabel}>
					<Box display="inline-flex" pos="relative">
						{label}
						{tooltip && <Tooltip>{tooltip}</Tooltip>}
					</Box>
				</FormLabel>
			)}

			<InputGroup>
				<Input
					type={inputType}
					value={text}
					spellCheck={!validateURL}
					aria-label={ariaLabel || label || undefined}
					placeholder={placeholder || undefined}
					data-testid={`input-${formatTestId(label || type)}`}
					{...customInput}
					onInput={onInput}
					onChange={handleChange}
				/>
				{renderColor && <InputRightElement>{renderColor()}</InputRightElement>}
			</InputGroup>
		</FormControl>
	);
};

export default TextInput;
