import React, { useEffect, useState } from 'react';

import { Box, Button, Image, ModalBody, ModalFooter, Text } from '@chakra-ui/react';

import useModalStore from '@/stores/ModalStore';

import { Modals, ModalTypes } from '@/util/resources';

import Modal from '@/components/gui/shared/modals/Modal.react';
import CheckBoxSetting from '@/components/gui/shared/settings/CheckBoxSetting.react';

const DontAskAgain = ({ onToggleCheckbox, dont_ask_again }) => (
	<CheckBoxSetting
		styleProps={{ mr: 'auto', mb: 0 }}
		onClick={onToggleCheckbox}
		label="Don’t show this again"
		checked={dont_ask_again}
		type="dont_ask_again"
	/>
);

const DontAsktModal = ({ show, data }) => {
	const [dontAskAgain, setDontAskAgain] = useState(data.dont_ask_again[data.type]);

	useEffect(() => {
		setDontAskAgain(data.dont_ask_again[data.type]);
	}, [data]);

	const close = () => {
		useModalStore.getState().closeDontAskModal({ [data.type]: false });
		if (data.onCancel) data.onCancel(dontAskAgain);
		setDontAskAgain(false);
	};

	const ok = () => {
		if (data.onOk) data.onOk(dontAskAgain);
		useModalStore.getState().closeDontAskModal({ [data.type]: dontAskAgain });
	};

	const onToggleCheckbox = () => {
		setDontAskAgain((prevState) => !prevState);
	};

	const list = data.list?.map((item, i) => (
		<Text mb={1.5} as="li" key={i}>
			- {item}
		</Text>
	));

	return (
		<Modal display={show} modal={data.modal || Modals.CONFIRM} modalType={ModalTypes.DONT_ASK} closeCallback={close}>
			<ModalBody>
				{data.message && <Text>{data.message}</Text>}

				<Box display="flex">
					<Box flex={1}>
						{data.message2 && <Text>{data.message2}</Text>}
						{list && list.length ? <Box>{list}</Box> : null}
					</Box>

					{data.image && (
						<Box flex={1}>
							<Image src={data.image} />
						</Box>
					)}
				</Box>
			</ModalBody>
			<ModalFooter>
				<DontAskAgain dont_ask_again={dontAskAgain} onToggleCheckbox={onToggleCheckbox} />
				{!data.ignoreCancelBtn && (
					<Button variant="outline" colorScheme="neutral" onClick={close}>
						{data.cancelLabel || 'Cancel'}
					</Button>
				)}
				<Button colorScheme={data.modal === Modals.ERROR ? 'danger' : 'primary'} ml={5} onClick={ok}>
					{data.okLabel || 'Yes'}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DontAsktModal;
