import React from 'react';

import useContentStore from '@/stores/ContentStore';

import { SettingsBox } from '@/components/gui/settingsContext/SettingsContext';
import ColorPickerSetting from '@/components/gui/shared/settings/ColorPickerSetting.react';
import NumberSetting from '@/components/gui/shared/settings/NumberSetting.react';

import SharedImageSettings from './SharedImageSettings.react';

export const ImageSettingComponents = React.memo((props) => {
	const onChange = React.useCallback(
		(data) => {
			if (props.onChange) {
				return props.onChange(data);
			}

			const settings = {
				[data.type]: data.value,
			};

			useContentStore.getState().saveElementSettings({
				address: props.address,
				settings,
			});
		},
		[props.address],
	);

	return (
		<SettingsBox>
			<NumberSetting
				type="imageBorderWidth"
				onChange={onChange}
				text={props.imageBorderWidth}
				label="Border width"
				ariaLabel="Border width in pixels"
			/>
			<ColorPickerSetting
				type="imageBorderColor"
				onChange={onChange}
				selectedColor={props.imageBorderColor || 'transparent'}
				label="Border color"
			/>
			<SharedImageSettings
				linkSetting={!props.isMobileView}
				recropBtn={true}
				alignSetting={true}
				paddingSetting={true}
				fileUploadSetting={!props.isMobileView}
				originalSizeSetting={true}
				dimensionSettings={true}
				borderRadiusSetting={true}
				altSetting={!props.isMobileView}
				settingType="element"
				filterImmunity
				{...props}
				imageBorderWidth={null}
				imageBorderColor={null}
			/>
		</SettingsBox>
	);
});

export default ImageSettingComponents;
