import React from 'react';

import { Box, Button as ChakraButton, IconButton, Tooltip } from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage, NativeTypes } from 'react-dnd-html5-backend';
import { Flipped } from 'react-flip-toolkit';
import { shallow } from 'zustand/shallow';

import useContentStore from '@/stores/ContentStore';
import useContentViewStore from '@/stores/ContentViewStore';

import { findRow, simpleObjectEquals } from '@/util/helper';
import itemTypes from '@/util/itemTypes';
import { GridOptions, GridTypes, SlotTypes, contentViewMenuItemConstants as ViewTypes } from '@/util/resources';
import { AllComponentTypes, getDesignerAlignment, getPadding } from '@/util/shared';

import { Icon } from '@/components/gui/shared/Icon';

import AddElementsBox from './helper/AddElementsBox.react';
import { _dynamicElements, collect, compareAddress, containerSpec, slotEmpty, sourceCollect, sourceSpec } from './SlotHelpers';

export class Slot extends React.PureComponent {
	props;
	state;

	hoverOver;
	hoverSide;

	constructor(props) {
		super(props);

		this.hoverOver = null;
		this.hoverSide = null;

		this.state = {
			progress: 0,
			dropzoneBorder: 'none',
			clickedPlus: false,
		};
	}

	componentDidMount() {
		this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true });
	}

	render() {
		const { type, connectDropTarget, activeElement, mailingLists, padding, multiPadding, isMobileView } = this.props;

		// const boxShadow = isOver && 'inset 0 0 5px black';
		let components = this.props.components.map((item, i) => {
			if (!item.type) {
				console.log(item);
			}
			if (!item.type) throw new Error('missing component type');
			let DynamicElement = _dynamicElements[item.type];
			let componentAddress = { ...this.props.address, component: i };

			let active = simpleObjectEquals(activeElement, componentAddress);

			const deductPadding = multiPadding && multiPadding.allow ? multiPadding.paddingRight + multiPadding.paddingLeft : padding * 2;
			const slotWidth = this.props.slotWidth - deductPadding - this.props.borderWidth * 2 || 0;

			const componentProps = isMobileView ? { ...item, ...item.mobileProps } : item;

			return (
				<DynamicElement
					{...componentProps}
					showResolvedComments={this.props.showResolvedComments}
					teamMembers={this.props.teamMembers}
					comments={this.props.comments}
					lastPage={this.props.lastPage}
					pageIndex={this.props.pageIndex}
					key={item.id || i}
					index={i}
					rowId={this.props.address.rowId}
					slotIndex={this.props.address.slot}
					slot_type={type}
					touch_screen={this.props.touch_screen}
					activeElement={active}
					isMobileView={isMobileView}
					isScriptMode={this.props.isScriptMode}
					general_styles={this.props.general_styles}
					mailingLists={mailingLists}
					slotWidth={slotWidth}
					forceUpdate={this.props.forceUpdate}
					responsive={this.props.responsive}
				/>
			);
		});

		let verticalAlign = this.props.vertical_align || 'top';

		if (this.props.grid === GridOptions.DISABLED && slotEmpty(this.props)) {
			verticalAlign = 'middle';
		}

		let minHeight = slotEmpty(this.props) ? '100px' : 'auto';
		const border = this.props.borderWidth ? `${this.props.borderWidth}px solid ${this.props.borderColor}` : '';

		const justifyContent = components.length && getDesignerAlignment(this.props.vertical_align);

		const slotContent = (
			<div
				style={{
					display: 'flex',
					flex: 1,
					flexDirection: 'column',
					height: '100%',
					minHeight: this.props.minHeight,
					width: '100%',
					...getPadding(this.props, true),
				}}
				data-testid={'slot-content'}
			>
				<AddComponentGridBtn {...this.props} gridType={GridTypes.TOP} onClickAddComponent={this.onClickAddComponent} />
				<Box
					minH={minHeight}
					position="relative"
					justifyContent={justifyContent}
					display="flex"
					flex={1}
					flexDirection="column"
					h="100%"
					border={border}
					bgColor={this.props.contentBgColor || 'transparent'}
					borderRadius={this.props.borderRadius}
					// ref="slot"
				>
					{this.state.progress !== 0 && (
						<Box
							className="progress-bar"
							bgColor="primary"
							zIndex={12}
							position="absolute"
							top={0}
							h={'3px'}
							w={`${this.state.progress}%`}
							style={{
								zIndex: 12,
								position: 'absolute',
								top: 0,
								height: '3px',
								backgroundColor: '#2d2d2d',
								transition: 'width .5s',
								display: this.state.progress === 0 ? 'none' : 'block',
								width: `${this.state.progress}%`,
							}}
						/>
					)}
					{!this.props.isMobileView ? (
						connectDropTarget(
							<div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent }}>
								{!this.props.isMobileView && (
									<AddElementsEmpty {...this.props} onPlusClick={this.onPlusClick} clickedPlus={this.state.clickedPlus} />
								)}
								<Flipped inverseFlipId={this.props.address.rowId}>
									<div>{components}</div>
								</Flipped>
							</div>,
						)
					) : (
						<div>
							<Flipped inverseFlipId={this.props.address.rowId}>
								<div>{components}</div>
							</Flipped>
						</div>
					)}
				</Box>
				<AddComponentGridBtn {...this.props} gridType={GridTypes.BOTTOM} onClickAddComponent={this.onClickAddComponent} />
			</div>
		);

		return this.renderSlotByType(slotContent, components.length, verticalAlign);
	}

	renderSlotByType = (slotContent, componentsLength) => {
		const { connectDropTarget, isDragging, isOver, activeElement } = this.props;
		const boxShadow = !this.props.isMobileView && isOver ? 'inset 0 0 5px black' : '';
		const hiddenSlot = this.props.responsive && this.props.isMobileView && this.props.hidden ? { opacity: 0.3 } : {};
		const mobileStyles = this.props.isMobileView && componentsLength === 0 && this.props.responsive ? { display: 'none' } : {};

		const flex = this.props.type === SlotTypes.TWO_THIRDS ? 2 : 1;
		const opacity = isDragging ? 0.5 : 1;

		const shadowyElement =
			this.props.isMobileView && isOver ? (
				<Box boxShadow={'inset 0px 0px 15px 5px rgba(0,0,0,0.5)'} position="absolute" top={0} left={0} right={0} bottom={0} zIndex={1} />
			) : null;

		const slot = (
			<div
				// outlineWidth={1}
				// outlineStyle="solid"
				// outlineColor="chakra-border-color"
				style={{
					outline: '1px solid #efefef',
					backgroundColor: this.props.background_color || 'transparent',
					position: 'relative',
					maxWidth: this.props.slotWidth,
					display: 'flex',
					boxShadow,
					opacity,
					flex,
					justifyContent: 'center',
					...mobileStyles,
					...hiddenSlot,
				}}
				className="slot-ui"
			>
				{shadowyElement}
				{slotContent}
			</div>
		);

		const finalSlot = this.props.isMobileView ? connectDropTarget(slot) : slot;

		let shouldDrag = true;

		if (activeElement) {
			const content = useContentStore.getState().content;
			const component = findRow(content.rows, activeElement.rowId).row.slots[activeElement.slot].components[activeElement.component];
			if (component && component.type === AllComponentTypes.text) {
				shouldDrag = false;
			}
		}

		return shouldDrag ? this.props.connectDragSource(finalSlot) : finalSlot;
	};

	onPlusClick = () => {
		this.setState({ clickedPlus: !this.state.clickedPlus });

		if (!this.state.clickedPlus) {
			setTimeout(() => {
				document.getElementById(`${JSON.stringify(this.props.address)}`).focus();
			}, 0);
		}
	};

	onClickAddComponent = (location) => {
		if (location && this.props.address) {
			useContentStore.getState().activateAddElementBoxHelper({
				address: this.props.address,
				location,
			});
		}
	};
}

const AddComponentGridBtn = (props) => {
	let addComponentButton;
	let opositeGridType = props.gridType === GridTypes.BOTTOM ? GridTypes.TOP : GridTypes.BOTTOM;

	let borderTop = props.gridType === GridTypes.BOTTOM;
	let borderBottom = props.gridType !== GridTypes.BOTTOM;
	let position = props.gridType === GridTypes.BOTTOM ? props.components.length : 0;

	if (
		!simpleObjectEquals(props.active_addElement.address, props.address) ||
		(simpleObjectEquals(props.active_addElement.address, props.address) && props.active_addElement.location === opositeGridType)
	) {
		addComponentButton = (
			<Box
				py={5}
				borderStyle="dashed"
				borderColor="purple.400"
				borderWidth={3}
				display="flex"
				alignItems="center"
				justifyContent="center"
				className={`add-component-grid add-component-${props.gridType.toLowerCase()}`}
				onClick={() => props.onClickAddComponent(props.gridType)}
			>
				<ChakraButton onClick={() => props.onClickAddComponent(props.gridType)} bgColor="purple.400">
					{/*<ButtonIcon children="add" mr={5} />*/}
					<Box boxAs="span" fontSize="xs" fontWeight="semibold" ml="1" verticalAlign="baseline">
						Add item
					</Box>
				</ChakraButton>
			</Box>
		);
	}

	let addComponentBox = simpleObjectEquals(props.active_addElement.address, props.address) &&
		props.active_addElement.location === props.gridType && (
			<AddElementsBox {...props} borderTop={borderTop} borderBottom={borderBottom} position={position} />
		);

	if (props.grid === GridOptions.ENABLED && !slotEmpty(props)) {
		return (
			<React.Fragment>
				{addComponentButton}
				{addComponentBox}
			</React.Fragment>
		);
	}

	return <noscript />;
};

const AddElementsEmpty = (props) => {
	if (props.grid === GridOptions.ENABLED && slotEmpty(props)) {
		return <AddElementsBox {...props} position={0} />;
	}

	if (slotEmpty(props)) {
		return (
			<div>
				{!props.clickedPlus && (
					<Box
						position="absolute"
						left={0}
						right={0}
						top={0}
						bottom={0}
						cursor="pointer"
						onClick={props.onPlusClick}
						alignItems="center"
						justifyContent="center"
						data-testid="add-new-element-plus"
						display="flex"
					>
						<Tooltip label="Add item">
							<IconButton size="lg" aria-label="Add item" variant="ghost" icon={<Icon path={mdiPlus} size="6" />} />
						</Tooltip>
					</Box>
				)}
				{props.clickedPlus && (
					<Box tabIndex="2" onBlur={props.onPlusClick} onClick={props.onPlusClick} id={JSON.stringify(props.address)}>
						<AddElementsBox {...props} position={0} />
					</Box>
				)}
			</div>
		);
	}

	return <noscript />;
};

export const SlotDnD = DragSource(
	itemTypes.SLOT,
	sourceSpec,
	sourceCollect,
)(
	DropTarget(
		(props) => {
			return props.isMobileView ? itemTypes.SLOT : [itemTypes.ELEMENT, NativeTypes.FILE];
		},
		containerSpec,
		collect,
	)(Slot),
);

const SlotComp = (props) => <SlotDnD {...props} />;

const SlotWrapper = (props) => {
	const address = React.useMemo(() => {
		return { rowId: props.rowId, slot: props.index };
	}, [props.index, props.rowId]);

	const isMobileView = useContentViewStore((state) => state.currentView === ViewTypes.MOBILE_VIEW);

	const slotProps = useContentStore((state) => {
		const slot = findRow(state.content.rows, props.rowId).row.slots[props.index];

		if (isMobileView) {
			return { ...slot, ...slot.mobileProps };
		}

		return slot;
	});

	const activeElement = useContentStore((state) => {
		const clicked_element = state.helper.clicked_element || {};
		let activeOrNullElement = null;
		const activeElement = (simpleObjectEquals(clicked_element.rowId, props.rowId) && clicked_element) || null;

		if (activeElement && compareAddress(activeElement, { rowId: props.rowId, slot: props.index })) {
			activeOrNullElement = activeElement;
		} else {
			activeOrNullElement = null;
		}

		return activeOrNullElement;
	}, shallow);

	return <SlotComp {...props} {...slotProps} address={address} activeElement={activeElement} />;
};

SlotWrapper.displayName = 'SlotWrapper';

export default React.memo(SlotWrapper);
