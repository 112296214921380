import { useCallback } from 'react';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, SimpleGrid } from '@chakra-ui/react';

import useStylingStore from '@/stores/StylingStore';
import useTextEditorStore from '@/stores/TextEditorStore';

import { compareFontLabels } from '@/util/helper';
import { FontFamilyOptions, FormLabelPositions } from '@/util/resources';

import { AlignOptions, ButtonSettingsFields, ButtonWidthOptions } from '@/components/gui/content/settings/Button-settings.react';
import { DecorationSettings, SharedInputSettingsFields } from '@/components/gui/content/settings/SharedInput-settings.react';
import { SocialFollowSettingsFields } from '@/components/gui/content/settings/SocialFollow-settings.react';
import { SpacerSettingsFields } from '@/components/gui/content/settings/Spacer-settings.react';
import AlignmentSetting from '@/components/gui/shared/settings/AlignmentSetting.react';
import CheckBoxSetting from '@/components/gui/shared/settings/CheckBoxSetting.react';
import ColorPickerSetting from '@/components/gui/shared/settings/ColorPickerSetting.react';
import DropDownSetting from '@/components/gui/shared/settings/DropDownSetting.react';
import NumberSetting from '@/components/gui/shared/settings/NumberSetting.react';
import PaddingSetting from '@/components/gui/shared/settings/PaddingSetting.react';
import ShadowsSettings from '@/components/gui/shared/settings/ShadowsSettings.react';

export const StylingSettings = (props) => {
	const { changeStyle, styles } = useStylingStore((state) => {
		return {
			changeStyle: state.changeStyle,
			styles: state.styles.settings,
		};
	});

	const onChange = (type, data) => {
		let settings = {};

		if (Array.isArray(data)) {
			data.forEach((setting) => {
				settings = {
					...settings,
					[setting.type]: setting.value,
				};
			});
		} else {
			settings = {
				[data.type]: data.value,
			};
		}

		changeStyle(type, settings);
	};

	const onDimensionChange = (type, data) => {
		const input = {
			...styles[type].customSize,
			[data.type]: data.value,
		};

		changeStyle(type, { customSize: input });
	};

	const getDecorationSettings = (type, data) => {
		let settings = {};

		if (data.type === 'italics') {
			if (data.fontType) {
				return (
					onChange &&
					onChange(type, {
						type: `${data.fontType}FontStyle`,
						value: (data.value && 'italic') || 'normal',
					})
				);
			} else {
				settings.fontStyle = (data.value && 'italic') || 'normal';
			}
		} else if (data.type === 'bold') {
			if (data.fontType) {
				return (
					onChange &&
					onChange(type, {
						type: `${data.fontType}FontWeight`,
						value: (data.value && 'bold') || 'normal',
					})
				);
			} else {
				settings.fontWeight = (data.value && 'bold') || 'normal';
			}
		} else if (data.type === 'underline') {
			if (data.fontType) {
				return (
					onChange &&
					onChange(type, {
						type: `${data.fontType}TextDecoration`,
						value: (data.value && 'underline') || 'none',
					})
				);
			} else {
				settings.textDecoration = (data.value && 'underline') || 'none';
			}
		}

		return settings;
	};

	const getButtonDecorationSettings = (type, data) => {
		let settings = {};

		if (data.type === 'italics') {
			settings.fontStyle = (data.value && 'italic') || 'normal';
		} else if (data.type === 'bold') {
			settings.fontWeight = (data.value && 'bold') || 'normal';
		} else if (data.type === 'underline') {
			settings.textDecoration = (data.value && 'underline') || 'none';
		}

		changeStyle(type, settings);
	};

	const onLabelPositionChange = (type, data) => {
		let settings = {};
		if (data.value === 'column' && props.labelWidth > 40) {
			settings = {
				[data.type]: data.value,
				[SharedInputSettingsFields.labelWidth]: 25,
			};
		} else if (data.value === 'row' && props.labelWidth < 120) {
			settings = {
				[data.type]: data.value,
				[SharedInputSettingsFields.labelWidth]: 120,
			};
		}

		changeStyle(type, settings);
	};

	return (
		<Accordion allowToggle>
			<AccordionItem>
				<AccordionButton>
					Field
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<FieldStyles {...styles.field} settingType="field" onChange={onChange} getDecorationSettings={getDecorationSettings} />
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Checkbox
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<FieldStyles {...styles.checkbox} settingType="checkbox" onChange={onChange} getDecorationSettings={getDecorationSettings} />
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Radio
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<FieldStyles {...styles.radio} settingType="radio" onChange={onChange} getDecorationSettings={getDecorationSettings} />
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Label
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<LabelStyles
						{...styles.field}
						settingType="field"
						onChange={onChange}
						getDecorationSettings={getDecorationSettings}
						onLabelPositionChange={onLabelPositionChange}
					/>
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Placeholder
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<PlaceholderStyles {...styles.field} settingType="field" onChange={onChange} getDecorationSettings={getDecorationSettings} />
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Help text
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<HelpTextStyles {...styles.field} settingType="field" onChange={onChange} getDecorationSettings={getDecorationSettings} />
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Recaptcha
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<RecaptchaStyles {...styles.recaptcha} settingType="recaptcha" onChange={onChange} />
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Button
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<ButtonStyles
						{...styles.submitButton}
						settingType="submitButton"
						onChange={onChange}
						onDimensionChange={onDimensionChange}
						getDecorationSettings={getButtonDecorationSettings}
					/>
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Back button
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<ButtonStyles
						{...styles.backButton}
						settingType="backButton"
						onChange={onChange}
						onDimensionChange={onDimensionChange}
						getDecorationSettings={getButtonDecorationSettings}
					/>
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Spacer
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<SpacerStyles {...styles.spacer} settingType="spacer" onChange={onChange} />
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Text
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<TextStyles {...styles.text} settingType="text" onChange={onChange} />
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Image
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<ImageStyles {...styles.image} settingType="image" onChange={onChange} />
				</AccordionPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionButton>
					Social media
					<AccordionIcon ml="auto" />
				</AccordionButton>
				<AccordionPanel motionProps={{ unmountOnExit: true }}>
					<SocialStyles {...styles.social} settingType="social" onChange={onChange} />
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);
};

const FieldStyles = (props) => {
	const allFontOptions = FontFamilyOptions.concat(useTextEditorStore.getState().custom_fonts).sort(compareFontLabels);

	const onChange = useCallback((data) => {
		props.onChange(props.settingType, data);
	}, []);

	const getDecorationSettings = useCallback((data) => {
		props.getDecorationSettings(props.settingType, data);
	}, []);

	const isField = props.settingType === 'field';

	return (
		<SimpleGrid columns={2} spacingX={5} spacingY={2}>
			<ColorPickerSetting
				type="background_color"
				onChange={onChange}
				selectedColor={props.background_color || 'transparent'}
				label="Background color"
			/>
			<ColorPickerSetting
				type={SharedInputSettingsFields.fieldBgColor}
				onChange={onChange}
				selectedColor={props.fieldBgColor}
				label="Field background color"
			/>
			<ColorPickerSetting
				type={SharedInputSettingsFields.fieldBorderColour}
				onChange={onChange}
				selectedColor={props.fieldBorderColour}
				label="Border color"
			/>
			<NumberSetting
				type={SharedInputSettingsFields.fieldBorderWidth}
				onChange={onChange}
				text={props.fieldBorderWidth}
				label="Border width"
				ariaLabel="Border width in pixels"
			/>
			<NumberSetting
				type={SharedInputSettingsFields.fieldBorderRadius}
				onChange={onChange}
				text={props.fieldBorderRadius}
				label="Border radius"
				ariaLabel="Field radius in pixels"
			/>
			<NumberSetting
				type={SharedInputSettingsFields.fieldPadding}
				onChange={onChange}
				text={props.fieldPadding}
				label="Inner padding"
				ariaLabel="Inner padding in pixels"
			/>
			<NumberSetting
				type={SharedInputSettingsFields.fieldFontSize}
				onChange={onChange}
				text={props.fieldFontSize}
				label="Field font size"
				ariaLabel="Font size in pixels"
				// onBlur={onFontSizeBlur}
			/>
			<ColorPickerSetting
				type={SharedInputSettingsFields.fieldFontColour}
				onChange={onChange}
				selectedColor={props.fieldFontColour}
				label="Font color"
			/>
			{isField && (
				<DropDownSetting
					type={SharedInputSettingsFields.fieldFont}
					onChange={onChange}
					selected={props.fieldFont}
					label="Field font"
					options={allFontOptions}
				/>
			)}
			{isField && (
				<DecorationSettings
					type="field"
					label="Field decorations"
					onDecorationsChange={getDecorationSettings}
					textDecoration={props.fieldTextDecoration}
					fontStyle={props.fieldFontStyle}
					fontWeight={props.fieldFontWeight}
				/>
			)}
			<ShadowsSettings
				selectedValue={
					props.box_shadow || {
						offsetx: 1,
						offsety: 1,
						blur: 10,
						spread: 1,
						color: '#000',
					}
				}
				onChange={onChange}
				label="Shadow settings"
			/>
		</SimpleGrid>
	);
};

const LabelStyles = (props) => {
	const allFontOptions = FontFamilyOptions.concat(useTextEditorStore.getState().custom_fonts).sort(compareFontLabels);

	const onChange = useCallback((data) => {
		props.onChange(props.settingType, data);
	}, []);

	const getDecorationSettings = useCallback((data) => {
		props.getDecorationSettings(props.settingType, data);
	}, []);

	const onLabelPositionChange = useCallback((data) => {
		props.onLabelPositionChange(props.settingType, data);
	}, []);

	return (
		<SimpleGrid columns={2} spacing={5}>
			<DropDownSetting
				type={SharedInputSettingsFields.labelFont}
				onChange={onChange}
				selected={props.labelFont}
				label="Label font"
				options={allFontOptions}
			/>
			<NumberSetting
				type={SharedInputSettingsFields.labelFontSize}
				onChange={onChange}
				text={props.labelFontSize}
				label="Label font size"
				ariaLabel="Font size in pixels"
				// onBlur={onFontSizeBlur}
			/>
			<ColorPickerSetting
				type={SharedInputSettingsFields.labelFontColour}
				onChange={onChange}
				selectedColor={props.labelFontColour}
				label="Label font color"
			/>
			<ColorPickerSetting
				type={SharedInputSettingsFields.asteriskColor}
				onChange={onChange}
				selectedColor={props.asteriskColor}
				label="Asterisk color"
			/>
			<DropDownSetting
				type={SharedInputSettingsFields.labelPosition}
				onChange={onLabelPositionChange}
				selected={props.labelPosition}
				label="Label position"
				options={FormLabelPositions}
			/>
			<NumberSetting
				type={SharedInputSettingsFields.labelWidth}
				onChange={onChange}
				text={props.labelWidth}
				label="Label width"
				ariaLabel="Label width in pixels"
			/>
			<DecorationSettings
				type="label"
				label="Label decorations"
				onDecorationsChange={getDecorationSettings}
				textDecoration={props.labelTextDecoration}
				fontStyle={props.labelFontStyle}
				fontWeight={props.labelFontWeight}
			/>
			<CheckBoxSetting onClick={onChange} label="Hide label" checked={props.labelHide} type={SharedInputSettingsFields.labelHide} />
		</SimpleGrid>
	);
};

const PlaceholderStyles = (props) => {
	const allFontOptions = FontFamilyOptions.concat(useTextEditorStore.getState().custom_fonts).sort(compareFontLabels);

	const onChange = useCallback((data) => {
		props.onChange(props.settingType, data);
	}, []);

	return (
		<SimpleGrid columns={2} spacing={5}>
			<DropDownSetting
				type={SharedInputSettingsFields.placeholderFont}
				onChange={onChange}
				selected={props.placeholderFont}
				label="Placeholder font"
				options={allFontOptions}
			/>
			<ColorPickerSetting
				type={SharedInputSettingsFields.placeholderFontColour}
				onChange={onChange}
				selectedColor={props.placeholderFontColour}
				label="Placeholder font color"
			/>
		</SimpleGrid>
	);
};

const HelpTextStyles = (props) => {
	const allFontOptions = FontFamilyOptions.concat(useTextEditorStore.getState().custom_fonts).sort(compareFontLabels);

	const onChange = useCallback((data) => {
		props.onChange(props.settingType, data);
	}, []);

	const getDecorationSettings = useCallback((data) => {
		props.getDecorationSettings(props.settingType, data);
	}, []);

	return (
		<SimpleGrid columns={2} spacing={5}>
			<DropDownSetting
				type={SharedInputSettingsFields.descriptionFontFamily}
				onChange={onChange}
				selected={props.descriptionFontFamily}
				label="Help text font"
				options={allFontOptions}
			/>
			<ColorPickerSetting
				type={SharedInputSettingsFields.descriptionFontColour}
				onChange={onChange}
				selectedColor={props.descriptionFontColour}
				label="Help text color"
			/>
			<NumberSetting
				type={SharedInputSettingsFields.descriptionFontSize}
				onChange={onChange}
				text={props.descriptionFontSize}
				label="Help text font size"
				ariaLabel="Font size in pixels"
			/>
			<NumberSetting
				type={SharedInputSettingsFields.descriptionSpacing}
				onChange={onChange}
				text={props.descriptionSpacing}
				label="Help text top spacing"
				ariaLabel="Spacing in pixels"
			/>
			<DecorationSettings
				type="description"
				label="Help text decoration"
				onDecorationsChange={getDecorationSettings}
				textDecoration={props.descriptionTextDecoration}
				fontStyle={props.descriptionFontStyle}
				fontWeight={props.descriptionFontWeight}
			/>
			<CheckBoxSetting
				onClick={onChange}
				label="Hide help text"
				checked={props.descriptionHide}
				type={SharedInputSettingsFields.descriptionHide}
			/>
		</SimpleGrid>
	);
};

const RecaptchaStyles = (props) => {
	const onChange = useCallback((data) => {
		props.onChange(props.settingType, data);
	}, []);

	return (
		<SimpleGrid columns={2} spacing={5}>
			<ColorPickerSetting
				type="background_color"
				onChange={onChange}
				selectedColor={props.background_color || 'transparent'}
				label="Background color"
			/>
			<DropDownSetting
				type="theme"
				onChange={onChange}
				selected={props.theme}
				label="Theme"
				options={[
					{
						value: 'light',
						label: 'Light',
					},
					{
						value: 'dark',
						label: 'Dark',
					},
				]}
			/>
			<PaddingSetting
				multiPadding={props.multiPadding}
				padding={props.padding}
				type="multiPadding"
				label="Padding"
				secondaryType="padding"
				onChange={onChange}
			/>
			<AlignmentSetting type="alignment" onChange={onChange} selected={props.alignment} options={AlignOptions} label="Alignment" />
			<ShadowsSettings
				selectedValue={
					props.box_shadow || {
						offsetx: 1,
						offsety: 1,
						blur: 10,
						spread: 1,
						color: '#000',
					}
				}
				onChange={onChange}
				label="Shadow settings"
			/>
		</SimpleGrid>
	);
};

const ButtonStyles = (props) => {
	const fontOptions = FontFamilyOptions.concat(useTextEditorStore.getState().custom_fonts)
		.filter((item) => {
			return !item.value.includes(':');
		})
		.sort(compareFontLabels);

	const onChange = useCallback((data) => {
		props.onChange(props.settingType, data);
	}, []);

	const onDimensionChange = useCallback((data) => {
		props.onDimensionChange(props.settingType, data);
	}, []);

	const getDecorationSettings = useCallback((data) => {
		props.getDecorationSettings(props.settingType, data);
	}, []);

	return (
		<SimpleGrid columns={2} spacing={5}>
			<ColorPickerSetting
				type="background_color"
				onChange={onChange}
				selectedColor={props.background_color || 'transparent'}
				label="Background color"
			/>
			<ColorPickerSetting
				type={ButtonSettingsFields.color}
				onChange={onChange}
				selectedColor={props.color || 'transparent'}
				label="Button color"
			/>
			<ColorPickerSetting
				type={ButtonSettingsFields.text_color}
				onChange={onChange}
				selectedColor={props.text_color || 'transparent'}
				label="Label color"
			/>
			<DropDownSetting
				type={ButtonSettingsFields.fontFamily}
				onChange={onChange}
				selected={props.fontFamily.replace(/'/g, '')}
				label="Font family"
				options={fontOptions}
			/>
			<NumberSetting
				type={ButtonSettingsFields.fontSize}
				inputType="number"
				onChange={onChange}
				text={props.fontSize}
				label="Font size"
				ariaLabel="Font size"
			/>
			<NumberSetting
				type={ButtonSettingsFields.borderWidth}
				onChange={onChange}
				text={props.borderWidth}
				max={10}
				label="Border width"
				ariaLabel="Number of pixels"
			/>
			<ColorPickerSetting
				type={ButtonSettingsFields.borderColor}
				onChange={onChange}
				selectedColor={props.borderColor || 'transparent'}
				label="Border color"
			/>
			<NumberSetting
				type={ButtonSettingsFields.borderRadius}
				onChange={onChange}
				text={props.borderRadius}
				label="Border radius"
				ariaLabel="Number of pixels"
			/>
			<NumberSetting
				type={ButtonSettingsFields.margin}
				onChange={onChange}
				text={props.margin}
				max={500}
				label="Margin"
				ariaLabel="Number of pixels"
			/>
			<DropDownSetting
				type={ButtonSettingsFields.width}
				onChange={onChange}
				selected={props.width}
				label="Button width"
				options={ButtonWidthOptions}
			/>
			<PaddingSetting
				multiPadding={props.multiPadding}
				padding={props.padding}
				type="multiPadding"
				label={'Label padding'}
				secondaryType={ButtonSettingsFields.padding}
				onChange={onChange}
			/>
			{props.width === 'custom' && (
				<NumberSetting
					type={ButtonSettingsFields.customSize.width}
					onChange={onDimensionChange}
					text={props.customSize && props.customSize.width}
					min={props.customSize && props.customSize.minWidth}
					max={200}
					validateOnBlur={true}
					label="Button width"
					ariaLabel="Button width"
				/>
			)}
			{props.width === 'custom' && (
				<NumberSetting
					type={ButtonSettingsFields.customSize.height}
					onChange={onDimensionChange}
					text={props.customSize && props.customSize.height}
					min={props.customSize && props.customSize.minHeight}
					max={1000}
					validateOnBlur={true}
					label="Button height"
					ariaLabel="Button height"
				/>
			)}

			<DecorationSettings
				type="button"
				label="Decorations"
				onDecorationsChange={getDecorationSettings}
				textDecoration={props.textDecoration}
				fontStyle={props.fontStyle}
				fontWeight={props.fontWeight}
			/>
			{/* <AlignmentSetting
						type={ButtonSettingsFields.alignment}
						onChange={onChange}
						selected={props.alignment}
						options={AlignOptions}
						label="Alignment"
					/> */}
			<ShadowsSettings
				selectedValue={
					props.box_shadow || {
						offsetx: 1,
						offsety: 1,
						blur: 10,
						spread: 1,
						color: '#000',
					}
				}
				onChange={onChange}
				label="Shadow settings"
			/>
		</SimpleGrid>
	);
};

const SpacerStyles = (props) => {
	const onChange = useCallback((data) => {
		props.onChange(props.settingType, data);
	}, []);

	return (
		<SimpleGrid columns={2} spacing={5}>
			<ColorPickerSetting
				type="background_color"
				onChange={onChange}
				selectedColor={props.background_color || 'transparent'}
				label="Background color"
			/>
			<NumberSetting
				type={SpacerSettingsFields.height}
				inputType="number"
				onChange={onChange}
				text={props.height}
				label="Height"
				ariaLabel="Set height"
			/>
			<NumberSetting
				type={SpacerSettingsFields.borderWidth}
				onChange={onChange}
				text={props.borderWidth}
				label="Border width"
				ariaLabel="Border width in pixels"
			/>
			<ColorPickerSetting
				type={SpacerSettingsFields.borderColor}
				onChange={onChange}
				selectedColor={props.borderColor || 'transparent'}
				label="Border color"
			/>
			<NumberSetting
				type={SpacerSettingsFields.borderRadius}
				onChange={onChange}
				text={props.borderRadius}
				label="Border radius"
				ariaLabel="Border radius in pixels"
			/>
			<ShadowsSettings
				selectedValue={
					props.box_shadow || {
						offsetx: 1,
						offsety: 1,
						blur: 10,
						spread: 1,
						color: '#000',
					}
				}
				onChange={onChange}
				label="Shadow settings"
			/>
		</SimpleGrid>
	);
};

const TextStyles = (props) => {
	const onChange = useCallback((data) => {
		props.onChange(props.settingType, data);
	}, []);

	return (
		<SimpleGrid columns={2} spacing={5}>
			<ColorPickerSetting
				type="background_color"
				onChange={onChange}
				selectedColor={props.background_color || 'transparent'}
				label="Background color"
			/>
			<PaddingSetting
				multiPadding={props.multiPadding}
				padding={props.padding}
				type="multiPadding"
				secondaryType="padding"
				onChange={onChange}
				label="Padding"
			/>
			<NumberSetting
				type="textBorderWidth"
				onChange={onChange}
				text={props.textBorderWidth}
				label="Border width"
				ariaLabel="Border width in pixels"
			/>
			<ColorPickerSetting
				type="textBorderColor"
				onChange={onChange}
				selectedColor={props.textBorderColor || 'transparent'}
				label="Border color"
			/>
			<NumberSetting
				type="textBorderRadius"
				onChange={onChange}
				text={props.textBorderRadius}
				label="Border radius"
				ariaLabel="Border radius in pixels"
			/>
			<ShadowsSettings
				selectedValue={
					props.box_shadow || {
						offsetx: 1,
						offsety: 1,
						blur: 10,
						spread: 1,
						color: '#000',
					}
				}
				onChange={onChange}
				label="Shadow settings"
			/>
		</SimpleGrid>
	);
};

const ImageStyles = (props) => {
	const onChange = useCallback((data) => {
		props.onChange(props.settingType, data);
	}, []);

	return (
		<SimpleGrid columns={2} spacing={5}>
			<ColorPickerSetting
				type="background_color"
				onChange={onChange}
				selectedColor={props.background_color || 'transparent'}
				label="Background color"
			/>
			<NumberSetting
				type="imageBorderWidth"
				onChange={onChange}
				text={props.imageBorderWidth}
				label="Border width"
				ariaLabel="Border width in pixels"
			/>
			<NumberSetting
				type="imageBorderRadius"
				onChange={onChange}
				text={props.imageBorderRadius}
				label="Image border radius"
				ariaLabel="Image border radius in pixels"
			/>
			<ColorPickerSetting
				type="imageBorderColor"
				onChange={onChange}
				selectedColor={props.imageBorderColor || 'transparent'}
				label="Border color"
			/>
			<PaddingSetting
				multiPadding={props.multiPadding}
				padding={props.padding}
				type="multiPadding"
				label={'Label padding'}
				secondaryType={ButtonSettingsFields.padding}
				onChange={onChange}
			/>
			<ShadowsSettings
				selectedValue={
					props.box_shadow || {
						offsetx: 1,
						offsety: 1,
						blur: 10,
						spread: 1,
						color: '#000',
					}
				}
				onChange={onChange}
				label="Shadow settings"
			/>
			<AlignmentSetting type="alignment" onChange={onChange} selected={props.alignment} options={AlignOptions} label="Alignment" />
		</SimpleGrid>
	);
};

const SocialStyles = (props) => {
	const onChange = useCallback((data) => {
		props.onChange(props.settingType, data);
	}, []);

	return (
		<SimpleGrid columns={2} spacing={5}>
			<ColorPickerSetting
				type="background_color"
				onChange={onChange}
				selectedColor={props.background_color || 'transparent'}
				label="Background color"
			/>
			<NumberSetting
				type={SocialFollowSettingsFields.spacing}
				onChange={onChange}
				text={props.spacing}
				label="Spacing"
				ariaLabel="Number of pixels"
			/>
			<AlignmentSetting
				type={SocialFollowSettingsFields.alignment}
				onChange={onChange}
				selected={props.alignment}
				options={AlignOptions}
				label="Alignment"
			/>
			<ShadowsSettings
				selectedValue={
					props.box_shadow || {
						offsetx: 1,
						offsety: 1,
						blur: 10,
						spread: 1,
						color: '#000',
					}
				}
				onChange={onChange}
				label="Shadow settings"
			/>
		</SimpleGrid>
	);
};

export default StylingSettings;
