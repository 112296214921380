import React from 'react';

import { Box, Image } from '@chakra-ui/react';

import { getFormFieldFontStyles } from '@/util/helper';
import { convertSize, getPadding, getShadowValFromProps } from '@/util/shared';

import PlusIcon from 'assets/images/component_icons/plus_icon_outlined.svg';

import Element from './Element.react';

export const FileUpload = (props) => {
	const {
		labelTextDecoration,
		labelFontStyle,
		labelFontWeight,
		labelHide,
		labelFont,
		labelFontSize,
		labelFontColour,
		descriptionFontColour,
		descriptionFontSize,
		descriptionSpacing,
		descriptionFontFamily,
		descriptionTextDecoration,
		descriptionFontStyle,
		descriptionFontWeight,
		descriptionHide,
		asteriskColor,
	} = props;

	const labelStyle = {
		color: labelFontColour,
		fontSize: labelFontSize,
		fontWeight: labelFontWeight,
		fontStyle: labelFontStyle,
		textDecoration: labelTextDecoration,
		display: labelHide ? 'none' : '',
		...getFormFieldFontStyles(labelFont),
	};

	const descriptionStyles = {
		color: descriptionFontColour,
		fontSize: descriptionFontSize,
		fontFamily: descriptionFontFamily,
		marginTop: descriptionSpacing,
		fontStyle: descriptionFontStyle,
		fontWeight: descriptionFontWeight,
		textDecoration: descriptionTextDecoration,
		display: descriptionHide ? 'none' : 'block',
	};

	const asteriskStyles = {
		color: asteriskColor,
		textDecoration: 'none',
		fontWeight: 'normal',
		fontStyle: 'normal',
		fontFamily: 'inherit',
	};

	const getDescription = () => {
		return props.description
			.replaceAll('[count]', props.maxFiles)
			.replaceAll('[size]', convertSize(props.fileSizeLimit, 'Bytes', props.fileSizeLimitUnit) + props.fileSizeLimitUnit);
	};

	return (
		<Box bgColor={props.background_color} style={{ ...getPadding(props, true) }}>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				w="100%"
				bgColor={props.fieldBgColor}
				borderWidth={props.fieldBorderWidth}
				borderStyle="dashed"
				borderColor={props.fieldBorderColour}
				borderRadius={props.fieldBorderRadius}
				boxShadow={getShadowValFromProps(props.box_shadow)}
				style={{ padding: props.fieldPadding }}
			>
				<Box m="auto" textAlign="center">
					<Image src={PlusIcon} mx="auto" mb={3} />

					<span style={labelStyle} className="form-field-label">
						{props.label}
						{props.required && (
							<span className="form-field-required" style={asteriskStyles}>
								{' '}
								*
							</span>
						)}
					</span>

					{props.description && <div style={descriptionStyles}>{getDescription()}</div>}
				</Box>
			</Box>
		</Box>
	);
};

export default Element(FileUpload);
