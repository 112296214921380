import React from 'react';

import useContentStore from '@/stores/ContentStore';

import SharedInputSettings from './SharedInput-settings.react';

export const MultiSelectSettingComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

const MultiSelectSettings = (props) => {
	const saveElementSettings = useContentStore((state) => state.saveElementSettings);

	const onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		if (props.address) {
			saveElementSettings({
				address: props.address,
				settings,
			});
		}
	};

	return (
		<div className="text-input-settings">
			<MultiSelectSettingComponents
				{...props}
				onChange={onChange}
				settings={{
					options: {
						active: true,
					},
					optionsLimit: {
						active: true,
					},
					hiddenField: {
						active: false,
					},
					prefill: {
						active: false,
					},
				}}
			/>
		</div>
	);
};

export default MultiSelectSettings;
