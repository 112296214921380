import { findDOMNode } from 'react-dom';

import useContentStore from '@/stores/ContentStore';

export const rowSourceSpec = {
	beginDrag(props) {
		useContentStore.getState().toggleDragging(props.id);
		useContentStore.getState().toggleRowDragging();
		return {
			row: props,
			originalIndex: props.index,
			pageIndex: props.pageIndex,
		};
	},

	endDrag() {
		useContentStore.getState().toggleDragging();
		useContentStore.getState().toggleRowDragging();
	},
};

export const rowTargetSpec = {
	canDrop() {
		return false;
	},

	hover(props, monitor, component) {
		const { row, originalIndex: dragIndex, newRow, pageIndex } = monitor.getItem();
		const draggedId = row.id;
		const { pageIndex: newPageIndex } = props;
		const overIndex = props.index;

		const contentStore = useContentStore.getState();

		if (dragIndex === overIndex) {
			return;
		}

		//$FlowIgnore
		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

		const clientOffset = monitor.getClientOffset();

		const hoverClientY = clientOffset.y - hoverBoundingRect.top;

		if (props.isMobileView && pageIndex !== newPageIndex) {
			return;
		} else if (pageIndex !== newPageIndex) {
			contentStore.moveRowToPage({ rowId: draggedId, pageIndex: newPageIndex });
			monitor.getItem().pageIndex = newPageIndex;
			return;
		}

		// Dragging downwards
		if (dragIndex < overIndex && hoverClientY < hoverMiddleY) {
			return;
		}

		// Dragging upwards
		if (dragIndex > overIndex && hoverClientY > hoverMiddleY) {
			return;
		}

		if (newRow) {
			contentStore.moveNewRow({ rowId: draggedId, newIndex: overIndex, pageIndex: newPageIndex });
		} else {
			contentStore.moveRow({ rowId: draggedId, newIndex: overIndex, pageIndex: newPageIndex });
		}

		monitor.getItem().originalIndex = overIndex;
	},
};
