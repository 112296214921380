import React from 'react';

import { Box, CircularProgress, Text } from '@chakra-ui/react';

import '@moosend/mooskin/lib/index/style.css';

import HTML5Backend from '@silvain-sayduck/react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { Outlet } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import useModalStore from '@/stores/ModalStore';

import { ModalTypes } from '@/util/resources';

import CropperModal from './gui/layout/modals/CropperModal.react';
import DontAskModal from './gui/layout/modals/DontAskModal.react';
import GeneralModal from './gui/layout/modals/GeneralModal.react';
import ImagePickerModal from './gui/layout/modals/ImagePickerModal.react';
import NotificationPopup from './gui/layout/modals/NotificationPopup.react';
import TemplatePreviewModal from './gui/layout/modals/TemplatePreviewModal.react';
import SaveCustomElementMenu from './gui/layout/SaveCustomElementMenu.react';
import SaveCustomRowMenu from './gui/layout/SaveCustomRowMenu.react';
import SaveTemplateMenu from './gui/layout/SaveTemplateMenu.react';
import TopMenu from './gui/layout/TopMenu.react';

const LoaderOverlay: React.FC = () => (
	<Box
		id="initial-overlay"
		zIndex={9999}
		display="flex"
		alignItems="center"
		flexDirection="column"
		position="fixed"
		top={0}
		left={0}
		right={0}
		bottom={0}
		backgroundColor="white"
		justifyContent="center"
	>
		<Text id="initial-error" fontSize="2xl" mb="4"></Text>
		<CircularProgress isIndeterminate capIsRound color="primary" trackColor="neutral-color.200" id="initial-loader" />
	</Box>
);

const App: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { generalModal, cropModal, dontAskModal, imagePickerModal, templatePreviewModal } = useModalStore(
		(state) => ({
			generalModal: state[ModalTypes.GENERAL_MODAL],
			dontAskModal: state[ModalTypes.DONT_ASK],
			templatePreviewModal: state[ModalTypes.TEMPLATE_PREVIEW_MODAL],
			imagePickerModal: state[ModalTypes.IMAGE_PICKER],
			cropModal: state[ModalTypes.CROP_MODAL],
		}),
		shallow,
	);

	return (
		<>
			<LoaderOverlay />
			<TemplatePreviewModal show={templatePreviewModal.show} data={templatePreviewModal.data} />
			<GeneralModal show={generalModal.show} data={generalModal.data} />
			<CropperModal show={cropModal.show} data={cropModal.data} />
			<ImagePickerModal {...imagePickerModal.data} />
			<SaveTemplateMenu />
			<SaveCustomRowMenu />
			<SaveCustomElementMenu />
			<NotificationPopup />
			<DontAskModal show={dontAskModal.show} data={dontAskModal.data} />
			<Box display="flex" flexDirection="column" h="full">
				<TopMenu />
				<Box h="full" overflowX="hidden" overflowY="auto">
					{children}
					<Outlet />
				</Box>
			</Box>
		</>
	);
};

export default DragDropContext(HTML5Backend)(App);
