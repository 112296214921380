import React from 'react';

import { convertSize, getId } from 'xmc-forms/src/util/shared.js';

import { imageUrl } from '../utils.js';

export const DefaultAcceptedFileTypes = ['.jpg, .jpeg', '.pdf', '.png', '.gif'];

const FileUpload = ({ type, label, description, name, required, maxFiles, fileSizeLimit, fileSizeLimitUnit, ...rest }) => {
	const id = getId(rest.id);
	const acceptedFileTypes = rest.acceptedFileTypes?.length ? rest.acceptedFileTypes : DefaultAcceptedFileTypes;

	const getDescription = () => {
		return description
			.replaceAll('[count]', maxFiles)
			.replaceAll('[size]', convertSize(fileSizeLimit, 'Bytes', fileSizeLimitUnit) + fileSizeLimitUnit);
	};

	return (
		<div
			id={id}
			className={`component shared-input-field file-upload-field ${
				required ? 'required-component' : ''
			} ${type}-component ${type}-wrapper ${type}${id}`}
		>
			<div className={`global-input-wrapper ${type}-dropzone`}>
				<input
					type="file"
					data-type={type}
					className={`form-input-element ${type}-input`}
					accept={acceptedFileTypes.join(',').replace(/,\s/g, ',')}
					multiple={maxFiles > 1}
					data-max-files={maxFiles}
					data-file-size-limit={fileSizeLimit}
				/>
				<input
					type="hidden"
					data-type={type}
					data-id={id}
					required={required}
					data-required={required}
					className={`form-input-element ${type}-input-value`}
					name={name}
				/>

				<div className={`${type}-box`}>
					<img src={imageUrl('/images/file_upload/plus_outlined.png')} alt="" />
					{label && (
						<span className="global-input-label form-field-label ">
							{label}
							<span className="global-field-required form-field-required "> *</span>
						</span>
					)}
					{description && <div className="global-input-description form-input-description  ">{getDescription()}</div>}
				</div>
			</div>

			<div className={`${type}-error-container component-error-container`} />

			<div className={`${type}-files`}>
				{Array.from(Array(maxFiles).keys()).map((idx) => (
					<div key={idx} className={`${type}-file-wrapper`}>
						<div className={`${type}-file`}>
							<img src={imageUrl('/images/file_upload/file.png')} alt="" />
							<span className={`${type}-file-name`} />
							<span className={`${type}-file-size`} />

							<div className={`${type}-file-actions`}>
								<button type="button" className={`${type}-remove`} />
								<div className={`${type}-loader`} />
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default FileUpload;
