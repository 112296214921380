import { useRef, useState } from 'react';

import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import Cropper from 'react-cropper';

import useContentStore from '@/stores/ContentStore';
import useModalStore from '@/stores/ModalStore';

const CropperModalFields = {
	cropWidth: 'cropWidth',
	cropHeight: 'cropHeight',
};

const CropperModal = ({ show, data }) => {
	const [, setImageLoaded] = useState(false);
	const [cropWidth, setCropWidth] = useState(null);
	const [cropHeight, setCropHeight] = useState(null);
	const [scale, setScale] = useState(1);
	const cropperRef = useRef(null);

	const cropImage = () => {
		const cropper = cropperRef.current.cropper;
		const imgData = cropper.getImageData();
		const contentStore = useContentStore.getState();

		const cropData = {
			...cropper.getData(true),
			scale: imgData.naturalWidth / imgData.width,
		};

		if (data.generalSettings) {
			if (data.customLogo) {
				contentStore.cropCustomLogo({
					originalSrc: data.customLogo,
					imageType: data.customImageType,
					cropData,
				});
			} else {
				contentStore.cropPromotionImage({
					originalSrc: data.originalSrc,
					imageType: data.imageType,
					cropData,
				});
			}
		} else {
			contentStore.cropImage({
				address: data.address,
				originalSrc: data.originalSrc,
				type: data.imageType,
				cropData,
			});
		}

		useModalStore.getState().closeCropper();
		resetState();
	};

	const cropperReady = () => {
		const cropper = cropperRef.current.cropper;
		const imgData = cropper.getImageData();
		setImageLoaded(true);
		setScale(imgData.naturalWidth / imgData.width);
		updateFields();
	};

	const closeCropper = () => {
		resetState();
		useModalStore.getState().closeCropper();
	};

	const resetState = () => {
		setImageLoaded(false);
		setCropWidth(null);
		setCropHeight(null);
	};

	const onChange = (type, value) => {
		const cropper = cropperRef.current.cropper;
		let cropBoxData = { ...cropper.getCropBoxData() };

		switch (type) {
			case CropperModalFields.cropWidth:
				cropBoxData.width = parseInt(value) / scale;
				break;
			case CropperModalFields.cropHeight:
				cropBoxData.height = parseInt(value) / scale;
				break;
			default:
				break;
		}

		setCropWidth(cropBoxData.width * scale);
		setCropHeight(cropBoxData.height * scale);

		cropper.setCropBoxData(cropBoxData);
	};

	const updateFields = () => {
		const cropper = cropperRef.current.cropper;
		const cropBoxData = cropper.getCropBoxData();
		setCropWidth(cropBoxData.width * scale);
		setCropHeight(cropBoxData.height * scale);
	};

	return (
		<Modal onClose={closeCropper} isOpen={show} size="xl">
			<ModalOverlay />
			<ModalContent h="fit-content" overflow="auto">
				<ModalHeader>Crop Image</ModalHeader>
				<ModalCloseButton />

				<ModalBody>
					<Cropper
						ref={cropperRef}
						src={data.originalCustomLogo || data.originalSrc}
						checkCrossOrigin={false}
						zoomable={false}
						guides={true}
						ready={cropperReady}
						cropend={updateFields}
					/>
					<Box display="flex" mx="auto" mt={6} w="100%" gap={4}>
						<FormControl>
							<FormLabel>Width</FormLabel>
							<Input type="number" value={cropWidth || ''} onChange={(e) => onChange(CropperModalFields.cropWidth, e.target.value)} />
						</FormControl>
						<FormControl>
							<FormLabel>Height</FormLabel>
							<Input type="number" value={cropHeight || ''} onChange={(e) => onChange(CropperModalFields.cropHeight, e.target.value)} />
						</FormControl>
					</Box>
				</ModalBody>

				<ModalFooter gap="2">
					<Button variant="ghost" onClick={closeCropper}>
						Cancel
					</Button>
					<Button onClick={cropImage}>Crop</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default CropperModal;
