import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { mdiMagnify } from '@mdi/js';

import useTemplateStore from '@/stores/TemplateStore';

import { Icon } from '@/components/gui/shared/Icon';

const SearchBox = (props) => {
	const { addSearchTerm } = useTemplateStore();

	const onSearchTemplate = (e) => {
		addSearchTerm(e.target.value);
	};

	return (
		<InputGroup maxW="sm">
			<Input placeholder="Search for a template" value={props.searchTerm} onChange={onSearchTemplate} />
			<InputLeftElement>
				<Icon path={mdiMagnify} />
			</InputLeftElement>
		</InputGroup>
	);
};

export default SearchBox;
