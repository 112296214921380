import React from 'react';

import { Box, Text } from '@chakra-ui/react';

import useContentStore from '@/stores/ContentStore';

import { SettingsBox } from '@/components/gui/settingsContext/SettingsContext';
import { settingsLabelStyle } from '@/components/gui/shared/settings/Settings.styles';
import TextEdit from '@/components/gui/shared/text/TextEdit.react';

import SharedInputSettings from './SharedInput-settings.react';

export const GDPRboxComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

const sharedGDPRBoxSettings = {
	required: {
		active: false,
	},
	placeholder: {
		active: false,
	},
	description: {
		active: false,
	},
	hiddenField: {
		active: false,
	},
	prefill: {
		active: false,
	},
	fieldGroup: {
		label: 'Checkbox styles',
		settings: {
			fieldBgColor: {
				label: 'Checkbox background color',
			},
			fieldFont: {
				active: false,
			},
			fieldFontSize: {
				active: true,
				label: 'Tick font size',
			},
			fieldFontColour: {
				label: 'Tick color',
			},
			fieldTextDecoration: {
				active: false,
			},
		},
	},
	labelGroup: {
		settings: {
			labelPosition: {
				active: false,
			},
			labelWidth: {
				label: 'Label Height',
				active: true,
			},
			labelHide: {
				active: false,
			},
		},
	},
	placeholderGroup: {
		active: false,
	},
	descriptionGroup: {
		active: false,
	},
};

const GDPRboxSettings = (props) => {
	const saveElementSettings = useContentStore((state) => state.saveElementSettings);

	const onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		if (props.address) {
			saveElementSettings({
				address: props.address,
				settings,
			});
		}
	};

	const onTextChangeLegal = (value) => {
		onChange({ type: 'legalText', value });
	};

	return (
		<Box className="gdpr-settings">
			<SettingsBox filterImmunity>
				<Box mb={4} label="Legal text">
					<Text {...settingsLabelStyle}>Legal text</Text>

					<Box p={2.5} borderColor="chakra-border-color" borderWidth={1} borderStyle="solid" borderRadius="sm" bgColor="white">
						<TextEdit
							general_styles={props.general_styles}
							id={`${props.id}-legal`}
							text={props.legalText}
							onChange={onTextChangeLegal}
							type="legalText"
							customToolbar={`newdocument cut copy pastetext outdent indent link unlink openlink anchor numlist bullist blockquote alignleft alignright aligncenter alignjustify bold italic strikethrough underline removeformat forecolor backcolor blocks fontfamily fontsize spellchecker`}
						/>
					</Box>
				</Box>
			</SettingsBox>

			<GDPRboxComponents {...props} onChange={onChange} settings={sharedGDPRBoxSettings} />
		</Box>
	);
};

export default GDPRboxSettings;
