import React, { createRef } from 'react';

import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Input,
	Menu,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import config from 'config/config';
import { ErrorMessages, SuccessMessages } from 'config/messages';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import useContentStore from '@/stores/ContentStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import { showNotification } from '@/stores/NotificationStore';
import useTemplateStore from '@/stores/TemplateStore';
import useUserStore from '@/stores/UserStore';

import { claimsKeys, getUser } from '@/util/auth/auth';
import { pathPrefix } from '@/util/helper';
import { ApiAuthorisationModes, NotificationTypes, PublicOptions, TemplateAccessLevel } from '@/util/resources';

export class SaveTemplateMenu extends React.PureComponent {
	constructor(props) {
		super(props);

		this.nameInputRef = createRef();

		this.state = {
			loading: false,
			name: this.props.name || '',
			imageSrc: '',
			imageMobileSrc: '',
			categories: '',
			tenants: [],
			AuthorisationMode: TemplateAccessLevel[0].value,
			selectedTemplate: this.props.selectedTemplate || undefined,
			selectedTemplateId: undefined,
			selectedImageSrc: undefined,
			selectedImageMobileSrc: undefined,
			selectedCategories: undefined,
		};
	}

	componentDidMount() {
		let templates = this.props.templates.length ? this.props.templates : [];
		if (templates) {
			templates.forEach((template) => {
				if (
					this.props.contentId &&
					template.id &&
					(template.id.includes(this.props.contentId) || template.id.includes(this.props.contenttestid)) &&
					template.AuthorisationMode === ApiAuthorisationModes.Private
				) {
					this.setState({ name: template.title, selectedTemplate: template, selectedTemplateId: template.id });
				}
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.contentId && !this.props.contentId) {
			this.unsetTemplate();
		}
	}

	render() {
		const dropDown = this.state.name && !this.state.selectedTemplate && this.renderDropDown();

		return (
			<Modal
				onClose={this.onClose}
				isOpen={this.props.location.pathname === pathPrefix() + '/save-template' || import.meta.env.MODE === 'test'}
				size="lg"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Save as template</ModalHeader>
					<ModalCloseButton data-testid="save-template-modal-close" />
					<ModalBody>
						<FormControl mb={3}>
							<FormLabel>Template name</FormLabel>
							<Input
								ref={this.nameInputRef}
								data-testid="save-template-name"
								value={this.state.name}
								readOnly={this.state.selectedTemplate}
								onChange={(e) => this.setState({ name: e.target.value })}
							/>
							<Box pos="relative" sx={{ '& > *': { w: '100%' } }}>
								{dropDown}
							</Box>
						</FormControl>

						{import.meta.env.MODE === 'test' || this.props.allowProdSave ? (
							<React.Fragment>
								<FormControl mb={3}>
									<FormLabel>Authorization mode</FormLabel>
									<Select
										useBasicStyles
										data-testid="auth-mode-select"
										value={this.state.AuthorisationMode}
										onChange={(selectedOption) => this.setState({ AuthorisationMode: selectedOption.value })}
										placeholder="Select an option"
										options={PublicOptions}
									/>
								</FormControl>
								<FormControl mb={3}>
									<FormLabel>Image</FormLabel>
									<Input value={this.state.imageSrc} onChange={(e) => this.setState({ imageSrc: e.target.value })} />
								</FormControl>
								<FormControl mb={3}>
									<FormLabel>Mobile image</FormLabel>
									<Input value={this.state.imageMobileSrc} onChange={(e) => this.setState({ imageMobileSrc: e.target.value })} />
								</FormControl>
								<FormControl>
									<FormLabel>Categories</FormLabel>
									<Input value={this.state.categories} onChange={(e) => this.setState({ categories: e.target.value })} />
								</FormControl>
							</React.Fragment>
						) : (
							<>
								<FormControl mb={3}>
									<FormLabel>Save template in</FormLabel>
									<Select
										data-testid="access-level-select"
										// selectedValue={this.state.AuthorisationMode}
										value={TemplateAccessLevel.find((item) => item.value === this.state.AuthorisationMode)}
										defaultValue={TemplateAccessLevel[0]}
										onChange={(selectedOption) => {
											const userData = getUser();

											let tenants;

											if (selectedOption.value !== ApiAuthorisationModes.Organization) {
												tenants = [];
											} else {
												const currentTenant = this.props.availableTenants.find((item) => item.id === userData[claimsKeys.TENANT_ID]);
												if (currentTenant) {
													tenants = [{ value: currentTenant.id, label: currentTenant.displayName }];
												}
											}

											this.setState({
												AuthorisationMode: selectedOption.value,
												tenants,
											});
										}}
										placeholder="Select an option"
										options={TemplateAccessLevel}
									/>
								</FormControl>
								{this.state.AuthorisationMode === ApiAuthorisationModes.Organization && (
									<FormControl>
										<FormLabel>Environments</FormLabel>
										<Select
											isMulti
											useBasicStyles
											closeMenuOnSelect={false}
											hideSelectedOptions={false}
											selectedOptionStyle="check"
											data-testid="access-level-select"
											value={this.state.tenants}
											onChange={(options) => this.setState({ tenants: options })}
											placeholder="Select an option"
											options={this.props.availableTenants.map((item) => ({ value: item.id, label: item.displayName }))}
										/>
										{!this.state.tenants.length ? (
											<Text mt="2" fontSize="xs">
												This template will be available on all environments of this organization
											</Text>
										) : null}
									</FormControl>
								)}
							</>
						)}
					</ModalBody>
					<ModalFooter gap="2">
						{this.state.selectedTemplate ? (
							<Box display="flex" justifyContent="flex-end">
								<Button variant="ghost" onClick={this.unsetTemplate} isDisabled={this.state.loading}>
									Cancel
								</Button>
								<Button onClick={this.overrideTemplate} isDisabled={this.state.loading}>
									Overwrite existing
								</Button>
							</Box>
						) : (
							<Box display="flex" justifyContent="flex-end">
								<Button data-testid="save-template-button" onClick={this.saveTemplate} isDisabled={this.state.loading}>
									Save
								</Button>
							</Box>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	}

	authModeOptions = PublicOptions.map((item) => {
		return { value: item.value, label: item.label };
	});

	onClose = () => {
		this.props.navigate(-1);
		this.setState({
			loading: false,
			name: this.props.name || '',
			imageSrc: '',
			imageMobileSrc: '',
			categories: '',
			tenants: [],
			AuthorisationMode: TemplateAccessLevel[0].value,
			selectedTemplate: this.props.selectedTemplate || undefined,
			selectedTemplateId: undefined,
			selectedImageSrc: undefined,
			selectedImageMobileSrc: undefined,
			selectedCategories: undefined,
		});
	};

	saveTemplate = () => {
		const contentStore = useContentStore.getState();

		if (!this.state.name || !this.state.name.replace(/\s/g, '').length) {
			return showNotification({ type: NotificationTypes.WARNING, text: 'Template must have a name' });
		}

		let templates = this.props.templates.length ? this.props.templates : [];

		const existingName =
			templates &&
			templates.length &&
			templates.find((item) => {
				return item.title.toLowerCase() === this.state.name.toLowerCase();
			});

		if (existingName) {
			return showNotification({ type: NotificationTypes.ERROR, text: 'A template with that name already exists' });
		}

		/* istanbul ignore next */
		let successCb = (templateId) => {
			this.setState({
				loading: false,
				name: this.props.name || '',
				imageSrc: '',
				imageMobileSrc: '',
				categories: '',
				AuthorisationMode: PublicOptions[0].value,
				selectedTemplate: this.props.selectedTemplate || undefined,
				selectedTemplateId: undefined,
				selectedImageSrc: undefined,
				selectedImageMobileSrc: undefined,
				selectedCategories: undefined,
			});
			contentStore.setTemplateId(templateId);
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.TEMPLATE_SAVED });
			this.props.navigate(-1);
		};

		/* istanbul ignore next */
		let errorCb = () => {
			this.setState({ loading: false });
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.TEMPLATE_NOT_SAVED });
		};

		/* istanbul ignore next */
		let limitErrorCb = () => {
			this.setState({ loading: false });
			showNotification({
				type: NotificationTypes.ERROR,
				text: ErrorMessages.TEMPLATE_NOT_SAVED_LIMIT_EXCEDED.replace(
					'{template_limit}',
					config.template_market.market_private_templates_limit.toString(),
				),
			});
		};

		let limit = templates.filter((template) => template.AuthorisationMode === ApiAuthorisationModes.Private).length;
		let categories =
			(this.state.categories &&
				this.state.categories
					.trim()
					.split(',')
					.map((cat) => cat.trim())) ||
			[];

		this.setState({ loading: true });

		const userData = getUser();

		this.props.saveNewTemplate(
			{
				title: this.state.name,
				categories: categories,
				imageSrc: this.state.imageSrc,
				imageMobileSrc: this.state.imageMobileSrc,
				createdBy: { name: userData.name, id: userData.sub, tenantId: userData[claimsKeys.TENANT_ID] },
				tenants: this.state.tenants,
				json: contentStore.content,
			},
			limit,
			this.state.AuthorisationMode,
			successCb,
			limitErrorCb,
			errorCb,
		);
	};

	renderDropDown = () => {
		const list = this.renderList();

		return (
			<Menu isOpen={Boolean(list?.length)} isLazy>
				<MenuList maxH="calc(50vh - 45px)" overflow="auto" width="100%" onFocus={() => this.nameInputRef.current?.focus()}>
					{list}
				</MenuList>
			</Menu>
		);
	};

	renderList = () => {
		let templates = this.props.templates.length ? this.props.templates : [];

		const list = [];

		if (templates) {
			templates.forEach((template, i) => {
				if (
					template.title.toLowerCase().includes(this.state.name.toLowerCase()) &&
					template.AuthorisationMode !== ApiAuthorisationModes.Public
				) {
					list.push(
						<MenuItem
							key={i}
							onClick={() => {
								this.setTemplateToEdit(template);
								this.nameInputRef.current?.blur();
							}}
							data-testid="dropdown-item"
						>
							{template.title}
						</MenuItem>,
					);
				}
			});
		}
		return list;
	};

	setTemplateToEdit = (template) => {
		this.setState({
			AuthorisationMode: template.AuthorisationMode,
			selectedTemplate: template,
			name: template.title,
			selectedTemplateId: template.id,
			selectedImageSrc: template.imageSrc,
			selectedImageMobileSrc: template.imageMobileSrc,
			selectedCategories: template.categories,
			tenants: template.tenants ? template.tenants : [],
		});
	};

	unsetTemplate = () => {
		this.setState({
			selectedTemplate: undefined,
			name: '',
			contentId: null,
			tenants: [],
			AuthorisationMode: TemplateAccessLevel[0].value,
		});
	};

	overrideTemplate = () => {
		let templates = this.props.templates.length ? this.props.templates : [];

		/* istanbul ignore next */
		let successCb = () => {
			this.setState({ loading: false });
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.TEMPLATE_SAVED });
			this.props.navigate(-1);
		};

		/* istanbul ignore next */
		let errorCb = () => {
			this.setState({ loading: false });
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.TEMPLATE_NOT_SAVED });
		};

		/* istanbul ignore next */
		let limitErrorCb = () => {
			this.setState({ loading: false });
			showNotification({
				type: NotificationTypes.ERROR,
				text: ErrorMessages.TEMPLATE_NOT_SAVED_LIMIT_EXCEDED.replace(
					'{template_limit}',
					config.template_market.market_private_templates_limit.toString(),
				),
			});
		};

		let limit = templates.filter((template) => template.AuthorisationMode === ApiAuthorisationModes.Private).length;

		const contentStore = useContentStore.getState();

		const categoryToUse = this.state.categories ? this.state.categories : this.state.selectedTemplate.categories.join(', ');
		let categories =
			(categoryToUse &&
				categoryToUse
					.trim()
					.split(',')
					.map((cat) => cat.trim())) ||
			[];

		this.setState({ loading: true });

		const userData = getUser();

		this.props.saveNewTemplate(
			{
				title: this.state.name.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, ''),
				id: this.state.selectedTemplateId,
				categories: categories,
				imageSrc: this.state.selectedImageSrc,
				imageMobileSrc: this.state.selectedImageMobileSrc,
				createdBy: { name: userData.name, id: userData.sub, tenantId: userData[claimsKeys.TENANT_ID] },
				tenants: this.state.tenants,
				json: contentStore.content,
			},
			limit,
			this.state.AuthorisationMode,
			successCb,
			limitErrorCb,
			errorCb,
		);
	};
}

const SaveTemplateMenuWrapper = (props) => {
	const templateProps = useTemplateStore();
	const { allowProdSave } = useLeftSidebarStore((state) => {
		return {
			allowProdSave: state.allowProdSave,
		};
	}, shallow);
	const templateId = useContentStore((state) => state.content.templateId);
	const availableTenants = useUserStore((state) => state.tenants);
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<SaveTemplateMenu
			{...templateProps}
			contentId={templateId}
			templates={templateProps.templatesMetadata}
			allowProdSave={allowProdSave}
			availableTenants={availableTenants}
			{...props}
			navigate={navigate}
			location={location}
		/>
	);
};

export default SaveTemplateMenuWrapper;
