import React from 'react';

import { getDesignerAlignment, getPadding, getShadowValFromProps } from 'xmc-forms/src/util/shared.js';

const Recaptcha = (props) => {
	const id = props.id ? props.id.toString().replace(/-/g, '') : '';

	return (
		<div
			id={id}
			className={`component global-recaptcha-wrapper ${props.type}-component ${props.type}${props.id.replace(
				/-/g,
				'',
			)} lp-flex-container lp-flex-align-center form-input-wrapper`}
		>
			<div
				className={`global-recaptcha-element recaptcha-element `}
				id={`recaptcha${props.entityId}`}
				data-sitekey={props.siteKey}
				data-theme={props.theme}
				data-id={id}
			/>
		</div>
	);
};

export const generateRecaptchaClass = (component) => {
	return `
        .${component.type}${component.id.replace(/-/g, '')} {
			flex-direction: column;
            -webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: ${getDesignerAlignment(component.alignment)};
			-ms-flex-align: ${getDesignerAlignment(component.alignment)};
			align-items: ${getDesignerAlignment(component.alignment)};
            background-color: ${component.background_color};
            display: flex;
			${getPadding(component)}
        }

		.${component.type}${component.id.replace(/-/g, '')} .recaptcha-element {
            box-shadow: ${getShadowValFromProps(component.box_shadow)};
        }
    `;
};

export default Recaptcha;
