import { ErrorMessages } from 'config/messages';

import { KeyboardKeys } from './resources';

const DomHelper = {
	traverseDOM(node, func) {
		func(node);
		node = node ? node.firstChild : null;
		while (node) {
			this.traverseDOM(node, func);
			node = node ? node.nextSibling : null;
		}
	},

	stripHtmlTags(html) {
		var tmp = document.createElement('DIV');
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || '';
	},

	setAttributeList(domElement, attributeList) {
		//TODO: refactor this
		let excludedAttrs = ['width', 'height'];

		if (attributeList && domElement) {
			attributeList.forEach((attr) => {
				if (!excludedAttrs.includes(attr.name)) {
					domElement.setAttribute(attr.name, attr.value);
				}
			});
		}
	},

	/**
	 * Returns an attribute object with the name take from @param attributeName from the given list that looks like this example: { name: 'style', value: 'color:red;'}. If no attribute with the name taken from @param attributeName is found then returns null.
	 * @param attributeName - a string that represents a DOM Element attribute name
	 * @param attributeList - attribute list as provided by getAttributeFromList()
	 */
	getAttributeFromList(attributeName, attributeList) {
		if (attributeList) {
			let foundAttr = null;
			attributeList.forEach((attr) => {
				if (attr.name === attributeName) {
					foundAttr = attr;
					return;
				}
			});
			return foundAttr;
		}
	},

	attributesToArray(domElement, excludedAttrs = []) {
		if (!domElement) {
			return [];
		}

		let attrs = [];

		let defaultExcludedAttributes = ['data-reactid'];

		let excludedAttributes = [...defaultExcludedAttributes, ...excludedAttrs];

		try {
			for (let attr in domElement.attributes) {
				//$FlowIgnore
				if (
					Object.prototype.hasOwnProperty.call(domElement.attributes, attr) &&
					!excludedAttributes.includes(domElement.attributes[attr].name)
				) {
					attrs.push({
						//$FlowIgnore
						name: domElement.attributes[attr].name,
						//$FlowIgnore
						value: domElement.attributes[attr].value,
					});
				}
			}
		} catch (e) {
			console.error(e);
		}

		return attrs;
	},

	preventBackspaceNavigation() {
		window.onkeydown = function (e) {
			var rx = /INPUT|SELECT|TEXTAREA|DIV/i;

			if (e.which == 8) {
				if (!rx.test(e.target.tagName) || e.target.disabled || e.target.readOnly) {
					e.preventDefault();
				}
			}
		};
	},

	bindUndoKeyboardCombo(cb) {
		document.addEventListener('keydown', (e) => {
			if (e.keyCode == 90 && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				cb();
			}
		});
	},

	bindSaveKeyboardCombo(cb) {
		document.addEventListener('keydown', (e) => {
			if (e.keyCode == 83 && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				cb();
			}
		});
	},

	onKeyUpModalListener(okCb, cancelCb) {
		return (event) => {
			switch (event.keyCode) {
				case KeyboardKeys.ENTER:
					if (okCb) okCb();
					break;
				case KeyboardKeys.ESC:
					if (cancelCb) cancelCb();
			}
		};
	},

	handleInitialErrors(error) {
		let initialLoader = document.querySelector('#initial-loader');

		if (initialLoader && initialLoader.style) {
			initialLoader.style.display = 'none';
		}

		let errorDiv = document.querySelector('#initial-error');

		if (errorDiv) {
			if (error.status || error === 'Halt') {
				errorDiv.innerHTML = ErrorMessages.INITIAL_LOAD_SCREEN_GENERAL;
				console.error(error, true);
			} else if (error instanceof Error) {
				errorDiv.innerHTML = ErrorMessages.INITIAL_LOAD_SCREEN_GENERAL;
				console.error(error, true);
			} else if (error.type === 'browser') {
				errorDiv.innerHTML = ErrorMessages.INITIAL_LOAD_SCREEN_BROWSER_NOT_SUPPORTED;
				console.error(error);
			}
		}
	},

	checkTouch(cb) {
		let body = document.body;
		let detectTouch = (e) => {
			if (e.type === 'mousedown') {
				console.info('Mouse Detected!');
				if (cb) cb(false);
			} else if (e.type === 'touchstart') {
				console.info('Touch Detected!');
				if (cb) cb(true);
			}

			if (body) body.removeEventListener('touchstart', detectTouch);
			if (body) body.removeEventListener('mousedown', detectTouch);
		};
		// attach both events to body
		if (body) body.addEventListener('touchstart', detectTouch);
		if (body) body.addEventListener('mousedown', detectTouch);
	},

	findAncestor(el, cls) {
		while ((el = el.parentElement) && !el.classList.contains(cls));
		return el;
	},

	camelize(str) {
		str = str === null ? '' : str;

		let cameled = (str + '').replace(/_\D/g, function (match) {
			return match.charAt(1).toUpperCase();
		});

		return cameled.charAt(0).toUpperCase() + cameled.slice(1);
	},

	dashedToCamelCase(input) {
		return input.toLowerCase().replace(/-(.)/g, (match, group1) => {
			return group1.toUpperCase();
		});
	},
	/**
	 * Applies given react or DOM style object in the frm of cssProperty: 'value' to the actual dom element, thus
	 * modifying it's inline style declaration.
	 * @param domElement
	 * @param stylesObj
	 *
	 */

	applyStyles(domElement, stylesObj) {
		if (domElement) {
			for (let prop in stylesObj) {
				//$FlowIgnore
				domElement.style[prop] = stylesObj[prop];
			}
		}
	},
	stripUnwantedTags(html, tagsToBeRemoved) {
		let htmlCopy = html.cloneNode(true);

		tagsToBeRemoved.forEach((tag) => {
			if (htmlCopy.querySelectorAll(tag).length > 0) {
				for (let toBeRemoved of htmlCopy.querySelectorAll(tag)) {
					if (toBeRemoved.parentNode) toBeRemoved.parentNode.removeChild(toBeRemoved);
				}
			}
		});

		return htmlCopy;
	},
};

//TODO: make domHelpers consistent between parser and rest of APP
export default DomHelper;

/**
 * Takes an html string as a parameter and inserts it into a detached dom element, the innerHTML of which is returned.
 * @param htmlString
 */
export function stringToDOMElement(htmlString) {
	let parser = new DOMParser();
	let doc = parser.parseFromString(htmlString, 'text/html');

	return doc.body && doc.body.firstElementChild;
}
