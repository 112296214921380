import React from 'react';

import useContentStore from '@/stores/ContentStore';

import { SettingsBox } from '@/components/gui/settingsContext/SettingsContext';
import ColorPickerSetting from '@/components/gui/shared/settings/ColorPickerSetting.react';
import ShadowsSettings from '@/components/gui/shared/settings/ShadowsSettings.react';

export const SharedSettingComponents = React.memo((props) => {
	return (
		<SettingsBox>
			<ColorPickerSetting
				type="background_color"
				onChange={props.onChange}
				hex={props.hex}
				selectedColor={props.background_color || 'transparent'}
				label="Background color"
			/>
			<ShadowsSettings
				selectedValue={
					props.box_shadow || {
						offsetx: 1,
						offsety: 1,
						blur: 10,
						spread: 1,
						color: '#000',
					}
				}
				onChange={props.onChange}
				label="Shadow settings"
			/>
		</SettingsBox>
	);
});

const SharedSettings = (props) => {
	const saveElementSettings = useContentStore((state) => state.saveElementSettings);

	const onChange = (data) => {
		if (props.onChange) {
			props.onChange(data);
		} else {
			const settings = {
				[data.type]: data.value,
			};

			saveElementSettings({
				address: props.address,
				settings,
			});
		}
	};

	return (
		<div className="shared-settings">
			<SharedSettingComponents {...props} onChange={onChange} />
		</div>
	);
};

export default SharedSettings;
