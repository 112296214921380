/*
 * KEEP THIS FILE ISOMORPHIC
 * DO NOT IMPORT ANYTHING THAT IS NOT ISOMORPHIC AND WONT WORK ON THE NODE APP
 */

const DomHelper = {
	traverseDOM(node, func) {
		func(node);
		node = node ? node.firstChild : null;
		while (node) {
			this.traverseDOM(node, func);
			node = node ? node.nextSibling : null;
		}
	},

	stripHtmlTags(html) {
		var tmp = document.createElement('DIV');
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || '';
	},

	setAttributeList(domElement, attributeList) {
		//TODO: refactor this
		let excludedAttrs = ['width', 'height'];

		if (attributeList && domElement) {
			attributeList.forEach((attr) => {
				if (!excludedAttrs.includes(attr.name)) {
					domElement.setAttribute(attr.name, attr.value);
				}
			});
		}
	},

	/**
	 * Returns an attribute object with the name take from @param attributeName from the given list that looks like this example: { name: 'style', value: 'color:red;'}. If no attribute with the name taken from @param attributeName is found then returns null.
	 * @param attributeName - a string that represents a DOM Element attribute name
	 * @param attributeList - attribute list as provided by getAttributeFromList()
	 */
	getAttributeFromList(attributeName, attributeList) {
		if (attributeList) {
			let foundAttr = null;
			attributeList.forEach((attr) => {
				if (attr.name === attributeName) {
					foundAttr = attr;
					return;
				}
			});
			return foundAttr;
		}
	},

	attributesToArray(domElement, excludedAttrs = []) {
		if (!domElement) {
			return [];
		}

		let attrs = [];

		let defaultExcludedAttributes = ['data-reactid'];

		let excludedAttributes = [...defaultExcludedAttributes, ...excludedAttrs];

		try {
			for (let attr in domElement.attributes) {
				//$FlowIgnore
				if (
					Object.prototype.hasOwnProperty.call(domElement.attributes, attr) &&
					!excludedAttributes.includes(domElement.attributes[attr].name)
				) {
					attrs.push({
						//$FlowIgnore
						name: domElement.attributes[attr].name,
						//$FlowIgnore
						value: domElement.attributes[attr].value,
					});
				}
			}
		} catch (e) {
			console.error(e);
		}

		return attrs;
	},

	findAncestor(el, cls) {
		while ((el = el.parentElement) && !el.classList.contains(cls));
		return el;
	},

	camelize(str) {
		str = str === null ? '' : str;

		let cameled = (str + '').replace(/_\D/g, function (match) {
			return match.charAt(1).toUpperCase();
		});

		return cameled.charAt(0).toUpperCase() + cameled.slice(1);
	},

	dashedToCamelCase(input) {
		return input.toLowerCase().replace(/-(.)/g, (match, group1) => {
			return group1.toUpperCase();
		});
	},
	/**
	 * Applies given react or DOM style object in the frm of cssProperty: 'value' to the actual dom element, thus
	 * modifying it's inline style declaration.
	 * @param domElement
	 * @param stylesObj
	 *
	 */

	applyStyles(domElement, stylesObj) {
		if (domElement) {
			for (let prop in stylesObj) {
				//$FlowIgnore
				domElement.style[prop] = stylesObj[prop];
			}
		}
	},
	stripUnwantedTags(html, tagsToBeRemoved) {
		let htmlCopy = html.cloneNode(true);

		tagsToBeRemoved.forEach((tag) => {
			if (htmlCopy.querySelectorAll(tag).length > 0) {
				for (let toBeRemoved of htmlCopy.querySelectorAll(tag)) {
					if (toBeRemoved.parentNode) toBeRemoved.parentNode.removeChild(toBeRemoved);
				}
			}
		});

		return htmlCopy;
	},
};

//TODO: make domHelpers consistent between parser and rest of APP
export default DomHelper;

/**
 * Takes an html string as a parameter and inserts it into a detached dom element, the innerHTML of which is returned.
 * @param htmlString
 */
export function stringToDOMElement(htmlString) {
	let parser = new DOMParser();
	let doc = parser.parseFromString(htmlString, 'text/html');

	return doc.body && doc.body.firstElementChild;
}
