import React, { createRef } from 'react';

import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Input,
	Menu,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import config from 'config/config';
import { ErrorMessages, SuccessMessages, WarningMessages } from 'config/messages';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import { showNotification } from '@/stores/NotificationStore';

import { leadingDebounce, pathPrefix } from '@/util/helper';
import { ApiAuthorisationModes, NotificationTypes, PublicOptions } from '@/util/resources';

export class SaveCustomRowMenu extends React.PureComponent {
	constructor(props) {
		super(props);

		this.nameInputRef = createRef();

		this.state = {
			authorisationMode: ApiAuthorisationModes.Private,
			title: '',
			hoverItem: -1,
			rowToEdit: undefined,
			order: '',
			loading: false,
		};
	}

	render() {
		const isDev = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test' || this.props.allowProdSave;

		const dropDown = this.state.title && !this.state.rowToEdit && this.renderDropDown();

		return (
			<Modal
				onClose={this.onClose}
				isOpen={this.props.location.pathname === pathPrefix() + '/save-custom-row' || import.meta.env.MODE === 'test'}
			>
				<ModalOverlay>
					<ModalContent>
						<ModalHeader>Save layout to reuse</ModalHeader>
						<ModalCloseButton data-testid="save-layout-modal-close" />
						<ModalBody>
							<FormControl mb={3}>
								<FormLabel>Layout name</FormLabel>
								<Input
									ref={this.nameInputRef}
									maxLength={40}
									value={this.state.title}
									readOnly={this.state.rowToEdit}
									onChange={(e) => this.setState({ title: e.target.value })}
									data-testid="layout-name-input"
								/>
								<Box pos="relative" sx={{ '& > *': { w: '100%' } }}>
									{dropDown}
								</Box>
							</FormControl>
							{isDev && (
								<React.Fragment>
									<FormControl mb={3}>
										<FormLabel>Authorization mode</FormLabel>
										<Select
											useBasicStyles
											data-testid="auth-mode-select"
											selectedValue={this.state.authorisationMode}
											defaultValue={PublicOptions[0]}
											onChange={(selectedOption) => this.setState({ authorisationMode: selectedOption.value })}
											placeholder="Select an option"
											options={this.authModeOptions}
										/>
									</FormControl>
									<FormControl>
										<FormLabel>Layout order</FormLabel>
										<Input maxLength={40} value={this.state.order} onChange={(e) => this.setState({ order: e.target.value })} />
									</FormControl>
								</React.Fragment>
							)}
						</ModalBody>
						<ModalFooter gap="2">
							{this.state.rowToEdit ? (
								<Box display="flex" justifyContent="flex-end">
									{!this.state.loading && (
										<Button variant="ghost" onClick={this.unsetRow} isDisabled={this.state.loading}>
											Cancel
										</Button>
									)}
									<Button onClick={this.replaceRow} isDisabled={this.state.loading}>
										Overwrite existing
									</Button>
								</Box>
							) : (
								<Box display="flex" justifyContent="flex-end">
									<Button data-testid="save-layout-button" onClick={this.saveRow} isDisabled={this.state.loading}>
										Save
									</Button>
								</Box>
							)}
						</ModalFooter>
					</ModalContent>
				</ModalOverlay>
			</Modal>
		);
	}

	authModeOptions = PublicOptions.map((item) => {
		return { value: item.value, label: item.label };
	});

	saveRow = leadingDebounce(() => {
		if (!this.state.title) {
			showNotification({ type: NotificationTypes.WARNING, text: WarningMessages.CUSTOM_ROW_NO_TITLE });
			return;
		}

		/* istanbul ignore next */
		let successCb = () => {
			this.setState({ loading: false });
			this.props.getCustomRows();
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.CUSTOM_ROW_SAVED });
			this.setState({ title: '' });
			this.props.navigate(pathPrefix() + this.props.location.search);
		};

		/* istanbul ignore next */
		let errorCb = () => {
			this.setState({ loading: false });
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.CUSTOM_ROW_NOT_SAVED });
		};

		this.setState({ loading: true });

		if (this.props.row_to_save) {
			this.props.saveCustomRow(
				this.props.row_to_save,
				this.state.title,
				'',
				this.state.order,
				this.state.authorisationMode,
				successCb,
				errorCb,
			);
		}
	}, config.debounce_times.general);

	renderDropDown = () => {
		const list = this.renderList();

		return (
			<Menu isOpen={Boolean(list?.length)} isLazy>
				<MenuList maxH="calc(50vh - 45px)" overflow="auto" width="100%" onFocus={() => this.nameInputRef.current?.focus()}>
					{list}
				</MenuList>
			</Menu>
		);
	};

	renderList = () => {
		let rows = this.props.rows;

		const list = [];

		const filteredRows = this.props.allowProdSave
			? rows
			: rows.filter((row) => {
					return row.authorisationMode === ApiAuthorisationModes.Private;
				});

		if (filteredRows) {
			filteredRows.forEach((row, i) => {
				if (row.title.toLowerCase().includes(this.state.title.toLowerCase())) {
					list.push(
						<MenuItem
							key={i}
							onClick={() => {
								this.setRowToEdit(row);
								this.nameInputRef.current?.blur();
							}}
							data-testid="dropdown-item"
						>
							{row.title}
						</MenuItem>,
					);
				}
			});
		}

		return list;
	};

	setRowToEdit = (row) => {
		this.setState({ rowToEdit: row, title: row.title, order: row.order, authorisationMode: row.authorisationMode });
	};

	unsetRow = () => {
		this.setState({ rowToEdit: undefined, title: '' });
	};

	replaceRow = () => {
		const row = this.state.rowToEdit;

		/* istanbul ignore next */
		const saveError = () => {
			showNotification({ type: NotificationTypes.ERROR, text: 'Unknown error occurred' });
		};

		/* istanbul ignore next */
		const saveSuccess = () => {
			this.props.getCustomRows();
			showNotification({ type: NotificationTypes.SUCCESS, text: 'Successfully overwritten.' });
			this.unsetRow();
			this.props.navigate(pathPrefix() + this.props.location.search);
		};

		/* istanbul ignore next */
		this.props.saveCustomRow(
			this.props.row_to_save,
			this.state.title,
			row.id,
			this.state.order,
			this.state.authorisationMode,
			saveSuccess,
			saveError,
		);
	};

	onClose = () => {
		this.props.navigate(pathPrefix() + this.props.location.search);
	};
}

const SaveCustomRowMenuWrapper = (props) => {
	const { custom_rows, getCustomRows, saveCustomRow } = useLeftSidebarStore((state) => {
		return {
			custom_rows: state.custom_rows,
			getCustomRows: state.getCustomRows,
			saveCustomRow: state.saveCustomRow,
		};
	}, shallow);

	const navigate = useNavigate();
	const location = useLocation();

	return (
		<SaveCustomRowMenu
			{...custom_rows}
			getCustomRows={getCustomRows}
			saveCustomRow={saveCustomRow}
			{...props}
			navigate={navigate}
			location={location}
		/>
	);
};

export default SaveCustomRowMenuWrapper;
