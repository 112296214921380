import React from 'react';

import { Box, Button, Link as ChakraLink, Heading, Image, Spacer, Text } from '@chakra-ui/react';
import config from 'config/config';
import { Link, useLocation } from 'react-router-dom';

import useUserStore from '@/stores/UserStore';

import { claimsKeys, getUser } from '@/util/auth/auth';

import { getRoute } from '@/components/Table/strings';

import { AccountMenu } from './AccountMenu';
import PocketNav from './PocketNav';

export const TableTopBar = ({ noAccess }) => {
	const tenants = useUserStore((state) => state.tenants);
	const user = getUser();
	const tenantId = user[claimsKeys.TENANT_ID];
	const currentTenant = tenants.find((tenant) => tenant.id === tenantId);
	const location = useLocation();

	return (
		<Box as="header" layerStyle="section.topbar" flex="0 0 auto" display="flex" justifyContent="space-between" zIndex={2}>
			<Box display="flex" alignItems="center" gap="2">
				<ChakraLink href={config.xmc.dashboard_url} isExternal>
					<Image src="https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm_cloud" />
				</ChakraLink>
				<PocketNav />
				<Spacer mx={1} borderRight="1px solid" borderColor="gray.200" h="90%" alignSelf="center" />
				<Heading size="sm">Forms</Heading>
				{currentTenant && <Text mr="4">{currentTenant.displayName}</Text>}
				{!noAccess && (
					<>
						<TopBarButton label="Forms" path={getRoute.root('forms', 'list', 1)} isActive={location.pathname.startsWith('/forms/list')} />
						<TopBarButton
							label="Webhooks"
							path={getRoute.root('forms', 'webhook', 1)}
							isActive={location.pathname.startsWith('/forms/webhook')}
						/>
						<TopBarButton label="Styles" path={getRoute.stylingList()} isActive={location.pathname.startsWith('/forms/styling')} />
					</>
				)}
			</Box>
			<Box>
				<AccountMenu />
			</Box>
		</Box>
	);
};

const TopBarButton = (props) => {
	const { label, path, styles, isActive } = props;

	return (
		<Link to={path}>
			<Button variant="navigation" size="sm" isActive={isActive ? true : false} {...styles}>
				{label}
			</Button>
		</Link>
	);
};
