import React from 'react';

import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { mdiLockOpenVariantOutline, mdiLockOutline } from '@mdi/js';

import { Icon } from '@/components/gui/shared/Icon';

import NumberSetting from './NumberSetting.react';

export const paddingInputs = [
	{
		label: 'Padding Top',
		type: 'paddingTop',
		border: 'Top',
	},
	{
		label: 'Padding Bottom',
		type: 'paddingBottom',
		border: 'Bottom',
	},
	{
		label: 'Padding Left',
		type: 'paddingLeft',
		border: 'Left',
	},
	{
		label: 'Padding Right',
		type: 'paddingRight',
		border: 'Right',
	},
];

const PaddingSetting = (props) => {
	const onChange = (data) => {
		if (props.multiPadding && props.multiPadding.allow) {
			const newData = {
				value: {
					...props.multiPadding,
					[data.type]: data.value,
				},
				type: props.type,
			};

			if (props.onChange) props.onChange(newData);
		} else {
			const newData = {
				value: {
					...props.multiPadding,
					paddingTop: data.value,
					paddingLeft: data.value,
					paddingBottom: data.value,
					paddingRight: data.value,
				},
				type: props.type,
			};

			if (props.onChange) props.onChange([data, newData]);
		}
	};

	const onIconClick = () => {
		if (props.multiPadding && props.multiPadding.allow) {
			const data = {
				type: 'multiPadding',
				value: {
					...props.multiPadding,
					allow: !props.multiPadding.allow,
				},
			};

			if (props.onChange) props.onChange(data);
		} else {
			const data = {
				type: 'multiPadding',
				value: {
					paddingTop: props.padding,
					paddingLeft: props.padding,
					paddingBottom: props.padding,
					paddingRight: props.padding,
					allow: !props.multiPadding.allow,
				},
			};

			if (props.onChange) props.onChange(data);
		}
	};

	const icon = props.multiPadding && props.multiPadding.allow ? mdiLockOpenVariantOutline : mdiLockOutline;
	const iconStyle = {};

	if (!props.multiPadding.allow) {
		return (
			<Box position="relative">
				<NumberSetting
					key={'padding'}
					type={props.secondaryType}
					onChange={onChange}
					text={props.padding}
					label={props.label || 'Padding'}
				/>
				<Tooltip label="Unlock">
					<IconButton
						aria-label="Unlock"
						onClick={onIconClick}
						top={0}
						right={props.iconLeft || 0}
						variant="ghost"
						size="xs"
						position="absolute"
						icon={<Icon path={icon} />}
						data-testid="padding-setting-icon"
						{...iconStyle}
					/>
				</Tooltip>
			</Box>
		);
	} else {
		return (
			<Box key={1}>
				<Box
					className={`padding-setting`}
					color="chakra-subtle-text"
					fontWeight="medium"
					display="flex"
					mb={2.5}
					justifyContent="space-between"
				>
					{props.label}
					<Tooltip label="Lock">
						<IconButton
							aria-label="Lock"
							variant="ghost"
							size="xs"
							onClick={onIconClick}
							icon={<Icon path={icon} />}
							data-testid="padding-setting-icon"
							{...iconStyle}
						/>
					</Tooltip>
				</Box>
				<Box display="flex" justifyContent="space-between">
					{paddingInputs.map((item) => {
						return (
							<NumberSetting
								key={item.type}
								type={item.type}
								onChange={onChange}
								text={props.multiPadding[item.type]}
								label={item.label}
								customStyle={{
									customRoot: { width: 14, flex: 'none' },
									customLabel: { display: 'none' },
									customInput: {
										[`border${item.border}Color`]: 'black',
										[`border${item.border}Width`]: 3,
									},
								}}
							/>
						);
					})}
				</Box>
			</Box>
		);
	}
};

PaddingSetting.defaultProps = {
	label: 'Padding',
	multiPadding: {
		allow: false,
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		paddingLeft: 10,
	},
};

export default React.memo(PaddingSetting);
