export default {
	save_as: 'Save as',
	template_market: 'Load template',
	versions: 'Versions',
	drafts: 'Drafts',
	undo: 'Undo',
	clear_content: 'Clear',
	preview: 'Preview',
	import_html: 'Import HTML',
	send_test: 'Send test',
	proceed_to_next_step: 'Save and exit',
	proceed_to_save: 'Save template',
	proceed_to_edit: 'Update template and exit',
	edit_active_form_desing: 'Edit form',
	push_active_form_changes: 'Push changes',
	structure: 'Structure',
	elements: 'Elements',
	settings: 'Settings',
	script: 'Custom script',
	code_options: 'Code options',
	save_template: 'Save template',
	save_custom_row: 'Save layout',
	back_to_main: 'Back to edit mode',
	back_to_previous: 'Back to previous design',
	full_width_container: 'Full-width',
	half_width_container: 'Half-width',
	two_thirds_left_container: '2/3 + 1/3',
	two_thirds_right_container: '1/3 + 2/3',
	three_one_thirds_container: '3 × 1/3',
	full_width_container_first: 'Full-width',
	half_width_container_first: '2 × 1/2',
	two_thirds_left_container_first: '2/3 + 1/3',
	two_thirds_right_container_first: '1/3 + 2/3',
	three_one_thirds_container_first: '3 × 1/3',
	four_one_fourths_container_first: '4 × 1/4',
	radio: 'Radio button',
	fieldPlaceholder: 'Field placeholder',
	checkbox: 'Checkbox',
	checkbox_group: 'Checkbox group',
	gdpr: 'T&C checkbox',
	recaptcha: 'reCAPTCHA',
	email_input: 'Email',
	phone_input: 'Phone',
	text_input: 'Short text',
	longtext_input: 'Long text',
	singleselect_input: 'Select',
	multiselect_input: 'Multi select',
	date_input: 'Date',
	number_input: 'Number',
	file_upload: 'File upload',
	image: 'Image',
	text: 'Text',
	button: 'Button',
	submit_button: 'Button',
	article: 'Article',
	described_image: 'Described image',
	spacer: 'Spacer',
	social_follow: 'Social media',
	html: 'HTML',
	timer: 'Timer',
	form: 'Form',
	video: 'Video',
	product: 'Product',
	coupon: 'Coupon',
	design_view: 'Design view',
	code_view: 'Code view',
	split_view: 'Split view',
	desktop_view: (type) => `Desktop ${type}`,
	mobile_view: (type) => `Mobile ${type}`,
	no_settings_for_text: 'Use the text editing interface in the design area',
	no_settings_for_mobile: 'No available mobile settings for the selected element',
	select_element_for_information: 'Click on an element, to show useful script information',
	no_draft_title_default: '<No title>',
	no_draft_description_default: '<No description>',
	no_template_title_default: '<No title>',
	no_template_description_default: '<No description>',
	cancel_exit: 'Cancel and exit',
};
