import React from 'react';

import useContentStore from '@/stores/ContentStore';

import SharedInputSettings from './SharedInput-settings.react';

export const LongTextInputSettingComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

const LongTextInputSettings = (props) => {
	const saveElementSettings = useContentStore((state) => state.saveElementSettings);

	const onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		if (props.address) {
			saveElementSettings({
				address: props.address,
				settings,
			});
		}
	};

	return (
		<div className="text-input-settings">
			<LongTextInputSettingComponents {...props} onChange={onChange} settings={{ maxLength: { active: true } }} />
		</div>
	);
};

export default LongTextInputSettings;
