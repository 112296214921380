import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, SimpleGrid } from '@chakra-ui/react';
import { shallow } from 'zustand/shallow';

import useContentStore from '@/stores/ContentStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';

import strings from '@/resources/strings/en-us';
import { transformBlockTitle } from '@/util/helper';
import { ApiAuthorisationModes, PublicCustomRowImg, RowTypes } from '@/util/resources';
import { generateGuid } from '@/util/shared';

//preview images
import fullRowPreview from 'assets/images/drag_previews/full_row_preview.png';
import halfRowPreview from 'assets/images/drag_previews/half_row_preview.png';
import threeOneThirdsPreview from 'assets/images/drag_previews/three_one_thirds_row_preview.png';
import twoThirdsLeftPreview from 'assets/images/drag_previews/two_thirds_left_row_preview.png';
import twoThirdsRightPreview from 'assets/images/drag_previews/two_thirds_right_row_preview.png';
import oneFourthIcon from 'assets/images/left_structures/menuarea_1-1-1-1.svg';
import oneFourthIconCustom from 'assets/images/left_structures/menuarea_1-1-1-1.svg';
import oneThirdIcon from 'assets/images/left_structures/menuarea_1-1-1.svg';
import oneThirdIconCustom from 'assets/images/left_structures/menuarea_1-1-1.svg';
import halfIcon from 'assets/images/left_structures/menuarea_1-1.svg';
import halfIconCustom from 'assets/images/left_structures/menuarea_1-1.svg';
import twoThirdsRightIcon from 'assets/images/left_structures/menuarea_1-2.svg';
import twoThirdsRightIconCustom from 'assets/images/left_structures/menuarea_1-2.svg';
//custom normal row icons
import fullIcon from 'assets/images/left_structures/menuarea_1.svg';
import fullIconCustom from 'assets/images/left_structures/menuarea_1.svg';
import twoThirdsLeftIcon from 'assets/images/left_structures/menuarea_2-1.svg';
import twoThirdsLeftIconCustom from 'assets/images/left_structures/menuarea_2-1.svg';

import DraggableStructure from './DraggableStructure.react';

const customRowData = {
	[RowTypes.FULL]: {
		normalIconSrc: fullIconCustom,
	},
	[RowTypes.HALF]: {
		normalIconSrc: halfIconCustom,
	},
	[RowTypes.TWO_THIRDS_LEFT]: {
		normalIconSrc: twoThirdsLeftIconCustom,
	},
	[RowTypes.TWO_THIRDS_RIGHT]: {
		normalIconSrc: twoThirdsRightIconCustom,
	},
	[RowTypes.THREE_ONE_THIRDS]: {
		normalIconSrc: oneThirdIconCustom,
	},
	[RowTypes.FOUR_ONE_FOURTHS]: {
		normalIconSrc: oneFourthIconCustom,
	},
};

const _normalMenuItems = [
	{
		label_first: strings.full_width_container_first,
		iconSrc: fullIcon,
		previewIcon: fullRowPreview,
		type: RowTypes.FULL,
	},
	{
		label_first: strings.half_width_container_first,
		iconSrc: halfIcon,
		previewIcon: halfRowPreview,
		type: RowTypes.HALF,
	},
	{
		label_first: strings.two_thirds_right_container_first,
		iconSrc: twoThirdsRightIcon,
		previewIcon: twoThirdsRightPreview,
		type: RowTypes.TWO_THIRDS_RIGHT,
	},
	{
		label_first: strings.two_thirds_left_container_first,
		iconSrc: twoThirdsLeftIcon,
		previewIcon: twoThirdsLeftPreview,
		type: RowTypes.TWO_THIRDS_LEFT,
	},
	{
		label_first: strings.three_one_thirds_container_first,
		iconSrc: oneThirdIcon,
		previewIcon: threeOneThirdsPreview,
		type: RowTypes.THREE_ONE_THIRDS,
	},
	{
		label_first: strings.four_one_fourths_container_first,
		iconSrc: oneFourthIcon,
		previewIcon: threeOneThirdsPreview,
		type: RowTypes.FOUR_ONE_FOURTHS,
	},
];

const StructureMenu = () => {
	const { rows, getCustomRowData } = useLeftSidebarStore(
		(state) => ({ rows: state.custom_rows.rows, getCustomRowData: state.getCustomRowData }),
		shallow,
	);
	const { appendRow, appendCustomRow, saveRowSettings, removeRow, replaceRow } = useContentStore(
		(state) => ({
			appendRow: state.appendRow,
			appendCustomRow: state.appendCustomRow,
			saveRowSettings: state.saveRowSettings,
			removeRow: state.removeRow,
			replaceRow: state.replaceRow,
		}),
		shallow,
	);

	const onDoubleClickNormalItem = (type) => {
		appendRow({ rowType: type });
	};

	const onDoubleClickOldCustomItem = (row) => {
		appendCustomRow(row);
	};

	const onDoubleClickCustomItem = (row) => {
		const newId = generateGuid();

		appendRow({ rowType: row.type, id: newId });
		saveRowSettings({ loading: true, id: newId });

		const onError = () => {
			removeRow(newId);
		};

		const onSuccess = (customRow) => {
			const newRow = JSON.parse(customRow);
			replaceRow({ rowId: newId, row: newRow });
		};

		getCustomRowData(row, onSuccess, onError);
	};

	const onDoubleClick = (item) => {
		if (item.row) {
			onDoubleClickOldCustomItem(item.row);
		} else {
			onDoubleClickCustomItem(item);
		}
	};

	const getRowIcon = (type) => {
		return customRowData[type] ? customRowData[type].normalIconSrc : '';
	};

	const normalRowItems = _normalMenuItems.map((item, i) => (
		<DraggableStructure
			iconSrc={item.iconSrc}
			labelFirst={item.label_first}
			previewIcon={item.previewIcon}
			key={i}
			onDoubleClick={() => onDoubleClickNormalItem(item.type)}
			type={item.type}
		/>
	));

	const customRowItems = rows.map((item, i) => {
		let iconSrc;
		if (item.authorisationMode === ApiAuthorisationModes.Public) {
			const transformedTitle = transformBlockTitle(item.title);
			iconSrc = PublicCustomRowImg[transformedTitle];
		} else {
			iconSrc = item.row ? getRowIcon(item.row.type) : getRowIcon(item.type);
		}

		return (
			<DraggableStructure
				className="notranslate"
				authorisationMode={item.authorisationMode}
				structure={item.row}
				rowId={item.id}
				iconSrc={iconSrc}
				labelFirst={item.title}
				previewIcon={fullIcon}
				key={i}
				onDoubleClick={() => onDoubleClick(item)}
				type={item.row ? item.row.type : item.type}
			/>
		);
	});

	const accordionItems = [
		['Basic', normalRowItems],
		['Saved layouts', customRowItems],
	];

	return (
		<Accordion allowMultiple defaultIndex={accordionItems.map((_, idx) => idx)}>
			{accordionItems.map(([title, items], idx) => (
				<AccordionItem
					key={title}
					borderTop={idx === 0 ? '0' : undefined}
					borderBottom={idx === accordionItems.length - 1 ? '0' : undefined}
				>
					<AccordionButton>
						{title}
						<AccordionIcon ml="auto" />
					</AccordionButton>

					<AccordionPanel motionProps={{ unmountOnExit: true }}>
						<SimpleGrid columns={3} spacing={2} p={1}>
							{items}
						</SimpleGrid>
					</AccordionPanel>
				</AccordionItem>
			))}
		</Accordion>
	);
};

export default StructureMenu;
