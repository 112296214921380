import { memo } from 'react';

import { Box, IconButton, Image, Tooltip } from '@chakra-ui/react';
import { mdiFormatBold, mdiFormatItalic, mdiFormatUnderline } from '@mdi/js';

import { Icon } from '@/components/gui/shared/Icon';

const DECORATION_CONSTANTS = {
	italics: { icon: mdiFormatItalic, label: 'Italic' },
	bold: { icon: mdiFormatBold, label: 'Bold' },
	underline: { icon: mdiFormatUnderline, label: 'Underline' },
};

export const ToggleButtonSetting = ({ on, onIcon, width = 35, height = 35, margin, type, offIcon, svg, onChange }) => {
	const onClick = () => {
		let invertedState = !on;
		if (onChange) onChange({ type: type, value: invertedState });
	};

	if (onIcon) {
		let src = (on && onIcon) || offIcon;
		return (
			<Image
				className={`toggleButtonSetting toggle-button-${type}`}
				src={src}
				onClick={onClick}
				w={width}
				h={height}
				cursor="pointer"
				display="inline-block"
				m={margin}
				userSelect="none"
			/>
		);
	}

	return (
		<Box
			mr={5}
			w={width}
			h={height}
			m={margin}
			display="flex"
			alignItems="center"
			justifyContent="center"
			onClick={onClick}
			cursor="pointer"
			userSelect="none"
			data-testid={`toggle-button-setting-${type}`}
		>
			{svg ? (
				svg
			) : (
				<Tooltip label={DECORATION_CONSTANTS[type].label}>
					<IconButton
						aria-label={DECORATION_CONSTANTS[type].label}
						variant="ghost"
						size="sm"
						colorScheme={on ? 'primary' : 'neutral'}
						isActive={on}
						icon={<Icon path={DECORATION_CONSTANTS[type].icon} />}
					/>
				</Tooltip>
			)}
		</Box>
	);
};

export default memo(ToggleButtonSetting);
