import cloneDeep from 'lodash/cloneDeep';
import { useDeepCompareCallback } from 'use-deep-compare';

import useContentStore from '@/stores/ContentStore';

import { generateGuid } from '@/util/shared';

import { swapConditionalItems } from '@/components/Conditions/utils';

export const useLogicMenuActions = (index, parentAddress, getParentSettings, itemToDuplicate) => {
	const changeConditionSetting = useContentStore((state) => state.changeConditionSetting);

	const handleMove = useDeepCompareCallback(
		(direction: 'up' | 'down') => {
			let toIndex: number | undefined;
			if (direction === 'up') {
				toIndex = index - 1;
			} else if (direction === 'down') {
				toIndex = index + 1;
			}
			changeConditionSetting(parentAddress, {
				settings: swapConditionalItems([...getParentSettings()], index, toIndex),
			});
		},
		[index, parentAddress],
	);

	const handleDelete = useDeepCompareCallback(() => {
		const parentSettings = [...getParentSettings()];
		parentSettings.splice(index, 1);
		changeConditionSetting(parentAddress, { settings: parentSettings });
	}, [index, parentAddress]);

	const handleDuplicate = useDeepCompareCallback(() => {
		const onDuplicateCondition = () => {
			return cloneDeep(itemToDuplicate);
		};

		const parentSettings = [...getParentSettings()];
		parentSettings.splice(index + 1, 0, { ...onDuplicateCondition(), id: generateGuid() });
		changeConditionSetting(parentAddress, { settings: parentSettings });
	}, [index, itemToDuplicate, parentAddress]);

	return {
		handleMove,
		handleDelete,
		handleDuplicate,
	};
};
