import React from 'react';

import { Box } from '@chakra-ui/react';
import { mdiClose, mdiImagePlusOutline } from '@mdi/js';

import useContentStore from '@/stores/ContentStore';
import useModalStore from '@/stores/ModalStore';

import ButtonSetting from './ButtonSetting.react';

const customStyle = { pt: 5, pb: 5, w: '100%' };
const customStyleRemove = { ...customStyle, colorScheme: 'danger' };

const ImagePickerButtons = ({ label, image, removeLabel, type, details }) => {
	const openWidget = () => {
		useModalStore.getState().showImagePickerModal({ label, image, removeLabel, type, details, id: Date.now() });
	};

	const removeSelectedImage = () => {
		useContentStore.getState().setImage({ type, url: '', imageType: '', details });
	};

	return (
		<Box>
			<ButtonSetting
				onClick={openWidget}
				tooltip={label ? label : image ? 'Change image' : 'Add image'}
				label={label ? label : image ? 'Change image' : 'Add image'}
				icon={mdiImagePlusOutline}
				testId="image"
				customStyle={customStyle}
			/>
			{image && (
				<ButtonSetting
					tooltip={removeLabel || 'Remove image'}
					label={removeLabel || 'Remove image'}
					icon={mdiClose}
					testId="close"
					onClick={removeSelectedImage}
					customStyle={customStyleRemove}
				/>
			)}
		</Box>
	);
};

export default ImagePickerButtons;
