import { Box, Heading, Image } from '@chakra-ui/react';

import { TableTopBar } from './Table/components/TableTopBar';

export const NoAccessLP = () => {
	return (
		<>
			<TableTopBar noAccess />
			<LPContent
				src="https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-lock"
				title="You don’t have access to Forms"
				label="Learn more about Forms"
			/>
		</>
	);
};

const LPContent = ({ style, src, title }) => {
	return (
		<Box style={style} display={{ base: 'none', md: 'flex' }} alignItems="center" justifyContent="center" flexDirection="column" pt="24">
			<Image boxSize="32" src={src} />

			<Heading my={8} as="h1" size="lg">
				{title}
			</Heading>
		</Box>
	);
};
