import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import useModalStore from '@/stores/ModalStore';

import { ModalTypes } from '@/util/resources';

import GeneralModal from '@/components/gui/layout/modals/GeneralModal.react';
import NotificationPopup from '@/components/gui/layout/modals/NotificationPopup.react';
import { TableTopBar } from '@/components/Table/components/TableTopBar';

export const TableRoot = () => {
	const generalModal = useModalStore((state) => state[ModalTypes.GENERAL_MODAL]);
	return (
		<>
			<TableTopBar />
			<NotificationPopup />
			<GeneralModal show={generalModal.show} data={generalModal.data} />
			<Box h="full" overflowX="hidden" overflowY="auto">
				<Outlet />
			</Box>
		</>
	);
};

export default TableRoot;
