import Imgix from 'imgix.js';

export const ImgixService = {
	applyParametersToSrc(src, type, parameters, forceImgixGifs, imgixPrefix) {
		if (!src) {
			return '';
			// throw new Error('src must be present!');
		}

		//create a detached <a> element for ez url parsing
		let dummyLink = window.document.createElement('a');
		dummyLink.href = src;

		const regionCode = (typeof process !== 'undefined' ? process.env.RegionCode : import.meta.RegionCode) ?? 'euw';
		// const regionCode = process.env.RegionCode;

		let url = imgixPrefix.replace('{regionCode}', regionCode) + dummyLink.pathname + dummyLink.search;

		let imgixParams = { ...parameters, auto: 'format,compress' };

		if (type === 'image/gif' && !forceImgixGifs) {
			imgixParams = {};
		} else if (type === 'image/png') {
			// imgixParams = { ...parameters, fm: 'png8' };
		}

		let imgxUrl = new Imgix.URL(url, imgixParams);

		return imgxUrl.getURL();
	},

	//this takes a src and a width and returns an Imgix url, width defaults to 600 because it is the max width of the newsletter
	getUrlWithWidth(src, type, width, retina = false, imgixPrefix) {
		//width * 2 here becauuse of responsive image quality on smaller slots
		let newWidth = width ? { w: width >= 400 ? width : width * 2 } : {};
		let dpr = 1;

		if (retina) {
			dpr = 2;
		}

		return this.applyParametersToSrc(src, type, { ...newWidth, fit: 'clip', dpr }, false, imgixPrefix);
	},

	//this takes a src and a width and returns an Imgix url, width defaults to 600 because it is the max width of the newsletter
	applyCropParameters(src, type, cropData, imgixPrefix) {
		const x = cropData.x > 0 ? cropData.x : 0;
		const y = cropData.y > 0 ? cropData.y : 0;
		const width = cropData.width > 0 ? cropData.width : 0;
		const height = cropData.height > 0 ? cropData.height : 0;
		return this.applyParametersToSrc(src, type, { q: 100, rect: `${x},${y},${width},${height}` }, false, imgixPrefix);
	},
};
export const resolveProvider = () => {
	return ImgixService; //just imgix atm
};
