import { useCallback } from 'react';

import { Box, Button, Heading, IconButton, Tooltip } from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import { Link } from 'react-router-dom';

import useStylingStore from '@/stores/StylingStore';

import { Icon } from '@/components/gui/shared/Icon';
import { getRoute } from '@/components/Table/strings';

export const StylingHeader = (props) => {
	const savedStylesString = useStylingStore((state) => state.savedStyles?.stylesString);
	const stylesString = useStylingStore((state) => state.styles.stylesString);
	const savedStylesIsCss = useStylingStore((state) => state.savedStyles?.isCss);
	const isCss = useStylingStore((state) => state.styles.isCss);
	const attributes = useStylingStore((state) => state.attributes);

	const onSave = useCallback(() => {
		useStylingStore.getState().saveStyle(props.id);
	}, []);

	const onCancel = useCallback(() => {
		useStylingStore.getState().cancelStyle();
	}, []);

	const canSave = savedStylesString !== stylesString || savedStylesIsCss !== isCss;

	return (
		<Box display="flex" alignItems="center" justifyContent="space-between" px={7} py={4} position="relative">
			<Box overflow="hidden" display="flex" alignItems="center">
				<Link to={getRoute.stylingList()} data-testid="back-icon">
					<Tooltip label="Back to Styles">
						<IconButton mr={3} variant="ghost" icon={<Icon path={mdiArrowLeft} />} aria-label={'Back to Styles'} />
					</Tooltip>
				</Link>
				<Heading as="h1" size="lg" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" mr={7}>
					{attributes.name}
				</Heading>
			</Box>
			<Box display="flex">
				{savedStylesString !== stylesString && (
					<Button mr={2.5} variant="ghost" onClick={onCancel}>
						Discard changes
					</Button>
				)}
				<Button isDisabled={!canSave} onClick={onSave}>
					Save
				</Button>
			</Box>
		</Box>
	);
};

export default StylingHeader;
