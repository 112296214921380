;
/* istanbul ignore file */
import * as React from 'react';



import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { mdiMagnify } from '@mdi/js';



import { Icon } from '@/components/gui/shared/Icon';





export const useSettingsContext = () => React.useContext(SettingsContext);

export const SettingsContext = React.createContext({ filterValue: '' });

export const SettingsContextProvider = SettingsContext.Provider;

export const withSettingsContext = (Component) => {
	return React.memo(function WithSettingsContext(props) {
		const context = useSettingsContext();
		return <Component {...props} filterValue={context.filterValue} />;
	});
};

export const SettingsBox = withSettingsContext(
	React.memo(({ filterValue, blockId, ...rest }) => {
		const getChildren = () => {
			return React.Children.map(rest.children, (child) => {
				if (React.isValidElement(child)) {
					if (child.props.filterImmunity) {
						return child;
					}

					const value = child.props.filterId || child.props.label;
					if (value && value.toLowerCase().includes(filterValue.toLowerCase())) {
						return child;
					}
				}
				return null;
			});
		};

		const children = filterValue ? getChildren() : rest.children;

		if (blockId) {
			const block = document.getElementById(blockId);
			if (block) {
				if (!children || (children && children.length === 0)) {
					block.style.display = 'none';
					block.classList.add('hidden');
				} else {
					block.style.display = 'block';
					block.classList.remove('hidden');
				}
			}
		}

		return <Box {...rest}>{children}</Box>;
	}),
);

export const FilterableSettings = React.memo((props) => {
	const [filterValue, setFilterValue] = React.useState('');

	return (
		<>
			<InputGroup my={5}>
				<InputLeftElement>
					<Icon path={mdiMagnify} />
				</InputLeftElement>
				<Input placeholder="Search for a setting" value={filterValue} onChange={(e) => setFilterValue(e.target.value)} />
			</InputGroup>
			<SettingsContextProvider value={{ filterValue }}>{props.children}</SettingsContextProvider>
		</>
	);
});
