import { useMemo } from 'react';

import { useDeepCompareCallback, useDeepCompareMemo } from 'use-deep-compare';

import useContentStore from '@/stores/ContentStore';

export function useParentSettings(parentAddressArr: number | number[], index: number) {
	const logicsErrors = useContentStore((state) => state.logicsErrors);

	const parentAddress = useDeepCompareMemo(() => {
		return Array.isArray(parentAddressArr) ? parentAddressArr : [parentAddressArr];
	}, [parentAddressArr]);

	const address = useDeepCompareMemo(() => {
		return [...parentAddress, index];
	}, [index, parentAddress]);

	const getParentData = useDeepCompareCallback(() => {
		let parentData = useContentStore.getState().content.logics;

		parentAddress.forEach((item) => {
			if (Array.isArray(parentData)) {
				parentData = parentData[item];
			} else {
				parentData = parentData?.settings[item];
			}
		});

		return parentData;
	}, [parentAddress]);

	const errors: string[] = useMemo(() => {
		let parentData = logicsErrors;

		[...parentAddress, index].forEach((item) => {
			if (!parentData) return;

			if (Array.isArray(parentData)) {
				parentData = parentData?.[item];
			} else {
				parentData = parentData?.settings?.[item];
			}
		});

		return parentData?.errors || [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index, JSON.stringify(parentAddress), JSON.stringify(logicsErrors)]);

	return {
		parentAddress,
		address,
		getParentData,
		getParentSettings: () => getParentData()?.settings,
		getFieldOptions: () => getParentData()?.field?.options,
		errors,
	};
}
