import React from 'react';

import Element from './Element.react';
import SharedInput from './SharedInput.react';

export const EmailInput = (props) => {
	return <SharedInput {...props} inputType="email" />;
};

export default Element(EmailInput);
