import { memo } from 'react';

import { Box, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { mdiFormatAlignCenter, mdiFormatAlignLeft, mdiFormatAlignRight } from '@mdi/js';

import { Icon } from '@/components/gui/shared/Icon';

import { settingsLabelStyle } from './Settings.styles';

const alignIcons = {
	left: mdiFormatAlignLeft,
	center: mdiFormatAlignCenter,
	right: mdiFormatAlignRight,
};

const AlignmentSetting = ({ options, onChange, selected, type, label, disabled }) => {
	return (
		<Box display="flex" flexDirection="column" mb={5}>
			<Text {...settingsLabelStyle} color={disabled ? 'chakra-placeholder-color' : settingsLabelStyle.color}>
				{label}
			</Text>

			<Box display="flex">
				{options.map((item) => (
					<Tooltip label={item.value}>
						<IconButton
							aria-label={item.value}
							key={item.value}
							mr={2}
							variant="ghost"
							size="sm"
							colorScheme={!disabled && selected === item.value ? 'primary' : 'neutral'}
							isActive={!disabled && selected === item.value ? true : false}
							onClick={() => onChange({ type, value: item.value })}
							icon={<Icon path={alignIcons[item.value]} />}
							isDisabled={disabled}
							data-testid={`alignment-icon-${item.value}`}
						/>
					</Tooltip>
				))}
			</Box>
		</Box>
	);
};

export default memo(AlignmentSetting);
