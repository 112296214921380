import React from 'react';

import {
	Button,
	ButtonGroup,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Spinner,
	Tooltip,
} from '@chakra-ui/react';
import { mdiCellphone, mdiMonitor, mdiTablet } from '@mdi/js';

import useContentStore from '@/stores/ContentStore';
//actions
import useModalStore from '@/stores/ModalStore';

import { Router } from '@/routes';
import DomHelper from '@/util/domHelper';
import { pathPrefix } from '@/util/helper';

import { Icon } from '@/components/gui/shared/Icon';

class TemplatePreviewModal extends React.PureComponent {
	keyListener;
	iframe = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			clicked: 'desktop',
		};
	}

	componentDidMount() {
		this.keyListener = DomHelper.onKeyUpModalListener(this.ok, this.close);
	}

	componentDidUpdate() {
		if (this.props.show) {
			window.addEventListener('keyup', this.keyListener);
		} else {
			window.removeEventListener('keyup', this.keyListener);
		}

		if (this.refs.iframe) {
			let doc = this.refs.iframe.contentDocument;
			doc.open();

			if (this.props.data.html) {
				const htmlString = this.addScriptToHtml(this.props.data.html);
				doc.write(htmlString);
			} else {
				doc.write('');
			}

			doc.close();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('keyup', this.keyListener);
	}

	render() {
		let { show } = this.props;

		let iframeWidth;

		switch (this.state.clicked) {
			case 'mobile':
				iframeWidth = 380;
				break;
			case 'tablet':
				iframeWidth = 550;
				break;
			case 'desktop':
				iframeWidth = '97%';
				break;
		}

		return (
			<Modal onClose={this.close} isOpen={show} size="6xl">
				<ModalOverlay />
				<ModalContent minH="2xl">
					<ModalCloseButton />
					<ModalBody display="flex" alignItems="center" justifyContent="space-between" flexDirection="column">
						<DevicesMenu clicked={this.state.clicked} iconClick={this.iconClick} />
						{this.props.data.html ? (
							<iframe
								frameBorder="0"
								className="preview-iframe"
								ref="iframe"
								width={iframeWidth}
								style={{ flex: 1, marginRight: 'auto', marginLeft: 'auto' }}
							/>
						) : (
							<Spinner thickness="4px" emptyColor="neutral.200" color="primary" size="xl" />
						)}
					</ModalBody>
					<ModalFooter gap="2">
						<Button children="Use template" onClick={this.ok} />
						<Button variant="outline" children="Close preview" onClick={this.close} />
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	}

	iconClick = (viewIcon) => {
		return () => {
			this.setState({ clicked: viewIcon });
		};
	};

	ok = () => {
		if (this.props.data.onOk) this.props.data.onOk();
		useContentStore.getState().loadAndSetTemplate(this.props.data, null);
		(this.props.navigate || Router.navigate)(pathPrefix() + location.search);
		useModalStore.getState().closeTemplatePreviewModal();
	};

	close = () => {
		if (this.props.data.onCancel) this.props.data.onCancel();
		useModalStore.getState().closeTemplatePreviewModal();
	};

	addScriptToHtml = (html) => {
		const script = `<script>
		var forms = document.getElementsByTagName('form');
        if (forms && forms.length){
            for (var i = 0; i < forms.length; i++){
				forms[i].onsubmit = function(e){
					e.preventDefault();
				}
			}
        }
		</script>`;

		return html.replace('</body>', `${script}</body>`);
	};
}

const DevicesMenu = ({ iconClick, clicked }) => {
	return (
		<ButtonGroup variant="toggle">
			<Tooltip placement="top" label="Mobile">
				<IconButton
					aria-label="Mobile"
					data-testid="device-button-phone"
					icon={<Icon path={mdiCellphone} />}
					isActive={clicked !== 'mobile' ? false : true}
					onClick={iconClick('mobile')}
				/>
			</Tooltip>
			<Tooltip placement="top" label="Tablet">
				<IconButton
					aria-label="Tablet"
					data-testid="device-button-tablet"
					icon={<Icon path={mdiTablet} />}
					isActive={clicked !== 'tablet' ? false : true}
					onClick={iconClick('tablet')}
				/>
			</Tooltip>
			<Tooltip placement="top" label="Desktop">
				<IconButton
					aria-label="Desktop"
					data-testid="device-button-desktop"
					icon={<Icon path={mdiMonitor} />}
					isActive={clicked !== 'desktop' ? false : true}
					onClick={iconClick('desktop')}
				/>
			</Tooltip>
		</ButtonGroup>
	);
};

export default TemplatePreviewModal;
